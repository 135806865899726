import { IActions } from '../models/Actions';

export enum EDarklyflags {
  createAccPkgs = 'asm-create-access-packages',
  connectApps = 'asm-connect-application',
  genDimMapping = 'asm-generate-dimension-mapping',
  onboardApps = 'asm-onboard-application',
  updateAccPkgs = 'asm-update-access-packages',
  updateApps = 'asm-update-applications',
  updateEdms = 'asm-update-business-role-entitlement-dimension-mapping',
  updateBrs = 'asm-update-business-roles',
  upsertDataSelGrps = 'asm-upsert-data-selection-groups',
  upsertDataSels = 'asm-upsert-data-selections',
  upsertDims = 'asm-upsert-dimensions',
  upsertDimTypes = 'asm-upsert-dimension-types',
  upsertRoleFltrs = 'asm-upsert-role-filters',
  upsertRoleFltrSels = 'asm-upsert-role-filter-selections',
  upsertSegOduties = 'asm-upsert-segregation-of-duties',
  intakeValidationBox = 'asm-intake-validation-box',
}

export const msmObjectives = {
  mbr: {
    name: 'Manage business roles',
    visible: true,
  },
  ma: {
    name: 'Manage application',
    visible: true,
  },
  mdds: {
    name: 'Manage dimensions and data selections',
    visible: true,
  },
  map: {
    name: 'Manage access packages',
    visible: true,
  },
  mmc: {
    name: 'Manage miscellaneous configurations',
    visible: true,
  },
};

export const msmActions: IActions = {
  updateBr: {
    name: 'Update business role',
    val: 'update_business_role',
    objective: msmObjectives.mbr.name,
    link: 'updateBr',
    subUrl: 'm',
    visible: true,
    darklyFlag: EDarklyflags.updateBrs,
  },
  updateEdm: {
    name: 'Update business role to dimension mapping',
    val: 'update_business_role_to_dimension_mapping',
    objective: msmObjectives.mbr.name,
    link: 'updateEdm',
    subUrl: 'm',
    visible: true,
    darklyFlag: EDarklyflags.updateEdms,
  },
  updateSod: {
    name: 'Create and update segregation of duties',
    val: 'create_and_update_segregation_of_duties',
    objective: msmObjectives.mbr.name,
    link: 'updateSod',
    subUrl: 'm',
    visible: true,
    darklyFlag: EDarklyflags.upsertSegOduties,
  },
  updateApp: {
    name: 'Update application',
    val: 'update_application',
    objective: msmObjectives.ma.name,
    link: 'updateApp',
    subUrl: 'm',
    visible: true,
    darklyFlag: EDarklyflags.updateApps,
  },
  onboardApp: {
    name: 'Onboard application to myAccess',
    val: 'onboard_application_to_myAccess',
    objective: msmObjectives.ma.name,
    link: 'onboardApp',
    subUrl: 'm',
    visible: true,
    darklyFlag: EDarklyflags.onboardApps,
  },
  connectApp: {
    name: 'Connect application to myAccess',
    val: 'connect_application_to_myAccess',
    objective: msmObjectives.ma.name,
    link: 'connectApp',
    subUrl: 'm',
    visible: true,
    darklyFlag: EDarklyflags.connectApps,
  },
  updateDims: {
    name: 'Create and update dimensions',
    val: 'create_and_update_dimensions',
    objective: msmObjectives.mdds.name,
    link: 'updateDims',
    subUrl: 'd',
    visible: true,
    darklyFlag: EDarklyflags.upsertDims,
  },
  updateDimTypes: {
    name: 'Create and update dimension types',
    val: 'create_and_update_dimension_types',
    objective: msmObjectives.mdds.name,
    link: 'updateDimTypes',
    subUrl: 'd',
    visible: true,
    darklyFlag: EDarklyflags.upsertDimTypes,
  },
  updateDataSelections: {
    name: 'Create and update data selections',
    val: 'create_and_update_data_selections',
    objective: msmObjectives.mdds.name,
    link: 'updateDataSelections',
    subUrl: 'd',
    visible: true,
    darklyFlag: EDarklyflags.upsertDataSels,
  },
  updateDataSelectionGroups: {
    name: 'Create and update data selection groups',
    val: 'create_and_update_data_selection_groups',
    objective: msmObjectives.mdds.name,
    link: 'updateDataSelectionGroups',
    subUrl: 'd',
    visible: true,
    darklyFlag: EDarklyflags.upsertDataSelGrps,
  },
  createAccPkg: {
    name: 'Create access package',
    val: 'create_access_package',
    objective: msmObjectives.map.name,
    link: 'createAccessPackage',
    subUrl: 'accPkg',
    visible: true,
    darklyFlag: EDarklyflags.createAccPkgs,
  },
  updateAccPkg: {
    name: 'Update access package',
    val: 'update_access_package',
    objective: msmObjectives.map.name,
    link: 'updateAccessPackage',
    subUrl: 'accPkg',
    visible: true,
    darklyFlag: EDarklyflags.updateAccPkgs,
  },
  genDimMapping: {
    name: 'Generate dimension mapping',
    val: 'upsert_access_package',
    objective: msmObjectives.map.name,
    link: 'generateDimensionMapping',
    subUrl: 'accPkg',
    visible: true,
    darklyFlag: EDarklyflags.genDimMapping,
  },
  updateRoleFilters: {
    name: 'Create and update role filters',
    val: 'create_and_update_role_filters',
    objective: msmObjectives.mmc.name,
    link: 'updateRoleFilters',
    subUrl: 'miscConfig',
    visible: true,
    darklyFlag: EDarklyflags.upsertRoleFltrs,
  },
  updateRoleFilterSelections: {
    name: 'Create and update role filter selections',
    val: 'create_and_update_role_filter_selections',
    objective: msmObjectives.mmc.name,
    link: 'updateRoleFilterSelections',
    subUrl: 'miscConfig',
    visible: true,
    darklyFlag: EDarklyflags.upsertRoleFltrSels,
  },
  updateAreaOfInterests: {
    name: 'Create and update areas of interest',
    val: 'create_and_update_areas_of_interest',
    objective: msmObjectives.mmc.name,
    link: 'updateAreaOfInterests',
    subUrl: 'miscConfig',
    visible: true,
  },
};
