import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { Application } from 'src/app/models/Application';
import { AuthService } from 'src/app/auth/auth.service';
import { MsmService } from './msm.service';
import { msmActions, msmObjectives } from './actions-objective';
import { MsmApiService } from './msm-api.service';
import { IAccPkgSml } from 'src/app/models/AccPkg';
import { LaunchdarklyService } from '../singletons/launchdarkly.service';
import { IActionPkg } from '../models/Actions';
import { nvfilterOptions } from './app-br-edm/strings';

@Component({
  selector: 'app-msm',
  templateUrl: './msm.component.html',
  styleUrls: ['./msm.component.scss'],
})
export class MsmComponent implements OnInit, OnDestroy {
  accPkgs: IAccPkgSml[] = [];
  filteredAccPkgs: IAccPkgSml[] = [];
  filteredApps: Application[] = [];
  filterOptions = nvfilterOptions;
  msmObjectivesArr = Object.values(msmObjectives);
  msmActionsArr = Object.values(msmActions);
  clearAppFilter$ = new Subject<boolean>();
  clearAccPkgFilter$ = new Subject<boolean>();
  clearMappedBrFilter$ = new Subject<boolean>();
  mappedBrFilterStr = '';
  ttLookinFour =
    'If you cannot find what you are looking for, contact the myAccess Support Team.';

  private apps: Application[] = [];
  private subs: Subscription[] = [];
  private saoTO: NodeJS.Timeout;
  private appsChecker = false;
  showFilter = {
    activeApp: false,
    visibleApp: false,
    activeBr: false,
    visibleBr: false,
    activeAccPkg: false,
    visibleAccPkg: false,
  };
  filterValues = {
    activeApp: { all: false, yes: false, no: false },
    visibleApp: { all: false, yes: false, no: false },
    activeBr: { all: false, yes: false, no: false },
    visibleBr: { all: false, yes: false, no: false },
    activeAccPkg: { all: false, yes: false, no: false },
    visibleAccPkg: { all: false, yes: false, no: false },
  };
  activeAppState = 'All'; // Property to hold active filter
  visibleAppState = 'All'; // Property to hold visible filter
  activeBrState = 'All'; // Property to hold active filter
  visibleBrState = 'All'; // Property to hold visible filter
  activeAccPkgState = 'All'; // Property to hold active filter
  visibleAccPkgState = 'All'; // Property to hold visible filter
  constructor(
    public msmFgService: MsmService,
    private apiService: MsmApiService,
    private authService: AuthService,
    private darklyService: LaunchdarklyService,
  ) {}

  ngOnInit(): void {
    this.msmFgService.onInit();
    this.setAvailObjectivesTO();

    this.subs.push(
      this.msmFgService.acAction.valueChanges.subscribe(
        this.actionChanged.bind(this),
      ),
    );
    this.subs.push(
      this.msmFgService.acAccPkg.valueChanges.subscribe(
        this.accPkgChange.bind(this),
      ),
    );
    this.subs.push(
      this.msmFgService.acApp.valueChanges.subscribe(
        this.appChanged.bind(this),
      ),
    );
    this.subs.push(
      this.darklyService.flagsChanged$.subscribe(
        this.setAvailObjectivesTO.bind(this),
      ),
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((s) => s.unsubscribe());
    this.msmFgService.onDestroy();
  }
  onFilterChange(column: string, option: string, checked: boolean) {
    if (option === 'all') {
      this.filterValues[column].all = checked;
      if (checked) {
        this.filterValues[column].yes = false;
        this.filterValues[column].no = false;
      }
    } else {
      this.filterValues[column][option.toLowerCase()] = checked;
      if (checked) {
        this.filterValues[column].all = false;
      }
    }
  }
  filterApps(value: string): void {
    let apps = this.apps;

    // Apply active and visible filters
    apps = apps.filter((app) => {
      let activeMatch = true;
      let visibleMatch = true;

      if (this.activeAppState !== 'All' && this.activeAppState !== 'YesNo') {
        activeMatch = this.activeAppState === 'Yes' ? app.active : !app.active;
      }

      if (this.visibleAppState !== 'All' && this.visibleAppState !== 'YesNo') {
        visibleMatch =
          this.visibleAppState === 'Yes' ? app.visible : !app.visible;
      }

      return activeMatch && visibleMatch;
    });
    if (!value) {
      if (
        this.msmFgService.isMsmActionElevateToL3Onboard ||
        this.msmFgService.isMsmActionElevateToL3Connect
      ) {
        this.filteredApps = apps.filter((app) => app.isL2);
      } else {
        this.filteredApps = apps;
      }
      return;
    }
    const upperVal = value.toLocaleUpperCase();
    if (/^L[1-4]$/.test(upperVal)) {
      this.filteredApps = this.apps.filter(
        (app) => app.onboardingLevel === upperVal,
      );
    } else {
      if (
        this.msmFgService.acAction.value.val ===
        (msmActions.onboardApp.val || msmActions.connectApp.val)
      ) {
        this.filteredApps = apps.filter(
          (app) =>
            app.isL2 &&
            (`${app.igaAppID}`.indexOf(upperVal) >= 0 ||
              app.displayName.toLocaleUpperCase().indexOf(upperVal) >= 0),
        );
      } else {
        this.filteredApps = apps.filter(
          (app) =>
            `${app.igaAppID}`.indexOf(upperVal) >= 0 ||
            app.displayName.toLocaleUpperCase().indexOf(upperVal) >= 0,
        );
      }
    }
  }
  filterMappedBrs(value: string): void {
    let brs = this.msmFgService.appMappedBrs;
    brs = brs.filter((br) => {
      let activeMatch = true;
      let visibleMatch = true;

      if (this.activeBrState !== 'All' && this.activeBrState !== 'YesNo') {
        activeMatch = this.activeBrState === 'Yes' ? br.active : !br.active;
      }

      if (this.visibleBrState !== 'All' && this.visibleBrState !== 'YesNo') {
        visibleMatch = this.visibleBrState === 'Yes' ? br.visible : !br.visible;
      }

      return activeMatch && visibleMatch;
    });
    if (value) {
      const lowerVal = value.toLocaleLowerCase();
      this.msmFgService.filteredBusinessRoles = brs.filter(
        (br) =>
          br.name.toLocaleLowerCase().includes(lowerVal) ||
          br.key.toLocaleLowerCase().includes(lowerVal),
      );
    } else {
      this.msmFgService.filteredBusinessRoles = brs;
    }
  }
  filterAccPkgs(value: string): void {
    let accPkgs = this.accPkgs;
    accPkgs = accPkgs.filter((pkg) => {
      let activeMatch = true;
      let visibleMatch = true;

      if (
        this.activeAccPkgState !== 'All' &&
        this.activeAccPkgState !== 'YesNo'
      ) {
        activeMatch =
          this.activeAccPkgState === 'Yes' ? pkg.active : !pkg.active;
      }

      if (
        this.visibleAccPkgState !== 'All' &&
        this.visibleAccPkgState !== 'YesNo'
      ) {
        visibleMatch =
          this.visibleAccPkgState === 'Yes' ? pkg.visible : !pkg.visible;
      }

      return activeMatch && visibleMatch;
    });

    if (value) {
      const lowerVal = value.toLocaleLowerCase();
      this.filteredAccPkgs = accPkgs.filter((pkgs) =>
        pkgs.name.toLocaleLowerCase().includes(lowerVal),
      );
    } else {
      this.filteredAccPkgs = accPkgs;
    }
  }
  handleCheckboxState(column: string): void {
    // Determine which checkboxes to check based on this.activeState or this.visibleState
    let stateValue;
    if (column === 'activeApp') {
      stateValue = this.activeAppState;
    } else if (column === 'visibleApp') {
      stateValue = this.visibleAppState;
    } else if (column === 'activeBr') {
      stateValue = this.activeBrState;
    } else if (column === 'visibleBr') {
      stateValue = this.visibleBrState;
    } else if (column === 'activeAccPkg') {
      stateValue = this.activeAccPkgState;
    } else if (column === 'visibleAccPkg') {
      stateValue = this.visibleAccPkgState;
    }
    if (stateValue === 'YesNo') {
      this.filterValues[column] = { all: false, yes: true, no: true };
    } else if (stateValue === 'All') {
      this.filterValues[column] = { all: true, yes: false, no: false };
    } else if (stateValue === 'Yes') {
      this.filterValues[column] = { yes: true, all: false, no: false };
    } else if (stateValue === 'No') {
      this.filterValues[column] = { no: true, all: false, yes: false };
    } else {
      this.filterValues[column] = { all: false, yes: false, no: false };
    }
  }
  determineState(filterValues: {
    all: boolean;
    yes: boolean;
    no: boolean;
  }): string {
    if (filterValues.yes && filterValues.no) {
      return 'YesNo';
    } else if (filterValues.all) {
      return 'All';
    } else if (filterValues.yes) {
      return 'Yes';
    } else if (filterValues.no) {
      return 'No';
    }
    return ''; // Default state when none of the conditions are met
  }

  applyFilter(column: string, select: string) {
    if (select === 'app') {
      if (column === 'activeApp') {
        this.activeAppState = this.determineState(this.filterValues[column]);
      } else if (column === 'visibleApp') {
        this.visibleAppState = this.determineState(this.filterValues[column]);
      }
      this.clearAppFilter$.next(false);
      this.filterApps('');
    } else if (select === 'accPkg') {
      if (column === 'activeAccPkg') {
        this.activeAccPkgState = this.determineState(this.filterValues[column]);
      } else if (column === 'visibleAccPkg') {
        this.visibleAccPkgState = this.determineState(
          this.filterValues[column],
        );
      }
      this.clearAccPkgFilter$.next(false);
      this.filterAccPkgs(''); // Filter the access packages
    } else if (select === 'br') {
      if (column === 'activeBr') {
        this.activeBrState = this.determineState(this.filterValues[column]);
      } else if (column === 'visibleBr') {
        this.visibleBrState = this.determineState(this.filterValues[column]);
      }
      this.filterMappedBrs('');
      this.clearMappedBrFilter$.next(false);
    }
    this.showFilter[column] = false;
  }
  clearFilter(column: string, select: string) {
    this.filterValues[column] = { all: true, yes: false, no: false };
    this.applyFilter(column, select);
    this.showFilter[column] = false;
  }
  anyOptionChecked(filterKey: string): boolean {
    return Object.values(this.filterValues[filterKey]).some((value) => value);
  }
  private setAvailObjectivesTO(): void {
    clearTimeout(this.saoTO);
    this.saoTO = setTimeout(() => {
      this.setAvailObjectives();
      this.msmFgService.resetFG();
    }, 10);
  }

  private setAvailObjectives(): void {
    this.msmObjectivesArr.forEach((obj) => {
      const temp: boolean[] = [];
      for (const act in msmActions) {
        const a: IActionPkg = msmActions[act];
        if (a.objective === obj.name) {
          temp.push(a.visible);
        }
      }
      obj.visible = temp.includes(true);
    });

    if (!this.authService.currUser?.isAdmin) {
      msmObjectives.mdds.visible = false;
      msmObjectives.map.visible = false;
      msmObjectives.mmc.visible = false;
      msmActions.onboardApp.visible = false;
      msmActions.connectApp.visible = false;
    }
  }

  private actionChanged(): void {
    this.clearAppFilter$.next(false);
    this.clearFilter('activeApp', 'app');
    this.clearFilter('visibleApp', 'app');
    this.filterApps('');
    if (this.appsChecker) {
      this.clearFilter('activeBr', 'br');
      this.clearFilter('visibleBr', 'br');
      this.appsChecker = false;
      this.appChanged();
    }
  }

  private accPkgChange(): void {
    this.clearAccPkgFilter$.next(false);
    if (this.msmFgService.acAccPkg.enabled) {
      if (
        this.accPkgs.length !== 0 &&
        this.msmFgService.acAction.value.val !== 'update_access_package'
      ) {
        this.clearFilter('activeAccPkg', 'accPkg');
        this.clearFilter('visibleAccPkg', 'accPkg');
        this.filterAccPkgs('');
        return;
      } else if (this.accPkgs.length === 0) {
        this.apiService.getAccPkgs().subscribe((data) => {
          this.accPkgs = data;
          this.filteredAccPkgs = data;
        });
      }
    }
  } // Initialize filteredAccPkgs with all accPkgs initially

  private appChanged(): void {
    this.clearMappedBrFilter$.next(true);
    const filterValueStracObjective = this.msmFgService.acObjective.value;
    if (this.msmFgService.acApp.enabled) {
      if (
        filterValueStracObjective === msmObjectives.mbr &&
        !this.appsChecker
      ) {
        this.appsChecker = true;
        this.apiService.getApps().subscribe((data) => {
          if (data) {
            if (this.apps && this.apps.length > 0) {
              this.msmFgService.acApp.reset('');
            }
            this.apps = data;
            this.filteredApps = data;
            this.filterApps('');
          }
        });
      } else if (
        this.msmFgService.isMsmActionUpdateAppDeets &&
        !this.appsChecker
      ) {
        this.apiService.getAppsOnboarded().subscribe((dataOnboarded) => {
          this.appsChecker = true;
          if (dataOnboarded) {
            if (this.apps && this.apps.length > 0) {
              this.msmFgService.acApp.reset('');
            }
            this.apps = dataOnboarded;
            this.filteredApps = dataOnboarded;
            this.filterApps('');
          }
        });
      } else if (
        this.msmFgService.isMsmActionElevateToL3Onboard &&
        !this.appsChecker
      ) {
        this.apiService.getAppsEligible().subscribe((dataEligible) => {
          this.appsChecker = true;
          if (dataEligible) {
            if (this.apps && this.apps.length > 0) {
              this.msmFgService.acApp.reset('');
            }
            this.apps = dataEligible;
            this.filteredApps = dataEligible;
            this.filterApps('');
          }
        });
      } else if (
        this.msmFgService.isMsmActionElevateToL3Connect &&
        !this.appsChecker
      ) {
        this.apiService.getAppsEligible().subscribe((dataEligible) => {
          this.appsChecker = true;
          if (dataEligible) {
            if (this.apps && this.apps.length > 0) {
              this.msmFgService.acApp.reset('');
            }
            this.apps = dataEligible;
            this.filteredApps = dataEligible;
            this.filterApps('');
          }
        });
      }
    }
  }
}
