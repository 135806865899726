import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

export const invalidFilePropsValidator: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null => {
  const CSV_FILE_EXT = 'csv';
  const MAX_CSV_FILE_SIZE_MB = 26214400; // 25 MB, converting the value to bytes

  if (control && control.value && Array.isArray(control.value)) {
    if (control.value.length === 0) {
      return { noFilesSelected: 'At least one CSV file is required.' };
    }

    for (const file of control.value) {
      const fileExtMatch = file.name.match(/.+\.(csv)$/i);

      if (!fileExtMatch || fileExtMatch.length < 1) {
        return {
          invalidFileProps: `One or more files have invalid extensions. Only "${CSV_FILE_EXT}" files are accepted. Please select another file and try again.`,
        };
      }

      if (/[^\x20-\x7E]/.test(file.name)) {
        return {
          invalidFileProps:
            'File names cannot contain non-ASCII characters. Please rename the file(s) and try again.',
        };
      }
      if (file.size > MAX_CSV_FILE_SIZE_MB) {
        return {
          invalidFileProps: `The file "${file.name}" exceeds the maximum value of 25 Megabyte. Please try again.`,
        };
      }
    }
  } else {
    return { noFilesSelected: 'At least one CSV file is required.' };
  }

  return null;
};
