export interface IApplication {
  airID: number;
  description: string;
  igaAppID: number;
  name: string;
  onboardingLevel: string;
  onboardingStatus: string;
  active: boolean;
  visible: boolean;
}

export interface IPayLoadApp {
  applications: IApplication[];
}

export class Application {
  airID: number;
  description = '';
  igaAppID: number;
  name = '';
  onboardingLevel = '';
  onboardingStatus = '';
  active: boolean;
  visible: boolean;
  constructor(params: IApplication) {
    Object.assign(this, params);
  }

  get displayName(): string {
    return this.name;
  }

  get isL2(): boolean {
    return this.onboardingLevel === 'L2';
  }
}

export interface IBrApp {
  airID: number;
  igaAppID: number;
  applicationName: string;
}

export class BrApp {
  airID: number;
  igaAppID: number;
  applicationName: string;

  constructor(params: IBrApp | BrApp) {
    if (params) {
      this.airID = params.airID;
      this.igaAppID = params.igaAppID;
      this.applicationName =
        (params as IBrApp).applicationName || (params as BrApp).applicationName;
    }
  }

  get longDisplay(): string {
    return `AIR ID: ${this.airID}\nIGA app ID: ${this.igaAppID}\nApp name: ${this.applicationName}\n`;
  }

  get payload(): IBrApp {
    return {
      airID: this.airID,
      igaAppID: this.igaAppID,
      applicationName: this.applicationName,
    };
  }
}

export class SimpleApplication {
  airID: number;
  igaAppID: number;
  name = '';
  selected = false;

  constructor(params: Application) {
    Object.assign(this, params);
  }

  get displayName(): string {
    return `${this.name}`;
  }

  get igaAppIdAndName(): string {
    return `${this.igaAppID} ${this.name}`;
  }
}
