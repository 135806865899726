export enum DataClassification {
  Unrestricted = 'Unrestricted',
  /**
   * @deprecated MSM does not support Public as a value yet. Do not use this value.
   */
  Public = 'Public',
  Restricted = 'Restricted',
  Confidential = 'Confidential',
  HighlyConfidential = 'Highly Confidential',
}
