<div class="data-impact" *ngIf="mngr.hasLoaded">
    <p *ngIf="csvs.length === 0" class="no-impact">There is no data impact with the current changes/updates.</p>
    <div *ngIf="csvs.length > 0" class="content">
        <div class="hdr-wrp">
            <div class="lft">
                <h3>Data impact</h3>
                <p class="warning-txt"><b>IMPORTANT:</b> Please note that the changes you are about to make has the following impacts. Please review the impacts by downloading the files using the "Trigger file(s) download" button. If necessary, make updates to the impacts through a separate request(s) in order to ensure data consistency</p>
            </div>
            <div class="rht">
                <div class="download link" *ngIf="downloadState === 'NOT-STARTED'" (click)="downloadClicked()">
                    <mat-icon aria-hidden="false" aria-label="start" fontIcon="start"></mat-icon>
                    <i>Trigger file(s) download</i>
                </div>
                <div class="download running" *ngIf="downloadState === 'RUNNING'">
                    <mat-icon class="rotating" aria-hidden="false" aria-label="hourglass_top"
                        fontIcon="hourglass_top"></mat-icon>
                    <i>loading file(s)</i>
                </div>
                <a class="download complete" *ngIf="downloadState === 'SUCCEEDED'" href="{{presignedUrl}}"
                    target="_blank">
                    <mat-icon aria-hidden="false" aria-label="download" fontIcon="download"></mat-icon>
                    <i>Download files</i>
                </a>
                <div class="download failed" *ngIf="downloadState === 'FAILED'">
                    <mat-icon aria-hidden="false" aria-label="error" fontIcon="error"></mat-icon>
                    <i>failed to download files</i>
                </div>
            </div>
        </div>
        <div class="all-wrp">
            <ng-container *ngFor="let csv of csvs">
                <div class="no-data" *ngIf="csv.count === 0; else hasDataBlock">
                    <h4>{{csv.name}}</h4>
                    <p>Manually validate this file if there are updates required from this request</p>
                </div>
                <ng-template #hasDataBlock>
                    <div class="tbl-wrp">
                        <h4>{{csv.name}} <span>({{csv.count}} records)</span></h4>
                        <app-impact-tbl [dispColKeys]="csv.tableKeys" [dispColumns]="csv.tableColumns"
                            [data]="csv.data"></app-impact-tbl>
                    </div>
                </ng-template>
            </ng-container>
        </div>
    </div>
</div>