export interface ISignedUrlBody {
  signedUrl: string;
  expiresIn: string;
  tag: string;
}

interface IEmailObj {
  email: string;
}

export interface ISignedEmails {
  emails: IEmailObj[];
}

export interface IvbFileNames {
  filenames: string[];
  emails: IEmailObj[];
}

export interface ISignedUploadCsvsBody {
  sessionID: string;
  message: string;
  presignedURLs: ISignedUrlBody[];
}

export const awsHeader = 'x-amz-tagging';
