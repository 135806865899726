<div class="nav-menu clean">
    <ng-template [ngIf]="item.children" [ngIfElse]="noChildren">
        <li (click)="clicked()" class="nav-item" [class.is-expanded]="item.isExpanded">
            <span>{{item.name}}</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"/></svg>
        </li>
        <li class="children" [class.is-expanded]="item.isExpanded" (click)="setToFalse()">
            <a *ngFor="let child of item.children" class="link" [routerLink]="child.route" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact:true}">{{child.name}}</a>
        </li>
        <li class="backsplash" (click)="setToFalse()" [class.is-expanded]="item.isExpanded">&nbsp;</li>
    </ng-template>
    <ng-template #noChildren>
        <a class="nav-item" [routerLink]="item.route" routerLinkActive="active">{{item.name}}</a>
    </ng-template>
</div>
