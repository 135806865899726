import { Component, OnInit } from '@angular/core';

import { Toast } from './toast';
import { ToasterService } from './toaster.service';

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss'],
})
export class ToasterComponent implements OnInit {
  public toasts: Array<Toast> = [];
  private tId = 0;

  constructor(private tService: ToasterService) {}

  ngOnInit(): void {
    this.tService.content.subscribe((t) => {
      if (t) {
        t.id = ++this.tId;
        t.timeout = setTimeout(this.clearToast.bind(this), toastLength, t);
        this.toasts.push(t);
      }
    });
  }

  clearToast(toast: Toast): void {
    const t = this.toasts;
    for (let i = 0, l = t.length; i < l; ++i) {
      if (toast.id === t[0].id) {
        clearTimeout(toast.timeout);
        t.splice(i, 1);
        return;
      }
    }
  }
}

export const toastLength = 9000;
