import { Component, OnInit } from '@angular/core';
import { SpinnerService } from './spinner.service';

@Component({
  selector: 'app-spinner',
  template:
    '<div class="spinner" *ngIf="showSpinner"><div class="back-drop">&nbsp;</div><img class="rotating" src="/assets/images/spinner.png"></div>',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnInit {
  private count = 0;

  public get showSpinner(): boolean {
    return this.count > 0;
  }

  constructor(private spinnerService: SpinnerService) {}

  ngOnInit(): void {
    this.spinnerService.content.subscribe((isLoading) => {
      if (isLoading != null) {
        if (isLoading) {
          ++this.count;
        } else {
          this.count = this.count === 0 ? 0 : this.count - 1;
        }
      }
    });
  }
}
