import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { MappingReviewTableDataSource } from './apprf-mapping-review-datasource';
import { AbstractControl } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { RoleFilter } from 'src/app/models/ApplicationRoleFilters';
@Component({
  selector: 'app-apprf-mapping-review-table',
  templateUrl: './apprf-mapping-review-table.component.html',
  styleUrls: ['./apprf-mapping-review-table.scss'],
})
export class AppRfMappingReviewTableComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<RoleFilter>;
  @Input() dispRowKeys: string[] = [];
  @Input() dispColumns: string[] = [];
  @Input() acDataAppRF: AbstractControl;
  @Input() filterKeyOriginal = '';
  @Input() filterKeyCurrent = '';
  @Input() originalMapped = '';
  @Input() actionType = '';

  dataSource: MappingReviewTableDataSource;

  private behSub: BehaviorSubject<RoleFilter[]>;
  private subs: Subscription[] = [];

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (this.acDataAppRF) {
      this.behSub = new BehaviorSubject<RoleFilter[]>(this.acDataAppRF.value);
      this.dataSource = new MappingReviewTableDataSource(
        this.behSub.asObservable(),
        this.filterKeyOriginal,
        this.filterKeyCurrent,
        this.originalMapped,
        this.actionType,
      );
      this.subs.push(
        this.acDataAppRF.valueChanges.subscribe((d) => {
          this.behSub.next(d);
        }),
      );

      this.dataSource.paginator = this.paginator;
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
      this.table.dataSource = this.dataSource;
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
    if (this.behSub) {
      this.behSub.complete();
    }
  }
}
