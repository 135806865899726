export enum EDataImpactTable {
  accessPackage = 'AccessPackage',
  application = 'IGAAppId',
  areaOfInterest = 'AreaOfInterest',
  businessRole = 'BusinessRole',
  dataSelection = 'DataSelection',
  dataSelectionGroup = 'DataSelectionGroup',
  dimension = 'Dimension',
  dimensionType = 'DimensionType',
  entitlement = 'Entitlement',
  roleFilter = 'RoleFilter',
  flterSelection = 'FilterSelection',
  sodPolicy = 'SODPolicy',
}

export enum EDataImpactHBState {
  notStarted = 'NOT-STARTED',
  succeeded = 'SUCCEEDED',
  running = 'RUNNING',
  failed = 'FAILED',
}

export interface IDataImpactKeys {
  key: string;
  active: boolean;
}

export interface IDataImpactTable {
  name: string;
  keys: IDataImpactKeys[];
}

export interface IDataImpactPreviewPayload {
  tables: IDataImpactTable[];
}

export interface IDataImpactDownloadPayload {
  tables: IDataImpactTable[];
}

export interface IDataImpactCsv {
  name: string;
  count: number;
  tableColumns?: string[];
  tableKeys?: string[];
  data?: any[];
}

export interface IDataImpactRes {
  impactedDataPreviews: IDataImpactCsv[];
}

export interface IDataImpactDownloadHBRes {
  csvDownloadHeartbeatId: string;
}

export interface IDataImpactHeartBeatRes {
  downloadId: string;
  downloadStatus: EDataImpactHBState;
}

export interface IDataImpactPresignedRes {
  signedUrl: string;
}
