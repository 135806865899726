import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from 'src/app/util/API';
import {
  Observable,
  tap,
  mergeMap,
  catchError,
  forkJoin,
  map,
  switchMap,
} from 'rxjs';
import { PopUpService } from 'src/app/singletons/popup/popup.service';
import { SpinnerService } from 'src/app/singletons/spinner/spinner.service';
import { ToasterService } from 'src/app/singletons/toaster/toaster.service';
import {
  IvbFileNames,
  ISignedUploadCsvsBody,
  ISignedUrlBody,
  awsHeader,
} from 'src/app/models/ISignedUrl';
import {
  AsmAccPkgPostResponse,
  AsmPostResponse,
} from 'src/app/models/AsmPostResponse';
import { AuthService } from 'src/app/auth/auth.service';
import { LaunchdarklyService } from 'src/app/singletons/launchdarkly.service';
import { IworkItem } from 'src/app/models/IVB';
@Injectable({
  providedIn: 'root',
})
export class IvbApiService extends API {
  constructor(
    http: HttpClient,
    pService: PopUpService,
    spinner: SpinnerService,
    toaster: ToasterService,
    ldService: LaunchdarklyService,
    private authService: AuthService,
  ) {
    super(http, pService, spinner, toaster, ldService);
  }
  private readonly paths = {
    uploadIvb: `${this.rootPath}intakevalidation/signed-urls`,
    invokeIvb: `${this.rootPath}intakevalidation/start`,
    workItemDetails: `${this.rootPath}intakevalidation/workitemattachmentdetails`,
    submitWorkItem: `${this.rootPath}intakevalidation/submitattachments`,
  };
  /* upload File */
  public uploadCsv(params: FormData): Observable<void> {
    this.spinner.show();
    const fileNamesData = JSON.parse(params.get('fileNames') as string);
    const emailsData = JSON.parse(params.get('emails') as string);
    return this.getPresignedUrl({
      filenames: fileNamesData,
      emails: emailsData,
    }).pipe(
      mergeMap((body: ISignedUploadCsvsBody) => {
        // Pass the presigned URLs array to the uploadFileHelper
        return this.uploadFileHelper(
          body.presignedURLs,
          params,
          body.sessionID,
        );
      }),
      catchError(this.handleError<any>('Uploading a IVB file')),
    );
  }
  public getPresignedUrl(
    emails: IvbFileNames,
  ): Observable<ISignedUploadCsvsBody> {
    return this.http.post<ISignedUploadCsvsBody>(
      `${this.paths.uploadIvb}`,
      emails,
    );
  }
  public uploadFileHelper(
    urls: ISignedUrlBody[],
    files: FormData,
    sessionId: string,
  ): Observable<void> {
    if (!urls || urls.length === 0) {
      throw new Error('No signed URLs provided');
    }
    const uploadRequests: Observable<void>[] = [];
    urls.forEach((url, index) => {
      const h = {};
      h[awsHeader] = url.tag;
      // Assuming files are appended with 'file0', 'file1', etc.
      const fileToUpload = files.get(`file${index}`);
      if (fileToUpload) {
        const request = this.http.put<void>(url.signedUrl, fileToUpload, {
          headers: h,
        });
        uploadRequests.push(request);
      }
    });
    // Use forkJoin to combine multiple PUT requests and wait for all to finish
    return forkJoin(uploadRequests).pipe(
      // Once all file uploads are successful, perform the POST request
      switchMap(() => {
        // Prepare the payload for the POST call
        const ivbStartPayload = {
          csvs: JSON.parse(files.get('fileNames') as string),
          sessionID: sessionId,
          requestedBy: this.authService.currUser.email,
          recipients: JSON.parse(files.get('emailsArray') as string),
          environment: files.get('environment') as string,
        };
        // Make the POST request
        return this.postReqHelper<AsmAccPkgPostResponse>(
          `${this.paths.invokeIvb}`,
          ivbStartPayload,
          `Uploading IVB file`,
        ).pipe(
          tap(() =>
            this.toaster.show(
              `IVB File upload`,
              `Intake Validation was successful`,
            ),
          ),
        );
      }),
      // Once the POST request is complete, return void
      map(() => void 0),
    );
  }
  /** @description get work item attachments */
  public getWorkAttachments(key: number): Observable<IworkItem> {
    return this.getReqHelper<IworkItem>(
      `${this.paths.workItemDetails}/${key}`,
      `Getting work item attachment details`,
    ).pipe(map((data) => data));
  }
  public uploadWorkItem(params: FormData): Observable<AsmPostResponse> {
    const ivbWorkItemPayload = {
      workItem: JSON.parse(params.get('workItem') as string),
      sessionID: params.get('sessionID') as string,
      requestedBy: this.authService.currUser.email,
      recipients: JSON.parse(params.get('emailsArray') as string),
      environment: params.get('environment') as string,
    };
    return this.postReqHelper<AsmPostResponse>(
      `${this.paths.submitWorkItem}`,
      ivbWorkItemPayload,
      'Uploading IVB file',
    ).pipe(
      tap({
        complete: () => {
          this.toaster.show(
            `IVB File upload`,
            `Intake Validation was successful`,
          );
        },
      }),
    );
  }
}
