import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, tap, catchError, throwError, of, map } from 'rxjs';
import { environment } from 'src/environments';
import { PopUpService } from '../singletons/popup/popup.service';
import { SpinnerService } from '../singletons/spinner/spinner.service';
import { ToasterService } from '../singletons/toaster/toaster.service';
import { IActionPkg } from '../models/Actions';
import { Application, IPayLoadApp } from '../models/Application';
import { AsmPostResponse, IRequestIdResp } from '../models/AsmPostResponse';
import { Dimension, IDimensionPayload } from '../models/Dim';
import { DimType, IDimTypePayload } from '../models/DimType';
import { APIVars } from './API-vars';
import {
  EDataImpactHBState,
  IDataImpactDownloadHBRes,
  IDataImpactHeartBeatRes,
  IDataImpactDownloadPayload,
  IDataImpactPresignedRes,
  IDataImpactRes,
  IDataImpactTable,
  IDataImpactPreviewPayload,
  IDataImpactCsv,
} from '../models/DataImpact';
import { IBrMd, IBrPayload } from '../models/BrSml';

const asmAPI = {
  apps: `${environment.api.asm}applications`,
  appsEligible: `${environment.api.asm}applications/eligible`,
  appsOnboarded: `${environment.api.asm}applications/onboarded`,
  br: `${environment.api.asm}businessroles`,
  dataImpact: `${environment.api.asm}data-impact`,
  cancel: `${environment.api.asm}requests/cancel`,
  dataSel: `${environment.api.asm}dataselections`,
  dataSelGrp: `${environment.api.asm}dataselectiongroups`,
  dataSelMapping: `${environment.api.asm}dataselectiondimensions`,
  dims: `${environment.api.asm}dimensions`,
  dimType: `${environment.api.asm}dimensiontypes`,
  entDims: `${environment.api.asm}entitlementdimensions`,
  envs: `${environment.api.asm}environments`,
  ing: `${environment.api.asm}ingestions`,
  requestId: `${environment.api.asm}requests/new`,
  validations: `${environment.api.asm}validations`,
};

export abstract class API {
  protected readonly rootPath = environment.api.asm;
  protected readonly rootPathCalendar = environment.api.releasecalendar;
  constructor(
    protected http: HttpClient,
    protected pService: PopUpService,
    protected spinner: SpinnerService,
    protected toaster: ToasterService,
  ) {}

  // ------------------------------------------ shared calls
  // ************************************************** Request id
  /** @description returns the current request id */
  public getReqId(): string | undefined {
    return APIVars.reqId;
  }

  /** @description fetches a new request id WITHOUT setting it */
  public getNoSetReqId(): Observable<IRequestIdResp> {
    return this.getReqHelper<IRequestIdResp>(
      asmAPI.requestId,
      'Fetch request id',
      // Need to add the Cache-Control header
      // in order to get a freshly generated
      // RequestID, instead of the one store in
      // API Gateway's cache.
      new HttpHeaders({
        'Cache-Control': 'max-age=0',
      }),
    );
  }

  /** @description fetches a new request id and sets it */
  public setReqId(): Observable<IRequestIdResp> {
    return this.getReqHelper<IRequestIdResp>(
      asmAPI.requestId,
      'Get request id',
      // Need to add the Cache-Control header
      // in order to get a freshly generated
      // RequestID, instead of the one store in
      // API Gateway's cache.
      new HttpHeaders({
        'Cache-Control': 'max-age=0',
      }),
    ).pipe(tap((data) => (APIVars.reqId = data.requestID)));
  }

  /** @description returns true if there is a request id */
  public hasReqId(): boolean {
    return !!APIVars.reqId;
  }

  /** @description clears the current request id */
  public clearReqId(): void {
    APIVars.reqId = undefined;
  }

  /** @description Invoke postIngestions only when the updates succeed  */
  public postIngestions(
    requsetId: string,
    action: IActionPkg,
  ): Observable<AsmPostResponse> {
    const bdy = { action: action.val, requestID: requsetId };
    return this.postReqHelper<AsmPostResponse>(
      asmAPI.ing,
      bdy,
      'Post ingestion',
    ).pipe(
      tap({
        complete: () => {
          this.toaster.show(
            'Post Ingestion',
            `Post ingestion for action: ${action.name}`,
          );
        },
      }),
    );
  }

  /** @description cancel ingestions only when the add/update succeeded  */
  public cancelIngestions(
    requsetId: string,
    data: any,
  ): Observable<AsmPostResponse> {
    this.spinner.show();
    return this.http.post(`${asmAPI.cancel}/${requsetId}`, data).pipe(
      tap({
        complete: () => {
          this.spinner.hide();
          this.toaster.show('Ingestion cancelled', `Request id: ${requsetId}`);
        },
      }),
      catchError(this.handleError<any>('Cancel ingestion')),
    );
  }

  public clearEntDimCache(): void {
    APIVars.cacheDimTypes = undefined;
    APIVars.cacheDims = {};
  }

  // ************************************************** data-impact
  /** @description makes a post request to get all impacted tables:
   * `data-impact preview` */
  public getDataImpactPreview(
    tables: IDataImpactTable[],
  ): Observable<IDataImpactCsv[]> {
    const params: IDataImpactPreviewPayload = {
      tables: tables,
    };
    return this.postReqHelper<IDataImpactRes>(
      `${asmAPI.dataImpact}/preview`,
      params,
      `Getting data-impact preview`,
    ).pipe(map((d) => d.impactedDataPreviews));
  }

  /** @description makes a post request to get the download heartbeat id */
  public getDataImpactDownloadHBId(
    tables: IDataImpactTable[],
  ): Observable<string> {
    const params: IDataImpactDownloadPayload = {
      tables: tables,
    };
    return this.postReqHelper<IDataImpactDownloadHBRes>(
      `${asmAPI.dataImpact}/download`,
      params,
      `Getting data-impact download heartbeat id`,
    ).pipe(map((data) => data.csvDownloadHeartbeatId));
  }

  /** @description Check the data-impact download heartbeat for completion */
  public checkDataImpactHeartBeat(
    heartBeatId: string,
  ): Observable<EDataImpactHBState> {
    return this.http
      .get<IDataImpactHeartBeatRes>(
        `${asmAPI.dataImpact}/download/${heartBeatId}/heartbeat`,
      )
      .pipe(
        map((d) => d.downloadStatus),
        catchError(
          this.handleError<any>('Polling data-impact download heartbeat'),
        ),
      );
  }

  /** @description get data-impact presigned url.
   * Make request after polling returns completed */
  public getDataImpactPresignedUrl(heartBeatId: string): Observable<string> {
    return this.http
      .get<IDataImpactPresignedRes>(
        `${asmAPI.dataImpact}/download/${heartBeatId}/presigned`,
      )
      .pipe(
        map((d) => d.signedUrl),
        catchError(this.handleError<any>('Get data-impact presigned URL')),
      );
  }

  // ************************************************** APP
  /** @description get all applications
   * @param `viaRoleFiltering` (true) Applications returned will be specific to the users role. *Note:* `Admin` role will return all applications
   *
   * (false) All applications will be returned, regardless of role
   */
  public getApps(viaRoleFiltering = true): Observable<Application[]> {
    let url = asmAPI.apps;
    this.resetApps();
    if (!viaRoleFiltering) {
      if (APIVars.allApps) {
        return of(APIVars.allApps);
      }
      url += '/all';
    } else if (APIVars.roleApps) {
      return of(APIVars.roleApps);
    }
    return this.getReqHelper<IPayLoadApp>(url, 'Get applications').pipe(
      map((data) => {
        const arr = data.applications
          .map((d) => new Application(d))
          .sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
          });
        if (viaRoleFiltering) {
          APIVars.roleApps = arr;
        } else {
          APIVars.allApps = arr;
        }
        return arr;
      }),
    );
  }

  // ************************************************** APP
  /** @description get eligible applications
   * @param `viaRoleFiltering` (true) Applications returned will be specific to the users role. *Note:* `Admin` role will return all eligible applications
   *
   * @param `viaRoleFiltering` (false) All applications will be returned, regardless of role
   */
  public getAppsEligible(viaRoleFiltering = true): Observable<Application[]> {
    const url = asmAPI.appsEligible;
    this.resetApps();
    if (!viaRoleFiltering) {
      if (APIVars.allApps) {
        return of(APIVars.allApps);
      }
    } else if (APIVars.roleApps) {
      return of(APIVars.roleApps);
    }
    return this.getReqHelper<IPayLoadApp>(
      url,
      'Get eligible applications',
    ).pipe(
      map((data) => {
        const arr = data.applications
          .map((d) => new Application(d))
          .sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
          });
        if (viaRoleFiltering) {
          APIVars.roleApps = arr;
        } else {
          APIVars.allApps = arr;
        }
        return arr;
      }),
    );
  }

  // ************************************************** APP
  /** @description get onboarded applications
   * @param `viaRoleFiltering` (true) Applications returned will be specific to the users role. *Note:* `Admin` role will return all onboarded applications
   *
   * (false) All applications will be returned, regardless of role
   */
  public getAppsOnboarded(viaRoleFiltering = true): Observable<Application[]> {
    const url = asmAPI.appsOnboarded;
    this.resetApps();
    if (!viaRoleFiltering) {
      if (APIVars.allApps) {
        return of(APIVars.allApps);
      }
    } else if (APIVars.roleApps) {
      return of(APIVars.roleApps);
    }
    return this.getReqHelper<IPayLoadApp>(
      url,
      'Get onboarded applications',
    ).pipe(
      map((data) => {
        const arr = data.applications
          .map((d) => new Application(d))
          .sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
          });
        if (viaRoleFiltering) {
          APIVars.roleApps = arr;
        } else {
          APIVars.allApps = arr;
        }
        return arr;
      }),
    );
  }

  /** @description reset the apps arrays */
  public resetApps(): void {
    APIVars.allApps = undefined;
    APIVars.roleApps = undefined;
  }

  // ************************************************** Business Roles
  /** @description get all active/visible business roles */
  public getBrs(): Observable<IBrMd[]> {
    return this.getReqHelper<IBrPayload>(
      asmAPI.br,
      'Getting all business roles',
    ).pipe(map((brPayload) => brPayload.businessRoles));
  }

  // ************************************************** Dimension
  /**
   * get all dimensions for EMD. *Caches result
   * @param dimTypeKey the dimension type key
   * */
  public getDimension(dimTypeKey: string): Observable<Dimension[]> {
    if (APIVars.cacheDims[dimTypeKey]) return of(APIVars.cacheDims[dimTypeKey]);

    const url = `${asmAPI.dimType}/${dimTypeKey}/dimensions`;
    return this.getReqHelper<IDimensionPayload>(
      url,
      `Getting dimensions from ${dimTypeKey}`,
    ).pipe(
      map((d) => {
        return d.dimensions
          .map((x, i) => new Dimension(x, i))
          .sort((a, b) => {
            if (a.value === 'Global') return -1;
            if (b.value === 'Global') return 1;
            if (a.value < b.value) return -1;
            if (a.value > b.value) return 1;
            return 0;
          });
      }),
      tap((d) => (APIVars.cacheDims[dimTypeKey] = d)),
    );
  }

  // ************************************************** Dimension types
  /** get all dimension types for EDM. *Caches result */
  public getDimensionType(): Observable<DimType[]> {
    if (APIVars.cacheDimTypes) return of(APIVars.cacheDimTypes);

    return this.getReqHelper<IDimTypePayload>(
      asmAPI.dimType,
      'Getting dimension types',
    ).pipe(
      map((d) => {
        return d.dimensionTypes
          .map((x, i) => new DimType(x, i))
          .sort((a, b) => {
            if (a.display === 'Global') return -1;
            if (b.display === 'Global') return 1;
            if (a.display < b.display) return -1;
            if (a.display > b.display) return 1;
            return 0;
          });
      }),
      tap((d) => (APIVars.cacheDimTypes = d)),
    );
  }

  // ------------------------------------------ protected calls
  /** @description protected helper for basic GET request: show/hide spinner and error handling  */
  protected getReqHelper<T>(
    url: string,
    operation: string,
    headers?: HttpHeaders,
  ): Observable<T> {
    this.spinner.show();
    return this.http
      .get<T>(url, {
        headers: headers,
      })
      .pipe(
        tap({
          complete: () => {
            this.spinner.hide();
          },
        }),
        catchError(this.handleError<any>(operation)),
      );
  }

  /** @description protected helper for basic POST request: show/hide spinner and error handling  */
  protected postReqHelper<T>(
    url: string,
    data: any,
    operator: string,
    callback?: () => void,
  ): Observable<T> {
    this.spinner.show();
    const observable = this.http.post<T>(url, data).pipe(
      tap({
        next: () => {
          // Hides the spinner for successful responses
          this.spinner.hide();
          if (callback) {
            callback();
          }
        },
        complete: () => {
          // Ensure spinner is hidden when the observable completes
          this.spinner.hide();
        },
        error: () => {
          // Hides the spinner in case of an error
          this.spinner.hide();
        },
      }),
    );

    if (!callback) {
      return observable.pipe(
        catchError((error) => {
          return this.handleError<any>(operator)(error);
        }),
      );
    } else {
      return observable;
    }
  }

  /** @description protected helper for basic DELETE request: show/hide spinner and error handling  */
  protected deleteReqHelper<T>(
    url: string,
    operator: string,
    callback?: () => void,
  ): Observable<T> {
    this.spinner.show();
    const observable = this.http.delete<T>(url).pipe(
      tap({
        next: () => {
          // This will be executed when the HTTP request is successful
          this.spinner.hide();
          if (callback) {
            callback();
          }
        },
        complete: () => {
          // Ensure spinner is hidden when the observable completes
          this.spinner.hide();
        },
        error: () => {
          // This will be executed when there is an error in the HTTP request
          this.spinner.hide();
        },
      }),
    );

    if (!callback) {
      return observable.pipe(
        catchError((error) => {
          return this.handleError<any>(operator)(error);
        }),
      );
    } else {
      return observable;
    }
  }

  /** @description protected error handler for all api requests */
  protected handleError<T>(operation: string): (err: any) => Observable<T> {
    return (err: HttpErrorResponse): Observable<any> => {
      if (operation !== 'no show') {
        let msg = [err.message];
        if (err.error?.message) {
          msg = Array.isArray(err.error.message) ? msg : [err.error.message];
          if (Array.isArray(err.error.errors)) {
            msg = msg.concat(err.error.errors);
          }
          if (err.error['x-request-id']) {
            msg.push(`Request ID: ${err.error['x-request-id']}`);
          }
        }
        this.pService.show(`(${err.status}) ${operation}`, msg);
      }
      this.spinner.hide();
      return throwError(() => err);
    };
  }
}
