<div class="listbox" [class.disabled]="isDisabled">
    <div class="container">
        <div class="col left">
            <div class="select-box left">
                <label class="listbox-label">{{leftListboxLabel}} {{lftFilteredData.length}}
                    <app-info-tooltip *ngIf="leftTooltipText" hoverText="{{leftTooltipText}}"></app-info-tooltip>
                </label>
                <app-input-keypress class="options-search" label="Search" placeholder="Search"
                    (keyPressed)="filterLftBox($event)" (inputFocus)="markTouched()">
                </app-input-keypress>
                <div class="paginator" *ngIf="lftLen > pMaxNumber">
                    <span>Page <b>{{lftCurrPage}}</b> of {{lftTotalPages}}</span>
                    <div class="btn sml-btn btn-two" mat-button (click)="prevPage()"
                        [class.disabled]="lftCurrPage === 1" id="mat-btn-prv">&lt;
                    </div>
                    <div class="btn sml-btn btn-two" mat-button (click)="nextPage()"
                        [class.disabled]="lftCurrPage >= lftTotalPages" id="mat-btn-nxt">
                        &gt;</div>
                    <i>{{pMaxNumber}} items per page</i>
                </div>
                <ul class="clean" [ngClass]="{'custom-tooltip': isTooltipDisabled}">
                    <ng-container *ngFor="let d of lftFilteredData.slice(lftPageStart, lftPageEnd)">
                        <li *ngIf="!d[selectedKey]" (click)="onLftHighlight(d)" [class.highlighted]="d.highlighted"
                            [class.disabled]="shouldDisable(d)"
                            [matTooltip]="d[displayKey]" matTooltipClass="tt-truncate" matTooltipShowDelay="311" [matTooltipDisabled]="isTooltipDisabled">
                            <ng-template [ngIf]="!itemTemplate">
                                {{d[displayKey]}}
                            </ng-template>
                            <ng-container [ngIf]="itemTemplate" *ngTemplateOutlet="itemTemplate; context:{data:d}">
                            </ng-container>
                        </li>
                    </ng-container>
                </ul>
            </div>
        </div>
        <div class="col controls-wrapper">
            <div class="listbox-controls">
                <button class="listbox-button" (click)="addItems()">&gt;</button>
                <button class="listbox-button" (click)="removeItems()">&lt;</button>
            </div>
        </div>
        <div class="col right">
            <div class="select-box right">
                <label class="listbox-label">{{rightListboxLabel}} {{rhtData.length}}
                    <app-info-tooltip *ngIf="rightTooltipText" hoverText="{{rightTooltipText}}"></app-info-tooltip>
                </label>
                <ul class="clean" [ngClass]="{'custom-tooltip': isTooltipDisabled}">
                    <ng-container *ngFor="let d of rhtData">
                        <li *ngIf="d[selectedKey]" (click)="onRhtHighlight(d)" [class.highlighted]="d.highlighted"
                            [matTooltip]="d[displayKey]" matTooltipClass="tt-truncate" matTooltipShowDelay="388" [matTooltipDisabled]="isTooltipDisabled">
                            <ng-template [ngIf]="!itemTemplate">
                                {{d[displayKey]}}
                            </ng-template>

                            <ng-container [ngIf]="itemTemplate" *ngTemplateOutlet="itemTemplate; context:{data:d}">
                            </ng-container>
                        </li>
                    </ng-container>
                </ul>
            </div>
        </div>
    </div>
</div>
