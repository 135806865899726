<app-header-nav></app-header-nav>
<div id="main">
  <div class="main-inlet" id="home">
    <router-outlet></router-outlet>
  </div>
</div>
<footer id="collapsable-foot" [class.show-footer]="showFooter">
  <div class="hide-tab" (click)="toggle()">Hide</div>
  <div class="left">
    <a target="_blank" class="bold link"
      href="https://in.accenture.com/protectingaccenture/data-security/5422-2/">Privacy policy</a>
    <a target="_blank" class="bold link"
      href="https://www.accenture.com/us-en/support/company-cookies-similar-technology">Cookie policy</a>
    <a target="_blank" class="bold link"
      href="https://support.accenture.com/support_portal?id=kb_article&sys_id=7da0dd89db614c906c7f3318f49619be">Terms of
      use</a>
  </div>
  <div class="right">
    &copy; {{year}} Accenture. All rights reserved. Accenture highly confidential. For internal use only.
  </div>
  <div class="toggle-btn" (click)="toggle()">&#x203A;</div>
</footer>
<app-popup></app-popup>
<app-spinner></app-spinner>
<app-toaster></app-toaster>