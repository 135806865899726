import * as msal from '@azure/msal-browser';
import jwtDecode from 'jwt-decode';

const adminRoleName = 'ASM.Admin';

export type idTokenClaims = {
  roles: string[];
  exp: number;
};

export class User {
  displayName = '';
  email = '';
  eid = '';
  oid = '';
  roles = [];
  airIds: string[] = [];
  exp?: Date;
  private _accessToken: string = null;

  isAdmin = false;
  hasAirIds = false;

  constructor(u: msal.AccountInfo) {
    if (u && u.idTokenClaims) {
      this.displayName = u.name || '';
      this.email = u.username;
      this.eid = getEid(u.username);
      this.oid = u.localAccountId;
      this.roles = (u.idTokenClaims as idTokenClaims)?.roles || [];
      this.exp = new Date((u.idTokenClaims as idTokenClaims).exp * 1000);
    } else {
      this.roles = [];
    }
  }

  refreshToken(u: msal.AuthenticationResult): void {
    this.exp = u.expiresOn;
    this._accessToken = u.accessToken;

    const decoded = jwtDecode<idTokenClaims>(u.accessToken);
    this.airIds = getAirIds(decoded.roles);
    this.hasAirIds = this.airIds.length > 0;
    this.isAdmin = decoded.roles.includes(adminRoleName);
  }

  get accessToken(): string {
    return this._accessToken;
  }

  get isTokenExpired(): boolean {
    if (!this.exp) {
      return true;
    }
    const fifMinFromNow = new Date();
    fifMinFromNow.setMinutes(fifMinFromNow.getMinutes() + 15);
    return fifMinFromNow > this.exp;
  }
}

function getEid(str: string): string {
  if (str && str.length > 0) {
    const index = str.indexOf('@');
    return index > 0 ? str.substring(0, index) : str;
  }
  return '';
}

function getAirIds(roles: string[]): string[] {
  const uniqueAirIDs = new Set<string>();
  roles.forEach((role) => {
    const oldFormatMatch = role.match(/^myAccess\.FFT\.([1-9]\d{0,9})$/);
    if (oldFormatMatch && oldFormatMatch.length > 1) {
      uniqueAirIDs.add(oldFormatMatch[1]);
      return;
    }
  });
  return [...uniqueAirIDs];
}
