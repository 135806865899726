import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-input-keypress',
  template: `
    <div class="keypress-component">
      <input
        matInput
        type="text"
        [placeholder]="placeholder"
        (focus)="onFocus()"
        (keydown)="handleKeyDown($event)"
        [formControl]="search"
      />
      <div
        *ngIf="search.value.length > 0"
        class="close-btn"
        (click)="clearClicked()"
      >
        &#10006;
      </div>
    </div>
  `,
  styleUrls: ['./keypress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeypressComponent implements OnInit, OnDestroy {
  @Input() placeholder: string;
  @Input() clear?: Observable<boolean>;
  @Output() keyPressed = new EventEmitter();
  @Output() inputFocus = new EventEmitter();

  search = new FormControl('');
  private filterTimeout: NodeJS.Timeout;
  private subs: Subscription[] = [];
  private wasClearClicked = false;

  ngOnInit(): void {
    this.subs.push(
      this.search.valueChanges.subscribe((val) => {
        clearTimeout(this.filterTimeout);
        if (this.wasClearClicked) {
          this.wasClearClicked = false;
          return;
        }
        this.filterTimeout = setTimeout(() => {
          this.keyPressed.emit(val);
        }, 500);
      }),
    );

    if (this.clear) {
      this.subs.push(
        this.clear.subscribe((emitEvent) => {
          this.search.setValue('', { emitEvent: emitEvent });
        }),
      );
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach((s) => s.unsubscribe());
  }

  onFocus(): void {
    if (this.inputFocus) this.inputFocus.emit();
  }

  handleKeyDown(event: KeyboardEvent): void {
    if (event.key === ' ') {
      this.search.setValue(this.search.value + ' ');
    }
  }

  clearClicked(): void {
    this.wasClearClicked = true;
    this.search.setValue('');
    this.keyPressed.emit('');
  }
}
