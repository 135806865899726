import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, tap, throwError, switchMap, catchError } from 'rxjs';
import { API } from 'src/app/util/API';
import { AppDeets, IAppDeetsPayload } from 'src/app/models/AppDeets';
import {
  AsmPostResponse,
  IFGvalidation,
  AsmBrPostResponse,
} from 'src/app/models/AsmPostResponse';
import { Br, Ibr } from 'src/app/models/Br';
import {
  EntDims,
  IEMP,
  IPostEntitlementDimension,
  Entitlement,
  IAvailableEntitlements,
  ETier,
  IEntDimValPayload,
} from 'src/app/models/EntitlementDimensions';
import { PopUpService } from 'src/app/singletons/popup/popup.service';
import { SpinnerService } from 'src/app/singletons/spinner/spinner.service';
import { ToasterService } from 'src/app/singletons/toaster/toaster.service';
import { msmActions } from '../actions-objective';
import { ISODPayload, SOD } from 'src/app/models/SOD';
import { CrudStppr } from 'src/app/models/CrudStppr';
import { AppAirDeets } from 'src/app/models/AppAir';
import { AppOnboardingStatus } from 'src/app/models/AppOnboardingStatus';
import { EAction, EMappingAction } from 'src/app/models/Actions';
import {
  AppRF,
  IAppRFPayload,
  IAppRFPostPayload,
} from 'src/app/models/ApplicationRoleFilters';
import {
  BRFilterSelections,
  IBRFilterSelectionsPayload,
  IBRFilterSelectionPostPayload,
  IRoleFilterByIgaAppId,
  RoleFilterByIgaAppId,
} from 'src/app/models/BusinessRoleFilterSelections';
import {
  BRCapability,
  IBRCapPayload,
  IBRCapabilityPostPayload,
} from 'src/app/models/BusinessRoleCapability';
import {
  IBrMDataSelPayload,
  IBrMappedDataSel,
  IBrMappedDataSelPayload,
  IBrValMdataSel,
} from 'src/app/models/BusinessRoleDataSelection';

@Injectable({
  providedIn: 'root',
})
export class AppBrEdmApiService extends API {
  private cacheEntDims: { [key: string]: EntDims } = {};

  constructor(
    http: HttpClient,
    pService: PopUpService,
    spinner: SpinnerService,
    toaster: ToasterService,
  ) {
    super(http, pService, spinner, toaster);
  }

  private readonly paths = {
    apps: `${this.rootPath}applications`,
    onboardApps: `${this.rootPath}applications/onboarded`,
    filterSelections: `${this.rootPath}rolefilterselections`,
    appsAir: `${this.rootPath}applications/getappdetailsfromair`,
    br: `${this.rootPath}businessroles`,
    dataSel: `${this.rootPath}dataselections`,
    dataSelGrp: `${this.rootPath}dataselectiongroups`,
    dims: `${this.rootPath}dimensions`,
    dimType: `${this.rootPath}dimensiontypes`,
    entDims: `${this.rootPath}entitlementdimensions`,
    envs: `${this.rootPath}environments`,
    ing: `${this.rootPath}ingestions`,
    requestId: `${this.rootPath}requests/new`,
    validations: `${this.rootPath}validations`,
    sod: `${this.rootPath}segregationofduties`,
    brfs: `${this.rootPath}businessroles/businessrolefilterselections`,
    brcapability: `${this.rootPath}businessroles/capabilities`,
    roleFilters: `${this.rootPath}rolefilters`,
    appRf: `${this.rootPath}applications/applicationrolefilters`,
  };

  // -------------------------------------------------------------------------- app

  /** @description get application via `igaAppID` */
  public getApp(igaAppID: number, isElevate: boolean): Observable<AppDeets> {
    return this.getReqHelper<IAppDeetsPayload>(
      `${this.paths.apps}/${igaAppID}`,
      `Getting Application via igaAppId: ${igaAppID}`,
    ).pipe(map((data) => new AppDeets(data.applications[0], isElevate)));
  }

  /**
   * Get the onboarded app details
   * @param igaAppID the id of the app
   * @param isElevate This flag will determine what fields are editable
   * in the `AppDeets` class.
   * @returns Observable<AppDeets>
   */
  public getOnboardedAppDeets(
    igaAppID: number,
    isElevate: boolean,
  ): Observable<AppDeets> {
    return this.getReqHelper<IAppDeetsPayload>(
      `${this.paths.onboardApps}/${igaAppID}`,
      `Getting onboarded application details via igaAppId: ${igaAppID}`,
    ).pipe(map((data) => new AppDeets(data.applications[0], isElevate)));
  }

  /** @description get application details from AIR via `airID`  */
  public getAppAirDeets(airId: number): Observable<AppAirDeets> {
    return this.getReqHelper<AppAirDeets>(
      `${this.paths.appsAir}/${airId}`,
      `Getting application details from Air: ${airId}`,
    ).pipe(map((data) => new AppAirDeets(data)));
  }

  /** @description get application onboarding status via `igaAppID`  */
  public getOnboardingStatus(
    igaAppID: number,
  ): Observable<AppOnboardingStatus> {
    return this.getReqHelper<AppOnboardingStatus>(
      `${this.paths.apps}/${igaAppID}/onboardingstatus`,
      `Getting application onboarding status: ${igaAppID}`,
    ).pipe(map((data) => new AppOnboardingStatus(data)));
  }

  /** @description update the application details */
  public updateApp(appDeets: AppDeets): Observable<AsmPostResponse> {
    return this.postReqHelper<AsmPostResponse>(
      `${this.paths.apps}`,
      { action: 'update', data: [appDeets.getPayload()] },
      'Updating application',
    ).pipe(
      tap({
        complete: () => {
          this.toaster.show(
            'Application updated',
            `${appDeets.appName} updated successfully`,
          );
        },
      }),
    );
  }

  /** @description get mapped filter selections by brKey and available filter selections via `rfKeys` */
  public getBRFilterSelectionByRFKeys(
    brKey: string,
    rfKeys: string[],
  ): Observable<BRFilterSelections> {
    if (!brKey || !rfKeys || rfKeys.length === 0)
      return throwError(() => new Error('Missing required params'));

    const url = `${this.paths.br}/${brKey}/filterselections`;

    const payload = {
      rfKeys: rfKeys,
    };

    // Include the 'Skip-Ingestion' header in the request
    const headers = new HttpHeaders().set('Skip-Ingestion', 'true');

    return this.http
      .post<IBRFilterSelectionsPayload>(url, payload, { headers })
      .pipe(
        map((data) => new BRFilterSelections(data)),
        catchError((error) =>
          throwError(
            () =>
              new Error(`Error getting filter selections: ${error.message}`),
          ),
        ),
      );
  }

  /** @description get mapped role filters and available role filters via `igaAppID` */
  public getAppRoleFilters(igaAppID: number): Observable<AppRF> {
    if (!igaAppID)
      return throwError(() => new Error('Missing required params'));

    const url = `${this.paths.apps}/${igaAppID}/rolefilters`;

    return this.getReqHelper<IAppRFPayload>(
      url,
      `Getting mapped and available role filters`,
    ).pipe(map((data) => new AppRF(data)));
  }

  /** @description Update all application role filter */
  public updateAppRoleFilter(
    appRf: IAppRFPostPayload[],
    actionName: string,
  ): Observable<AsmPostResponse> {
    const params = {
      action:
        actionName === msmActions.connectApp.name ||
        actionName === msmActions.onboardApp.name
          ? 'create'
          : 'update',
      data: appRf,
    };
    return this.postReqHelper<AsmPostResponse>(
      this.paths.appRf,
      params,
      'Update application role filter',
    ).pipe(
      tap({
        complete: () => {
          this.toaster.show(
            'Update AppRolefilter',
            `Updating application role filter`,
          );
        },
      }),
    );
  }

  // ************************************ sod
  /** get all sod */
  public getSOD(): Observable<SOD[]> {
    return this.getReqHelper<ISODPayload>(
      `${this.paths.sod}`,
      `Getting segregation of duties`,
    ).pipe(
      map((data) => {
        return data.segregationOfDuties.map((d, i) => new SOD(d, i));
      }),
    );
  }

  /** @description update the SOD */
  public updateSOD<T>(
    arr: CrudStppr<T>[],
    action: string,
  ): Observable<AsmPostResponse> {
    return this.postReqHelper<AsmPostResponse>(
      `${this.paths.sod}`,
      { action: action, data: arr },
      'Updating Segregation of Duties',
    ).pipe(
      tap({
        complete: () => {
          this.toaster.show(
            'Segregation of Duties',
            `${action} was successful`,
          );
        },
      }),
    );
  }

  /** @description validate SOD */
  public validateSOD(sod: SOD, isNew: boolean): Observable<IFGvalidation> {
    const payload = { SOD: [sod.validationPayload] };
    const action = isNew ? 'create' : 'update';
    return this.postReqHelper<IFGvalidation>(
      `${this.paths.validations}?action=${action}`,
      payload,
      'Segregation of Duties validation',
    );
  }

  /** @description validate the business role against MSM */
  public validateAppFields(app: AppDeets): Observable<IFGvalidation> {
    return this.postReqHelper<IFGvalidation>(
      this.paths.validations,
      { Application: [app.getValidationPayload()] },
      'Application details role validation',
    );
  }

  // -------------------------------------------------------------------------- Capabilities
  /** @description getting capabilities of a BR key in lower tier */
  public getCapabilityLower(br: string): Observable<BRCapability> {
    return this.getReqHelper<IBRCapPayload>(
      `${this.paths.br}/${br}/capabilities?tier=lower`,
      `Getting capabilities of a BR key in lower tier: `,
    ).pipe(map((data) => new BRCapability(data, true)));
  }

  /** @description getting capabilities of a BR key in higher tier */
  public getCapabilityHigher(br: string): Observable<BRCapability> {
    return this.getReqHelper<IBRCapPayload>(
      `${this.paths.br}/${br}/capabilities?tier=higher`,
      `Getting capabilities of a BR key in higher tier: `,
    ).pipe(map((data) => new BRCapability(data, false)));
  }

  /** @description Update business role capability */
  public updateBRCapability(
    brCap: IBRCapabilityPostPayload[],
    actionName: string,
  ): Observable<AsmBrPostResponse> {
    const params = {
      action: actionName === msmActions.onboardApp.name ? 'create' : 'update',
      data: brCap,
    };
    return this.postReqHelper<AsmBrPostResponse>(
      this.paths.brcapability,
      params,
      'Update business role capability',
    ).pipe(
      tap({
        complete: () => {
          this.toaster.show(
            'Update BRCapability',
            `Updating business role capability`,
          );
        },
      }),
    );
  }

  // -------------------------------------------------------------------------- br
  /**
   * @description get business role
   * @param `key` Mapped br key
   */
  public getBr(key: string): Observable<Br> {
    // A br can be mapped to all applications
    return this.getApps(false).pipe(
      switchMap((apps) => {
        return this.getReqHelper<Ibr>(
          `${this.paths.br}/${key}`,
          'getting business role',
        ).pipe(map((br) => new Br(br, apps)));
      }),
    );
  }

  /** @description Update all business roles */
  public updateBrs(
    brs: Ibr[],
    actionName: string,
  ): Observable<AsmPostResponse> {
    const params = {
      action:
        actionName === msmActions.connectApp.name ||
        actionName === msmActions.onboardApp.name
          ? 'create'
          : 'update',
      data: brs,
    };
    return this.postReqHelper<AsmBrPostResponse>(
      this.paths.br,
      params,
      'Update business roles',
    ).pipe(
      tap({
        complete: () => {
          this.toaster.show('Update BR', `Updating business role`);
        },
      }),
    );
  }

  /** @description Update all business role filter selection */
  public updateBRFilterSelection(
    brfs: IBRFilterSelectionPostPayload[],
    actionName: string,
  ): Observable<AsmPostResponse> {
    const params = {
      action:
        actionName === msmActions.connectApp.name ||
        actionName === msmActions.onboardApp.name
          ? 'create'
          : 'update',
      data: brfs,
    };
    return this.postReqHelper<AsmBrPostResponse>(
      this.paths.brfs,
      params,
      'Update business role filter selection',
    ).pipe(
      tap({
        complete: () => {
          this.toaster.show(
            'Update BRFilterSelection',
            `Updating business role filter selection`,
          );
        },
      }),
    );
  }

  /** @description validate the business role against MSM */
  public validateBrFields(
    br: Br,
    actionName: string,
  ): Observable<IFGvalidation> {
    const action =
      actionName === msmActions.connectApp.name ||
      actionName === msmActions.onboardApp.name
        ? 'create'
        : 'update';
    return this.postReqHelper<IFGvalidation>(
      `${this.paths.validations}?action=${action}`,
      { Business: [br.getMsMPayload()] },
      'Business role validation',
    );
  }

  // ---------------------------------------------------------------------------- ent dim

  /** @description get all mapped entitlements and dimensions to a specific env. *Caches result */
  public getEntitlementDimensions(
    igaAppID: number,
    brKey: string,
    tier: ETier,
    active: boolean,
  ): Observable<EntDims> {
    if (!igaAppID)
      return throwError(() => new Error('missing required params'));

    const blob = igaAppID + brKey + tier + active;

    let url = `${this.paths.apps}/${igaAppID}`;
    if (brKey) {
      url += `/businessroles/${brKey}/entitlementdimensions`;
    } else {
      url += '/entitlements';
    }
    url += `?tier=${tier}&active=${active}`;

    return this.getReqHelper<IEMP>(
      url,
      `Getting mapped entitlements and dimensions`,
    ).pipe(
      map((data) => new EntDims(data)),
      tap((data) => (this.cacheEntDims[blob] = data)),
    );
  }

  /** @description gets all available entitlements. */
  public getAvailableEntitlements(
    igaAppID: number,
    tier: ETier,
    active: boolean,
  ): Observable<IAvailableEntitlements> {
    if (!igaAppID)
      return throwError(() => new Error('missing required params'));

    return this.getReqHelper<IAvailableEntitlements>(
      `${this.paths.apps}/${igaAppID}/entitlements/available?tier=${tier}&active=${active}`,
      `Getting available entitlements.`,
    );
  }

  /** @description update mapped entitlement and dimensions using the **create** action. */
  public createEntDimension(
    postEnts: IPostEntitlementDimension[],
  ): Observable<AsmPostResponse> {
    return this.postEntDimension(EAction.create, postEnts);
  }

  /** @description update mapped entitlement and dimensions using the **update** action. */
  public updateEntDimension(
    postEnts: IPostEntitlementDimension[],
  ): Observable<AsmPostResponse> {
    return this.postEntDimension(EAction.update, postEnts);
  }

  /** @description validate the Entitlement dimension against MSM */
  public validateEntMapFields(
    ed: Entitlement,
    actionName: string,
  ): Observable<IFGvalidation> {
    // TODO fix payload with new model layout
    const action =
      actionName === msmActions.connectApp.name ||
      actionName === msmActions.onboardApp.name
        ? 'create'
        : 'update';
    return this.postReqHelper<IFGvalidation>(
      this.paths.validations + `?action=${action}`,
      { EntitlementBRMapping: ed.getEntDimValPayload(true) },
      'Entitlement Dimensions validation',
    );
  }

  /** @description validate the Entitlement dimension against MSM */
  public validateEntitlementBRMapping(
    edm: IEntDimValPayload[],
    tier: ETier,
    action: EAction,
    isMappingUpdate: boolean,
  ): Observable<IFGvalidation> {
    const queryString = `?action=${action}&tier=${tier}&mappingAction=${
      isMappingUpdate ? EMappingAction.update : EMappingAction.create
    }`;

    return this.postReqHelper<IFGvalidation>(
      this.paths.validations + queryString,
      { EntitlementBRMapping: edm },
      'Entitlement Dimensions validation',
    );
  }

  /** @description private post entitlement dimension handler. */
  private postEntDimension(
    action: EAction,
    postEnts: IPostEntitlementDimension[],
  ): Observable<AsmPostResponse> {
    return this.postReqHelper<AsmPostResponse>(
      this.paths.entDims,
      { action: action, data: postEnts },
      `${action} Entitlement Dimensions`,
    ).pipe(
      tap({
        complete: () => {
          const len = postEnts.length;
          if (len > 1) {
            this.toaster.show(
              'Entitlements updated',
              `The entitlements were updated`,
            );
          } else {
            this.toaster.show(
              'Entitlement updated',
              'The entitlement was updated',
            );
          }
        },
      }),
    );
  }

  // ---------------------------------------------------------------------------- data selection

  /** @description get business role data selections */
  public getBrWMappedDataSels(key: string): Observable<IBrMappedDataSel[]> {
    return this.getReqHelper<IBrMappedDataSelPayload>(
      `${this.paths.br}/${key}/dataselections`,
      `Getting business role with data selections`,
    ).pipe(map((data) => data.dataSelectionBusinessRoles));
  }

  /** @description update business role data selections */
  public updateBrWMappedDataSels(
    arr: IBrMDataSelPayload[],
    action: string,
  ): Observable<AsmPostResponse> {
    return this.postReqHelper<AsmPostResponse>(
      `${this.paths.br}/dataselections`,
      {
        action: action,
        data: arr,
      },
      `${action} business role mapped data selections`,
    ).pipe(
      tap(() =>
        this.toaster.show(
          'Business role mapped data selections',
          `Business role mapped data selections successfully updated`,
        ),
      ),
    );
  }

  /** @description validate business role data selections against MSM */
  public validateMappedDataSels(
    data: IBrValMdataSel[],
    isNew: boolean,
  ): Observable<IFGvalidation> {
    const payload = { DataSelectionBusinessRoleMapping: data };
    const action = isNew ? 'create' : 'update';
    return this.postReqHelper<IFGvalidation>(
      `${this.paths.validations}?action=${action}`,
      payload,
      'Business role mapped Data Selections validation',
    );
  }

  /** @description get role filter key by IgaAppId */
  public getRfKeyByIgaAppId(
    igaAppId: string,
  ): Observable<RoleFilterByIgaAppId> {
    if (!igaAppId)
      return throwError(() => new Error('Missing required params'));

    const url = `${this.paths.apps}/${igaAppId}/rolefilterkeys`;

    return this.getReqHelper<IRoleFilterByIgaAppId>(
      url,
      `Getting role filter key by IgaAppId: ${igaAppId}`,
    ).pipe(map((data) => new RoleFilterByIgaAppId(data)));
  }
}
