<div class="mat-elevation-z8">
  <table mat-table class="full-width-table" aria-label="alter rows">

    <!-- loop table display column keys -->
    <ng-container *ngFor="let key of dispRowKeys;
      let i = index" [matColumnDef]="key">
      <th mat-header-cell *matHeaderCellDef>{{ dispColumns[i] }}</th>
      <td mat-cell *matCellDef="let row"
      [ngClass]="{ added: row[filterKeyCurrent],
        removed: !row[filterKeyCurrent]}">
        <p>{{row[key]}}</p>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="dispRowKeys"></tr>
    <tr mat-row *matRowDef="let row; columns: dispRowKeys;"></tr>
  </table>

  <mat-paginator #paginator
    [length]="dataSource?.data?.length" [pageIndex]="0"
    [pageSize]="10" [showFirstLastButtons]="true"
    aria-label="Select page">
  </mat-paginator>
</div>
