<app-app-banner></app-app-banner>
<div class="stepper" *ngIf="!loading">
    <div class="col-lft">
        <mat-card class="demo-inline-calendar-card">
            <mat-calendar *ngIf="!loading" appArrowClick (prevArrowClick)="onPrevArrowClick()"
                (nextArrowClick)="onNextArrowClick()" [dateClass]="dateClass.bind(this)"
                (yearSelected)="handleYearChange()" (monthSelected)="handleMonthChange($event)"
                (selectedChange)="handleDateSelection($event)" (periodButtonClick)="onPeriodButtonClick()"
                [selected]="selectedDateRange"></mat-calendar>
        </mat-card>
    </div>
    <div class="col-rht">
        <div class="eventsDisplay">
            <div class="btn-wrapper lft-rht">
                <div class="lft"></div>
                <div class="rht">
                    <button mat-button type="button" class="btn btn-two" id="addSchedule"
                        (click)="openFormScheduleDialog()">Add
                        Schedule</button>
                    <button mat-button type="button" class="btn btn-one" id="updateEvents"
                        (click)="updateEventsClicked()">Update
                        Events</button>
                </div>
            </div>
            <div class="grouped-event-container">
              <ng-container *ngIf="groupedEvents?.length > 0; else noScheduleMessage">
                  <div class="list-item" *ngFor="let group of groupedEvents">
                    <h4>{{ group.environment }}</h4>
                    <ng-container *ngFor="let date of group.dates">
                      <table class="wdth-100">
                        <tr>
                          <td class="col-date">{{ date.date | MDYFormat }} </td>
                          <td class="col-event">
                            <div *ngFor="let event of date.events; let isLastEvent = last" class="text-left">
                              <table class="wdth-100">
                                <tr>
                                  <td class="wdth-85 text-left">: {{ event.event }} </td>
                                  <td  class="wdth-15 text-left">
                                    <mat-icon aria-hidden="false" aria-label="Example edit icon"
                                      fontIcon="edit"
                                      (click)="openFormScheduleDialogFromUpdateButton(date)"></mat-icon>
                                    <mat-icon aria-hidden="false" aria-label="Example delete icon"
                                      fontIcon="delete" (click)="deleteSchedule(event)"></mat-icon>
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </ng-container>
                  </div>
              </ng-container>
                <ng-template #noScheduleMessage>
                    <!-- Display message when there are no events -->
                    <div class="list-item">No schedule is available for this month.</div>
                </ng-template>
            </div>
        </div>
        <div *ngIf="showEventsForm">
            <!-- Your update events form content goes here -->
            <app-update-event-form [title]="updateEventsLabel"></app-update-event-form>
        </div>
    </div>
</div>
