import { Injectable } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import {
  AccPkg,
  AccPkgMappedBrDim,
  IAccPkgMappedBrDim,
  IAccPkgValMbrDim,
  fgToAccPkg,
  AccPkgMappedDataSel,
  IAccPkgMappedDataSel,
  IAccPkgValMdataSel,
} from 'src/app/models/AccPkg';
import { BrMd, IBrSml } from 'src/app/models/BrSml';
import { constants } from 'src/app/models/constants';
import { EAlterState } from 'src/app/models/CrudStppr';
import { AccPkgApiService } from './acc-pkg-api.service';
import {
  invalidBaseFldsValidator,
  invalidApproverFldsValidator,
  invalidMappedBrFldValidator,
  invalidDefaultAllowedDaysValidator,
} from './acc-pkg.validators';
import {
  AOIAccessPackages,
  AreaOfInterests,
} from 'src/app/models/AreaOfInterestAccessPackageMapping';

@Injectable({
  providedIn: 'root',
})
export class AccPkgService {
  fg: FormGroup;
  isNew = true;
  origAccPkg: AccPkg;
  mappedBrDims: AccPkgMappedBrDim[] = [];
  mappedDataSels: AccPkgMappedDataSel[] = [];
  combinedAreaOfInterests: AOIAccessPackages[] = [];
  doneUpdating = false;
  displayDataSel = false;

  private brSmls: { [key: string]: BrMd };
  private origMappedBrs: IBrSml[];
  private origMappedAoi: AOIAccessPackages;
  private origMappedBrDims: IAccPkgMappedBrDim[];
  public origMappedDataSels: IAccPkgMappedDataSel[];
  private subs: Subscription[] = [];

  constructor(
    fb: FormBuilder,
    private apiService: AccPkgApiService,
  ) {
    this.fg = fb.group(
      {
        key: '',
        name: [
          '',
          [
            Validators.pattern(constants.regExNoSpecialChars),
            Validators.required,
            Validators.maxLength(100),
          ],
        ],
        desc: [
          '',
          [
            Validators.pattern(constants.regExNoSpecialChars),
            Validators.maxLength(500),
          ],
        ],
        userTypes: [[], Validators.required],
        startDate: '',
        endDate: '',
        certifiable: false,
        active: true,
        visible: false,
        roleOwner: [
          '',
          [
            Validators.required,
            Validators.pattern(constants.regExNoSpecialChars),
            Validators.maxLength(1000),
          ],
        ],
        roleActivityFlag: [
          '',
          [
            Validators.required,
            Validators.pattern(constants.regExNoSpecialChars),
            Validators.maxLength(1000),
          ],
        ],
        roleProvisionApplication: [
          '',
          [
            Validators.required,
            Validators.pattern(constants.regExNoSpecialChars),
            Validators.maxLength(1000),
          ],
        ],
        roleDependency: [
          '',
          [
            Validators.required,
            Validators.pattern(constants.regExNoSpecialChars),
            Validators.maxLength(1000),
          ],
        ],
        functionalArea: [
          '',
          [
            Validators.required,
            Validators.pattern(constants.regExNoSpecialChars),
            Validators.maxLength(1000),
          ],
        ],
        approvalLevel: [
          constants.approvalLevel,
          [Validators.required, Validators.min(1), Validators.max(2)],
        ],
        approvalType: [constants.approvalType, Validators.required],
        primaryWGApprover: [
          '',
          [
            Validators.required,
            Validators.pattern(constants.regExNoSpecialChars),
            Validators.maxLength(500),
          ],
        ],
        secondaryWGApprover: [
          { value: '', disabled: true },
          [
            Validators.required,
            Validators.pattern(constants.regExNoSpecialChars),
            Validators.maxLength(500),
          ],
        ],
        maxAllowedDays: [
          constants.maxAllowedDays,
          [
            Validators.required,
            Validators.min(5),
            Validators.max(constants.maxAllowedDays),
          ],
        ],
        certActionPeriod: ['', [Validators.required, Validators.min(0)]],
        defaultAllowedDays: [
          constants.defaultAllowedDays,
          [Validators.required, Validators.min(5)],
        ],
        mappedBrs: [[], Validators.required],
        autoExtensionFlag: false,
        autoExtensionCondition: ['', Validators.required],
        maxAutoExtensionAllowed: ['', Validators.required],
        autoApprovalFlag: false,
        autoApprovalCondition: [
          '',
          [
            Validators.required,
            Validators.maxLength(100),
            Validators.pattern(constants.regExAutoApproval),
          ],
        ],
        maxAutoApprovalAllowed: [''],
        combinedAreaOfInterests: [[]],
        jobId: [
          '',
          [
            Validators.pattern(constants.regExNoSpecialChars),
            Validators.maxLength(100),
          ],
        ],
      },
      {
        validators: [
          invalidBaseFldsValidator,
          invalidApproverFldsValidator,
          invalidMappedBrFldValidator,
          invalidDefaultAllowedDaysValidator,
        ],
      },
    );
  }

  onInit(accPkg?: AccPkg): void {
    this.brSmls = undefined;
    this.origMappedBrs = undefined;
    this.origMappedBrDims = undefined;
    this.origMappedDataSels = undefined;
    this.origMappedAoi = undefined;
    this.mappedBrDims = [];
    this.mappedDataSels = [];
    this.origAccPkg = accPkg;
    this.doneUpdating = false;
    if (accPkg) {
      this.isNew = false;
      this.fg.reset(accPkg);
      if (accPkg.approvalLevel === 2) {
        this.acSecondaryWGApprover.enable();
      } else {
        this.acSecondaryWGApprover.disable();
      }
      this.apiService.getAccPkgWMappedBrs(accPkg.key).subscribe((d) => {
        this.origMappedBrs = d;
        this.setupDone();
      });
      this.apiService.getAccPkgWMappedBrDims(accPkg.key).subscribe((d) => {
        this.origMappedBrDims = d;
        this.setupDone();
      });
      this.apiService.getAccPkgWMappedDataSels(accPkg.key).subscribe((d) => {
        this.origMappedDataSels = d;
        this.setupDone();
      });

      this.apiService.getAccPkgWMappedAoi(accPkg.key).subscribe((d) => {
        this.origMappedAoi = d;
        this.setupDone();
      });
    } else {
      this.isNew = true;
      this.origMappedBrDims = [];
      this.origMappedDataSels = [];
      this.fg.reset(new AccPkg(null));
      this.acSecondaryWGApprover.disable();
    }
    this.apiService.getBrs().subscribe((d) => {
      this.brSmls = {};
      d.forEach((br) => {
        if (br.active && br.visible) {
          const b = new BrMd(br);
          this.brSmls[b.key] = b;
        }
      });
      this.setupDone();
    });
    this.subs.push(
      this.acApprovalLevel.valueChanges.subscribe((v) => {
        this.acSecondaryWGApprover.setValue('');
        if (v === 2) {
          this.acSecondaryWGApprover.enable();
        } else {
          this.acSecondaryWGApprover.disable();
        }
      }),
    );
    this.subs.push(
      this.acAutoExtensionFlag.valueChanges.subscribe(
        this.updateAutoExtension.bind(this),
      ),
    );
    this.subs.push(
      this.acAutoApprovalFlag.valueChanges.subscribe(
        this.updateAutoApproval.bind(this),
      ),
    );
    this.subs.push(
      this.acApprovalType.valueChanges.subscribe(this.updateDataSel.bind(this)),
    );
    // This is to ensure the Auto extension
    // is updated based on specific flags.
    this.updateAutoExtension();
    // This is to ensure the Auto approval
    // is updated based on specific flags.
    this.updateAutoApproval(this.acAutoApprovalFlag.value);
    // This is to ensure the Data Selection stepper
    // is updated based on specific flags.
    this.updateDataSel();
  }
  updateAutoExtension(): void {
    if (this.acAutoExtensionFlag.value) {
      this.acAutoExtensionCondition.addValidators(Validators.required);
      this.acMaxAutoExtensionAllowed.addValidators(Validators.required);
    } else {
      this.acAutoExtensionCondition.removeValidators(Validators.required);
      this.acMaxAutoExtensionAllowed.removeValidators([Validators.required]);
    }
    this.acAutoExtensionCondition.updateValueAndValidity();
    this.acMaxAutoExtensionAllowed.updateValueAndValidity();
  }
  updateAutoApproval(isOn: boolean): void {
    if (isOn) {
      this.acAutoApprovalCondition.addValidators(Validators.required);
      this.acMaxAutoApprovalAllowed.addValidators(Validators.required);
    } else {
      this.acAutoApprovalCondition.removeValidators(Validators.required);
      this.acMaxAutoApprovalAllowed.removeValidators([Validators.required]);
    }
    this.acAutoApprovalCondition.updateValueAndValidity();
    this.acMaxAutoApprovalAllowed.updateValueAndValidity();
  }
  updateDataSel(): void {
    this.displayDataSel = this.acApprovalType.value === 'AP+DS';
  }
  onDestroy(): void {
    this.brSmls = undefined;
    this.origMappedBrs = undefined;
    this.origMappedBrDims = undefined;
    this.origMappedDataSels = undefined;
    this.origMappedAoi = undefined;
    this.mappedBrDims = [];
    this.mappedDataSels = [];
    this.subs.forEach((s) => s.unsubscribe());
  }

  getCurrAccPkg(): AccPkg {
    return fgToAccPkg(this.fg.value);
  }

  canExitAccPkg() {
    return this.doneUpdating;
  }

  getMappedBrDimValPayload(): IAccPkgValMbrDim[] {
    const arr: IAccPkgValMbrDim[] = [];
    this.mappedBrDims.forEach((d) => {
      if (d.updatedState) {
        arr.push({
          AccessPackageKey: this.origAccPkg.key,
          AccessPackageDimensionKey: d.updatedState.payload.apDm.key,
          BusinessRoleKey: d.updatedState.payload.br.key,
          BusinessRoleDimensionKey: d.updatedState.payload.brDm.key,
          IsActive: d.isDisabled ? false : d.updatedState.payload.active,
          requiredSelection: d.updatedState.payload.requiredSelection || false,
        });
      } else if (d.isDisabled || d.action !== EAlterState.nothing) {
        arr.push({
          AccessPackageKey: this.origAccPkg.key,
          AccessPackageDimensionKey: d.apDm.key,
          BusinessRoleKey: d.brKey,
          BusinessRoleDimensionKey: d.brDm.key,
          IsActive: d.isDisabled ? false : d.active,
          requiredSelection: d.requiredSelection || false,
        });
      }
    });
    return arr;
  }

  getMappedDataSelValPayload(d: AccPkgMappedDataSel): IAccPkgValMdataSel {
    if (d.updatedState) {
      return {
        DataSelectionKey: d.apDs.key,
        AccessPackageKey: this.origAccPkg.key,
        PrimaryApproverWorkgroup:
          d.updatedState.payload.primaryApproverWorkgroup,
        IsActive: d.updatedState.payload.recommended,
      };
    } else {
      return {
        DataSelectionKey: d.apDs.key,
        AccessPackageKey: this.origAccPkg.key,
        PrimaryApproverWorkgroup: d.primaryApproverWorkgroup,
        IsActive: d.recommended,
      };
    }
  }

  hasAccPkgChanged(): boolean {
    if (!this.origAccPkg) return true;
    const currP = this.getCurrAccPkg().payload;
    const origP = this.origAccPkg.payload;
    const keys = Object.keys(currP);
    const origKeys = Object.keys(origP);

    // Check if the keys are the same
    if (
      keys.length !== origKeys.length ||
      !keys.every((key) => origKeys.includes(key))
    ) {
      return true; // Keys are different
    }
    for (let i = 0, l = keys.length; i < l; ++i) {
      const key = keys[i];
      if (currP[key] !== origP[key]) {
        return true;
      }
    }
    return false;
  }

  hasBRsChanged(): boolean {
    const brs: BrMd[] = this.acMappedBrs.value;
    for (let i = 0, l = brs.length; i < l; ++i) {
      if (brs[i].original && !brs[i].selected) {
        return true;
      }
      if (!brs[i].original && brs[i].selected) {
        return true;
      }
    }
    return false;
  }

  hasDimsChanged(): boolean {
    for (let i = 0, l = this.mappedBrDims.length; i < l; ++i) {
      const d = this.mappedBrDims[i];
      if (d.isDisabled && d.action === EAlterState.new) {
        continue;
      }
      if (d.isDisabled || d.action !== EAlterState.nothing) {
        return true;
      }
    }
    return false;
  }

  hasDataSelsChanged(): boolean {
    for (let i = 0, l = this.mappedDataSels.length; i < l; ++i) {
      const d = this.mappedDataSels[i];
      if (d.action !== EAlterState.nothing) {
        return true;
      }
    }
    return false;
  }

  hasAoiChanged(): boolean {
    const combinedAoi: AreaOfInterests[] = this.acCombinedAOI.value;
    for (let i = 0; i < combinedAoi.length; ++i) {
      if (combinedAoi[i].originalSelected && !combinedAoi[i].selected) {
        return true;
      }
      if (!combinedAoi[i].originalSelected && combinedAoi[i].selected) {
        return true;
      }
    }
    return false;
  }

  isDimUnique(curr: AccPkgMappedBrDim): boolean {
    for (let i = 0, l = this.mappedBrDims.length; i < l; ++i) {
      const d = this.mappedBrDims[i];
      const compare = d.updatedState || d;
      if (d.isDisabled || d.id === curr.id) {
        continue;
      }
      if (
        compare.payload.apDm.key === curr.apDm.key &&
        compare.payload.apDtKey === curr.apDtKey &&
        compare.payload.brDm.key === curr.brDm.key &&
        compare.payload.brDtKey === curr.brDtKey &&
        compare.payload.br.key === curr.br.key &&
        compare.payload.active === curr.active &&
        compare.payload.requiredSelection === curr.requiredSelection
      ) {
        return false;
      }
    }
    return true;
  }

  isDataSelUnique(curr: AccPkgMappedDataSel): boolean {
    for (let i = 0, l = this.mappedDataSels.length; i < l; ++i) {
      const d = this.mappedDataSels[i];
      const compare = d.updatedState || d;
      if (d.id === curr.id) {
        continue;
      }
      if (
        compare.payload.apDs.key === curr.apDs.key &&
        compare.payload.primaryApproverWorkgroup ===
          curr.primaryApproverWorkgroup &&
        compare.payload.recommended === curr.recommended
      ) {
        return false;
      }
    }
    return true;
  }

  updateDimDisabledState(): void {
    this.mappedBrDims.forEach((d) => {
      if (this.brSmls[d.brKey].selected) {
        d.isDisabled = false;
        if (d.action === EAlterState.nothing) {
          d.active = true;
        }
      } else {
        d.isDisabled = true;
        d.active = false;
        d.updatedState = undefined;
        d.action = EAlterState.nothing;
      }
    });
  }

  private setupDone(): void {
    if (this.isNew) {
      this.acMappedBrs.setValue(Object.values(this.brSmls), {
        emitEvent: false,
      });
    } else if (
      this.brSmls &&
      this.origMappedBrDims &&
      this.origMappedBrs &&
      this.origMappedDataSels &&
      this.origMappedAoi
    ) {
      const selectedBrs: { [key: string]: BrMd } = {};
      this.origMappedBrs.forEach((mbr) => {
        if (this.brSmls[mbr.key]) {
          this.brSmls[mbr.key].selected = true;
          this.brSmls[mbr.key].original = true;
          selectedBrs[mbr.key] = this.brSmls[mbr.key];
        }
      });
      this.mappedBrDims = [];
      this.origMappedBrDims.forEach((d, i) => {
        const newMbrd = new AccPkgMappedBrDim(d, i);
        if (selectedBrs[d.brKey]) {
          newMbrd.br = selectedBrs[d.brKey];
        } else {
          newMbrd.br = this.brSmls[d.brKey];
          newMbrd.isDisabled = true;
          newMbrd.active = false;
        }
        this.mappedBrDims.push(newMbrd);
      });
      this.mappedDataSels = [];
      this.origMappedDataSels.forEach((d, i) => {
        const newMDataSel = new AccPkgMappedDataSel(d, i);
        this.mappedDataSels.push(newMDataSel);
      });
      this.acMappedBrs.setValue(Object.values(this.brSmls), {
        emitEvent: false,
      });

      this.acCombinedAOI.setValue(this.origMappedAoi.combinedAreaOfInterests);
    }
  }

  updateStartDateValidity(): void {
    this.acStartDate.updateValueAndValidity();
  }

  updateEndDateValidity(): void {
    this.acEndDate.updateValueAndValidity();
  }

  /* --------------------------------------- getters --------------------------------------- */
  /** AbstractControl value is a `string` */
  get acName(): AbstractControl {
    return this.fg.get('name');
  }
  /** AbstractControl value is a `string` */
  get acDesc(): AbstractControl {
    return this.fg.get('desc');
  }
  /** AbstractControl value is a `string` */
  get acUserTypes(): AbstractControl {
    return this.fg.get('userTypes');
  }
  /** AbstractControl value is a `Date` */
  get acStartDate(): AbstractControl {
    return this.fg.get('startDate');
  }
  /** AbstractControl value is a `Date` */
  get acEndDate(): AbstractControl {
    return this.fg.get('endDate');
  }
  /** AbstractControl value is a string */
  get acRoleOwner(): AbstractControl {
    return this.fg.get('roleOwner');
  }
  /** AbstractControl value is a string */
  get acRoleActFlag(): AbstractControl {
    return this.fg.get('roleActivityFlag');
  }
  /** AbstractControl value is a string */
  get acRoleProvApp(): AbstractControl {
    return this.fg.get('roleProvisionApplication');
  }
  /** AbstractControl value is a string */
  get acRoleDep(): AbstractControl {
    return this.fg.get('roleDependency');
  }
  /** AbstractControl value is a string */
  get acFuncArea(): AbstractControl {
    return this.fg.get('functionalArea');
  }
  /** AbstractControl value is array of string */
  get acApprovalLevel(): AbstractControl {
    return this.fg.get('approvalLevel');
  }
  /** AbstractControl value is array of `Application` */
  get acApprovalType(): AbstractControl {
    return this.fg.get('approvalType');
  }
  /** AbstractControl value is a boolean */
  get acPrimaryWGApprover(): AbstractControl {
    return this.fg.get('primaryWGApprover');
  }
  /** AbstractControl value is a string */
  get acSecondaryWGApprover(): AbstractControl {
    return this.fg.get('secondaryWGApprover');
  }
  /** AbstractControl value is a number string */
  get acMaxAllowedDays(): AbstractControl {
    return this.fg.get('maxAllowedDays');
  }
  /** AbstractControl value is a number string */
  get acDefaultAllowedDays(): AbstractControl {
    return this.fg.get('defaultAllowedDays');
  }
  /** AbstractControl value is a number string */
  get acCertActionPeriod(): AbstractControl {
    return this.fg.get('certActionPeriod');
  }
  /** AbstractControl value is a `MappedBr[]` */
  get acMappedBrs(): AbstractControl {
    return this.fg.get('mappedBrs');
  }
  /** AbstractControl value is a string */
  get acAutoExtensionCondition(): AbstractControl {
    return this.fg.get('autoExtensionCondition');
  }
  /** AbstractControl value is a string */
  get acMaxAutoExtensionAllowed(): AbstractControl {
    return this.fg.get('maxAutoExtensionAllowed');
  }
  /** AbstractControl value is a string */
  get acAutoApprovalCondition(): AbstractControl {
    return this.fg.get('autoApprovalCondition');
  }
  /** AbstractControl value is a string */
  get acMaxAutoApprovalAllowed(): AbstractControl {
    return this.fg.get('maxAutoApprovalAllowed');
  }
  // --------------------------------------- flags
  /** AbstractControl value is `boolean` */
  get acCertifiable(): AbstractControl {
    return this.fg.get('certifiable');
  }
  /** AbstractControl value is `boolean` AKA enabled */
  get acActive(): AbstractControl {
    return this.fg.get('active');
  }
  /** AbstractControl value is a `boolean` AKA Requestable */
  get acVisible(): AbstractControl {
    return this.fg.get('visible');
  }
  /** AbstractControl value is a boolean AKA Auto extension */
  get acAutoExtensionFlag(): AbstractControl {
    return this.fg.get('autoExtensionFlag');
  }
  /** AbstractControl value is a boolean  AKA Auto Approval*/
  get acAutoApprovalFlag(): AbstractControl {
    return this.fg.get('autoApprovalFlag');
  }
  // ---------------------------------------
  get key(): string {
    return this.fg.get('key')?.value || '';
  }
  get selectedMappedBrs(): BrMd[] {
    const mappedBrs: BrMd[] = this.acMappedBrs.value;
    if (Array.isArray(mappedBrs)) {
      return mappedBrs.filter((d) => d.selected);
    }
    return [];
  }

  get acCombinedAOI(): AbstractControl {
    return this.fg.get('combinedAreaOfInterests');
  }

  /** AbstractControl value is a `string` */
  get acJobId(): AbstractControl {
    return this.fg.get('jobId');
  }
}
