import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'MDYFormat',
})
export class MonthDateYearPipe implements PipeTransform {
  transform(value: string): string {
    const date = new Date(value.endsWith('Z') ? value : `${value}Z`);
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth() + 1; // Months are 0-based
    const day = date.getUTCDate();

    // Format the date as "MMMM d, y"
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    return `${monthNames[month - 1]} ${day}, ${year}`;
  }
}
