<app-app-banner></app-app-banner>
<div>
    <h4>This self-service portal allows you to validate MSM files in an environment to lower turnaround time due to
        issues with it.</h4>
</div>
<div class="upload">
    <form [formGroup]="fg" class="stepper" (ngSubmit)="onSubmit()">
        <div class="col-lft">
            <!-- MSM environment -->
            <app-bsc-cstm-fld [abstrctCntrl]="acEnvironment" inptId="ms-env" [customLabelName]="'Environment'" label="Select the MSM environment" frmFldClss="ivb-env" tooltip="Select an environment">
                <mat-select id="ms-env" placeholder="-- Select an environment --" formControlName="environment">
                    <mat-option value="" disabled>-- Select an environment --</mat-option>
                    <mat-option *ngFor="let option of MSMEnvironment" [value]="option.environment">
                        {{option.displayName}}
                    </mat-option>
                </mat-select>
            </app-bsc-cstm-fld>

            <!-- emails -->
            <div class="form-field receipients-field">
                <div class="label-wrapper">
                    <label> Specify recipients of the result<sup>&#9733;</sup>
                        <app-info-tooltip hoverText="Enter a single email address or multiple, seperated by a comma/semi-colon, then press enter. Max ten recipients">
                        </app-info-tooltip>
                    </label>
                </div>
                <div class="input-wrapper">
                    <mat-form-field appearance="fill" class="fill">
                        <mat-chip-grid #chipGrid formControlName="emails" aria-label="Email selection">
                            <mat-chip-row color="accent" *ngFor="let email of emails" (removed)="chipRemove(email)" [editable]="true" (edited)="chipEdit(email, $event)" [aria-description]="'press enter to edit ' + email">
                                {{email}}
                                <button matChipRemove [attr.aria-label]="'remove ' + email">
                                    <mat-icon>cancel</mat-icon>
                                </button>
                            </mat-chip-row>
                            <input placeholder="Enter an email address" [matChipInputFor]="chipGrid" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="false" (matChipInputTokenEnd)="chipAdd($event)" (focus)="onInputFocus()" />
                        </mat-chip-grid>
                    </mat-form-field>
                </div>
                <div class="form-err-field rec-fld" *ngIf="acEmails.invalid">
                    <div class="err" *ngIf="acEmails.errors?.invalid; else nameElseBlock">
                        <p *ngFor="let item of acEmails.errors?.invalid">{{ item }}</p>
                    </div>
                    <ng-template #nameElseBlock>
                        <div class="err" *ngIf="acEmails.errors?.required && hasInput">At least one recipient is required</div>
                    </ng-template>
                </div>
            </div>
            <!-- end email -->
            <!--  procedure  -->
            <div class="form-field radio-fld">
                <div class="label-wrapper">
                    <label>Select procedure <sup>&#9733;</sup>
                        <app-info-tooltip hoverText="Select procedure">
                        </app-info-tooltip>
                    </label>
                </div>
                <div class="input-wrapper">
                    <mat-radio-group formControlName="procedure">
                        <mat-radio-button color="primary" value="1">CSV Manual upload</mat-radio-button>
                        <mat-radio-button color="primary" value="2">CSV from Work item</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <!-- end procedure -->
            <!-- file upload -->
            <div class="form-field file-fld" *ngIf="acProcedure.value === '1'">
                <div class="label-wrapper">
                    <label>Select files to upload<sup>&#9733;</sup>
                        <app-info-tooltip hoverText="File type must be a CSV">
                        </app-info-tooltip>
                    </label>
                </div>
                <div class="input-wrapper">
                    <div class="file-upload">
                        <input type="file" accept=".csv" multiple (change)="onFilesSelected($event)" #files />
                        <div class="file-txt" [ngClass]="{ 'ng-invalid': fileToUpload.invalid && (fileToUpload.dirty || fileToUpload.touched)}" (click)="files.click()">
                            <span *ngIf="!fg.get('fileToUpload').value || fg.get('fileToUpload').value.length === 0">No file selected yet.</span>
                            <span *ngFor="let file of fileToUpload.value">{{ file.name }}</span>
                        </div>

                    </div>
                </div>

                <div class="form-err-field file-fld" *ngIf="(fileToUpload.invalid || fileToUpload.errors?.invalidFileProps || fileToUpload.errors?.invalidFileNameChars || fileToUpload.errors?.noFilesSelected) && (fileToUpload.dirty || fileToUpload.touched)">
                    <div class="err">
                        <ng-container *ngIf="fileToUpload.errors">
                            <div *ngIf="fileToUpload.errors.noFilesSelected">{{ fileToUpload.errors.noFilesSelected }}</div>
                            <div *ngIf="fileToUpload.errors.invalidFileProps">{{ fileToUpload.errors.invalidFileProps }}</div>
                            <div *ngIf="fileToUpload.errors.invalidFileNameChars">
                                {{ fileToUpload.errors.invalidFileNameChars }}
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <!-- end filed upload  -->
            <!-- work item -->
            <div class="form-field work-item-fld" *ngIf="acProcedure.value === '2'">
                <app-bsc-cstm-fld [abstrctCntrl]="acWorkItem" [customLabelName]="'Work item ID'" inptId="mi-work-item"
                label="Specify work item ID" frmFldClss="work-item"
                tooltip="Please specify a valid work item.">
                    <input matInput id="mi-work-item" type="number" formControlName="workItem" min="1">
                </app-bsc-cstm-fld>
                <div class="btn-wrapper">
                    <button mat-button type="button" class="btn btn-one" [disabled]="acWorkItem?.invalid" (click)="getAttchments()">Get Attachments.</button>
                    <div class="attachments">
                        <p *ngIf="hasAttachments">Attachment(s) found: {{ attachmentCount }} items</p>
                        <ng-container *ngIf="attachmentCount > 0; else noAttachments">
                          <ul>
                            <li *ngFor="let filename of attachmentFilenames">{{ filename }}</li>
                          </ul>
                        </ng-container>
                        <ng-template #noAttachments>
                            <div class="form-err-field work-item-fld">{{ attachmentErrorMessage }}</div>
                        </ng-template>
                      </div>
                </div>
            </div>
            <!-- end work item -->
        </div>
        <!-- end col-lft-->

        <div class="col-rht">&nbsp;</div>

        <!-- submit and cancel-->
        <div class="col-wth">
            <div class="btn-wrapper lft-rht">
                <div class="lft">
                    <button mat-button type="submit" class="btn btn-two" [disabled]="fg.invalid || (acProcedure.value === '2' && attachmentCount === 0)">Submit</button>
                </div>
                <div class="rht">
                    <button mat-button type="button" class="btn btn-one" (click)="reloadIVBPage()">Cancel</button>
                </div>
            </div>
        </div>
        <!-- end submit and cancel -->
    </form>
</div>
