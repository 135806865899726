import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { AuthGuard, FaultAuthGuard } from './auth/auth.guard';
import { HomeComponent } from './home/home.component';
import { ReleaseComponent } from './header-nav/release/release.component';
import { IvbComponent } from './msm/ivb/ivb.component';
import { LoginFaultComponent } from './login-fault/login-fault.component';
import { msmRoutes } from './msm/msm-routes';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

const routes: Routes = [
  { path: 'about', component: AboutComponent, canActivate: [AuthGuard] },
  {
    path: 'unauthorized',
    component: LoginFaultComponent,
    canActivate: [FaultAuthGuard],
  },
  {
    path: 'loginError',
    component: LoginFaultComponent,
    canActivate: [FaultAuthGuard],
  },
  {
    path: 'loggedOut',
    component: LoginFaultComponent,
    canActivate: [FaultAuthGuard],
  },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'release', component: ReleaseComponent, canActivate: [AuthGuard] },
  {
    path: 'ivb',
    component: IvbComponent,
    canActivate: [AuthGuard],
  },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(msmRoutes.concat(routes))],
  exports: [RouterModule],
})
export class AppRoutingModule {}
