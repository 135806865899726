import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
} from '@angular/core';

@Directive({
  selector: '[appArrowClick]',
})
export class ArrowClickDirective {
  @Output() prevArrowClick = new EventEmitter<void>();
  @Output() nextArrowClick = new EventEmitter<void>();
  @Output() periodButtonClick = new EventEmitter<void>();

  constructor(private elementRef: ElementRef) {}

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    const periodButton = this.findClosestPeriodButton(target);
    if (periodButton) {
      event.preventDefault();
      event.stopPropagation();

      this.periodButtonClick.emit();
    } else if (
      target.classList.contains('mat-calendar-previous-button') ||
      target.parentElement?.classList.contains('mat-calendar-previous-button')
    ) {
      this.prevArrowClick.emit();
    } else if (
      target.classList.contains('mat-calendar-next-button') ||
      target.parentElement?.classList.contains('mat-calendar-next-button')
    ) {
      this.nextArrowClick.emit();
    }
  }

  private findClosestPeriodButton(element: HTMLElement): HTMLElement {
    while (element) {
      if (element.classList.contains('mat-calendar-period-button')) {
        return element;
      }
      element = element.parentElement;
    }
    return null;
  }
}
