import { EAlterState, CrudStppr, TReviewVals } from './CrudStppr';

export interface IDimension {
  active: boolean;
  key: string;
  riskScore: string;
  level?: string;
  mrdrKey?: string;
  value: string;
  dataSel?: string;
  typeKey?: string;
  visible: boolean;
}

export interface IDimensionPayload {
  dimensions: IDimension[];
}

export class Dimension extends CrudStppr<Dimension> {
  value = '';
  riskScore = '';
  dataSel = '';
  typeKey = '';
  level = '';
  mrdrKey = '';

  constructor(params?: IDimension, i?: number) {
    super(i);
    if (params) {
      this.key = params.key;
      this.active = params.active;
      this.value = params.value;
      this.visible = params.visible;
      const num = parseFloat(params.riskScore);
      if (isNaN(num)) {
        this.riskScore = '0.00';
      } else {
        this.riskScore = num.toFixed(2);
      }
      this.level = params.level || '';
      this.mrdrKey = params.mrdrKey || '';
      this.dataSel = params.dataSel || '';
      this.typeKey = params.typeKey || '';
    }
  }

  get displayName() {
    if (!this.key) {
      return `${this.value}`;
    }
    return `${this.key}: ${this.value}`;
  }

  get payload() {
    return {
      key: this.key,
      typeKey: this.typeKey,
      value: this.value,
      riskScore: this.riskScore,
      dataSel: this.dataSel,
      active: this.active,
      visible: this.visible,
      level: this.level,
      mrdrKey: this.mrdrKey,
    };
  }

  reset(): void {
    const uc = this.updatedClass as Dimension;
    if (uc) {
      this.key = uc.key;
      this.typeKey = uc.typeKey;
      this.value = uc.value;
      this.riskScore = uc.riskScore;
      this.dataSel = uc.dataSel;
      this.visible = uc.visible;
      this.active = uc.active;
      this.level = uc.level;
      this.mrdrKey = uc.mrdrKey;
      this.updatedClass = undefined;
    }
    this.action = EAlterState.nothing;
  }
}

export type TReviewValsDim = TReviewVals<Dimension>;
