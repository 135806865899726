import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap, map } from 'rxjs';
import { AsmPostResponse, IFGvalidation } from 'src/app/models/AsmPostResponse';
import { PopUpService } from 'src/app/singletons/popup/popup.service';
import { SpinnerService } from 'src/app/singletons/spinner/spinner.service';
import { ToasterService } from 'src/app/singletons/toaster/toaster.service';
import { RoleFilter, IRoleFilterPayload } from 'src/app/models/RoleFilter';
import {
  RoleFilterSelection,
  IRoleFilterSelectionPayload,
} from 'src/app/models/RoleFilterSelections';
import { API } from 'src/app/util/API';
import { CrudStppr } from 'src/app/models/CrudStppr';
import {
  AreaOfInterest,
  IAreaOfInterestPayload,
} from 'src/app/models/AreaOfInterest';
import { LaunchdarklyService } from 'src/app/singletons/launchdarkly.service';

@Injectable({
  providedIn: 'root',
})
export class MiscConfigApiService extends API {
  constructor(
    http: HttpClient,
    pService: PopUpService,
    spinner: SpinnerService,
    toaster: ToasterService,
    ldService: LaunchdarklyService
  ) {
    super(http, pService, spinner, toaster, ldService);
  }

  private readonly paths = {
    dataImpact: `${this.rootPath}dataImpact`,
    validations: `${this.rootPath}validations`,
    roleFilters: `${this.rootPath}rolefilters`,
    roleFilterSelections: `${this.rootPath}rolefilterselections`,
    areaOfInterests: `${this.rootPath}areaofinterests`,
  };

  // ************************************ role filter
  /** get all role filters */
  public getRoleFilters(): Observable<RoleFilter[]> {
    return this.getReqHelper<IRoleFilterPayload>(
      `${this.paths.roleFilters}`,
      `Getting role filter`,
    ).pipe(
      map((data) => {
        return data.roleFilters.map((d, i) => new RoleFilter(d, i));
      }),
    );
  }

  /** @description update the role filter */
  public updateRoleFilters<T>(
    arr: CrudStppr<T>[],
    action: string,
  ): Observable<AsmPostResponse> {
    return this.postReqHelper<AsmPostResponse>(
      `${this.paths.roleFilters}`,
      { action: action, data: arr },
      'Updating Role Filters',
    ).pipe(
      tap({
        complete: () => {
          this.toaster.show('Role Filters', `${action} was successful`);
        },
      }),
    );
  }

  public validateRoleFilter(
    rf: RoleFilter,
    isNew: boolean,
  ): Observable<IFGvalidation> {
    const payload = { RoleFilter: [rf.ValidationPayload] };
    const action = isNew ? 'create' : 'update';
    return this.postReqHelper<IFGvalidation>(
      `${this.paths.validations}?action=${action}`,
      payload,
      'Role Filter validation',
    );
  }

  // ************************************ role filter selections
  /** get all role filter selections */
  public getRolefilterselections(): Observable<RoleFilterSelection[]> {
    return this.getReqHelper<IRoleFilterSelectionPayload>(
      `${this.paths.roleFilterSelections}`,
      `Getting role filter selection`,
    ).pipe(
      map((data) => {
        return data.roleFilterSelections.map(
          (d, i) => new RoleFilterSelection(d, i),
        );
      }),
    );
  }

  /** @description update the role filter selection */
  public updateRoleFilterSelections<T>(
    arr: CrudStppr<T>[],
    action: string,
  ): Observable<AsmPostResponse> {
    return this.postReqHelper<AsmPostResponse>(
      `${this.paths.roleFilterSelections}`,
      { action: action, data: arr },
      'Updating Role Filter Selections',
    ).pipe(
      tap({
        complete: () => {
          this.toaster.show(
            'Role Filter Selections',
            `${action} was successful`,
          );
        },
      }),
    );
  }

  /** @description validate role filter */
  public validateRolefilterselection(
    rfs: RoleFilterSelection,
    isNew: boolean,
  ): Observable<IFGvalidation> {
    const payload = { RoleFilterSelection: [rfs.validationPayload] };
    const action = isNew ? 'create' : 'update';
    return this.postReqHelper<IFGvalidation>(
      `${this.paths.validations}?action=${action}`,
      payload,
      'RoleFilterSelection validation',
    );
  }

  // ************************************ areas of interest
  /** get all areas of interest */
  public getAreaOfInterest(): Observable<AreaOfInterest[]> {
    return this.getReqHelper<IAreaOfInterestPayload>(
      `${this.paths.areaOfInterests}`,
      `Getting areas of interest`,
    ).pipe(
      map((data) => {
        return data.areaOfInterests.map((d, i) => new AreaOfInterest(d, i));
      }),
    );
  }

  // ************************************ areas of interest
  public updateAreaOfInterest<T>(
    arr: CrudStppr<T>[],
    action: string,
  ): Observable<AsmPostResponse> {
    return this.postReqHelper<AsmPostResponse>(
      `${this.paths.areaOfInterests}`,
      { action: action, data: arr },
      'Updating areas of interest',
    ).pipe(
      tap({
        complete: () => {
          this.toaster.show('Area Of Interest', `${action} was successful`);
        },
      }),
    );
  }

  /** @description validate areas of interest */
  public validateAreaOfInterest(
    aoi: AreaOfInterest,
    isNew: boolean,
  ): Observable<IFGvalidation> {
    const payload = { AreaOfInterest: [aoi.ValidationPayload] };
    const action = isNew ? 'create' : 'update';
    return this.postReqHelper<IFGvalidation>(
      `${this.paths.validations}?action=${action}`,
      payload,
      'AreaOfInterest validation',
    );
  }
}
