import { BrMd, IBrMd } from './BrSml';
import { CrudStppr, EAlterState } from './CrudStppr';

export interface ISODPayload {
  segregationOfDuties: ISOD[];
}

export interface ISOD {
  key: string;
  owner: string;
  violationOwner: string;
  roleName?: string;
  conflictingRoleName?: string;
  brMd?: IBrMd;
  conflictingBrMd?: IBrMd;
  riskID: string;
  desc: string;
  active: boolean;
}

export interface ISODValidationPayload {
  SODPolicyKey: string;
  Owner: string;
  ViolationOwner: string;
  RoleName: string;
  ConflictingRoleName: string;
  RiskId?: string;
  Description?: string;
  IsActive: boolean;
}

export class SOD extends CrudStppr<SOD> {
  key = '';
  owner = 'WG_SOD_Owner';
  violationOwner = 'WG_Security_Compliance';
  brKey = '';
  conflictingBrKey = '';
  brMd: BrMd;
  conflictingBrMd: BrMd;
  riskID = '';
  desc = '';

  constructor(params?: ISOD, i?: number) {
    super(i);
    if (params) {
      this.key = params.key;
      this.owner = params.owner;
      this.violationOwner = params.violationOwner;
      this.riskID = params.riskID;
      this.desc = params.desc;
      this.brKey = params.roleName;
      this.conflictingBrKey = params.conflictingRoleName;
      this.brMd = params.brMd ? new BrMd(params.brMd) : undefined;
      this.conflictingBrMd = params.conflictingBrMd
        ? new BrMd(params.conflictingBrMd)
        : undefined;
      this.active = params.active;
    }
  }

  get brDisplay(): string {
    if (this.brMd) {
      return this.brMd.display;
    }
    return '';
  }

  get crDisplay(): string {
    if (this.conflictingBrMd) {
      return this.conflictingBrMd.display;
    }
    return '';
  }

  get payload() {
    return {
      key: this.key,
      owner: this.owner,
      violationOwner: this.violationOwner,
      roleName: this.brMd?.key || null,
      brName: this.brMd?.name || null,
      conflictingRoleName: this.conflictingBrMd?.key || null,
      conflictingBrName: this.conflictingBrMd?.name || null,
      riskID: this.riskID,
      desc: this.desc,
      active: this.active,
    };
  }

  get validationPayload(): ISODValidationPayload {
    const obj: ISODValidationPayload = {
      SODPolicyKey: this.key,
      Owner: this.owner,
      ViolationOwner: this.violationOwner,
      RoleName: this.brMd?.key,
      ConflictingRoleName: this.conflictingBrMd?.key,
      IsActive: this.active,
    };
    if (this.desc) {
      obj.Description = this.desc;
    }
    if (this.riskID) {
      obj.RiskId = this.riskID;
    }
    return obj;
  }

  reset(): void {
    const uc = this.updatedClass as SOD;
    if (uc) {
      this.key = uc.key;
      this.owner = uc.owner;
      this.violationOwner = uc.violationOwner;
      this.brMd = uc.brMd;
      this.conflictingBrMd = uc.conflictingBrMd;
      this.brKey = uc.brKey;
      this.riskID = uc.riskID;
      this.desc = uc.desc;
      this.active = uc.active;
    }
    this.action = EAlterState.nothing;
  }
}
