export const constants = {
  approvalLevel: 1,
  approvalType: 'BR',
  maxAllowedDays: 1825,
  minAllowedDays: 5,
  defaultAllowedDays: 720,
  minSelfCertPeriod: 1,
  maxSelfCertPeriod: 2147483647,
  maxLenHumanDataApprovalFilter: 1000,
  maxLenDefaultApproverIds: 1000,
  regExNoSpecialChars: /^[\w\s!@#$%^&*()\-+=,./<>?:;'"]*$/,
  regExAutoApproval: /^GIA_AutoApprovalPopulation\d+_[a-zA-Z]+(?:_[a-zA-Z]+)*$/,
  regExProfile:
    /^(order-[0-9]+),\s*(profileId-[A-Za-z0-9]+),\s*(restrictedField-(No|Yes))$/,
  roleOwner: 'spadmin',
  tbd: 'TBD',
};

export const dataPayloadMaxSize = 25;
