export interface IMappedBr {
  complexity: string;
  description: string;
  key: string;
  name: string;
  active: boolean;
  visible: boolean;
}

export interface IAppWithMappedBrs {
  airID: number;
  igaAppID: number;
  mappedBusinessRoles: IMappedBr[];
  name: string;
}

export class AppWithMappedBrs {
  airID: number;
  igaAppID: number;
  mappedBusinessRoles: IMappedBr[] = [];
  name = '';

  constructor(params?: IAppWithMappedBrs) {
    if (params) {
      this.airID = params.airID;
      this.igaAppID = params.igaAppID;
      this.name = params.name;
      if (Array.isArray(params.mappedBusinessRoles)) {
        this.mappedBusinessRoles = params.mappedBusinessRoles.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
      }
    }
  }
}
