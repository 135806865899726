import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { CrudStppr } from 'src/app/models/CrudStppr';
import { CRUDStpprMngr } from '../CRUDStpprMngr';
import { CrudTblDataSource } from './crud-tbl-datasource';

@Component({
  selector: 'app-crud-tbl',
  templateUrl: './crud-tbl.component.html',
  styleUrls: ['./crud-tbl.component.scss'],
})
export class CrudTblComponent<T> implements OnInit, AfterViewInit {
  @Input() mngr: CRUDStpprMngr<T>;
  @Input() showActions: boolean;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<CrudStppr<T>>;
  dataSource: CrudTblDataSource<T>;

  dispColHdrs: string[] = [];
  dispColKeys: string[] = [];
  displayedColumns: string[] = [];
  actionHeader: string;

  ngOnInit(): void {
    this.dataSource = new CrudTblDataSource(this.mngr, this.showActions);
    this.actionHeader = this.mngr.showVisibleFld ? 'Active/Visible' : 'Active';
    if (this.showActions) {
      if (this.mngr.showVisibleFld) {
        this.displayedColumns = this.mngr.tabDispColKeys.concat([
          'active',
          'visible',
          'action',
        ]);
      } else {
        this.displayedColumns = this.mngr.tabDispColKeys.concat([
          'active',
          'action',
        ]);
      }
      this.dispColHdrs = this.mngr.tabDispColHdrs;
      this.dispColKeys = this.mngr.tabDispColKeys;
    } else {
      if (this.mngr.showVisibleFld) {
        this.displayedColumns = this.mngr.revDispColKeys.concat([
          'active',
          'visible',
        ]);
      } else {
        this.displayedColumns = this.mngr.revDispColKeys.concat(['active']);
      }
      this.dispColHdrs = this.mngr.revDispColHdrs;
      this.dispColKeys = this.mngr.revDispColKeys;
    }
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }
}
