import { CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';

import { AppBannerComponent } from './app-banner/app-banner.component';
import { BscCstmFldComponent } from './bsc-cstm-fld/bsc-cstm-fld.component';
import { BscInptFldComponent } from './bsc-cstm-fld/bsc-inpt-fld/bsc-inpt-fld.component';
import { CrudStepperComponent } from './crud-stepper/crud-stepper.component';
import { CrudTblComponent } from './crud-stepper/crud-tbl/crud-tbl.component';
import { DataImpactComponent } from './crud-stepper/data-impact/data-impact.component';
import { ImpactTblComponent } from './crud-stepper/data-impact/impact-tbl/impact-tbl.component';
import { CrudFormPopupComponent } from './crud-stepper/form-popup/form-popup.component';
import { CstepperComponent } from './cstepper/cstepper.component';
import { DuelListboxComponent } from './duel-listbox/duel-listbox.component';
import { InfoTooltipComponent } from './info-tooltip/info-tooltip.component';
import { KeypressComponent } from './keypress/keypress.component';
import { YesNoPipe } from './pipes/yes-no.pipe';
import { AppRfMappingReviewTableComponent } from '../msm/app-br-edm/shared/app-flds/apprf-mapping-review-table/apprf-mapping-review-table.component';
import { MappingReviewTableComponent } from '../msm/app-br-edm/shared/mapping-review-table/mapping-review-table.component';
import { MonthDateYearPipe } from './pipes/month-date-year.pipe';

@NgModule({
  declarations: [
    AppBannerComponent,
    InfoTooltipComponent,
    KeypressComponent,
    YesNoPipe,
    DuelListboxComponent,
    CstepperComponent,
    BscInptFldComponent,
    BscCstmFldComponent,
    CrudStepperComponent,
    CrudTblComponent,
    CrudFormPopupComponent,
    ImpactTblComponent,
    DataImpactComponent,
    AppRfMappingReviewTableComponent,
    MappingReviewTableComponent,
    MonthDateYearPipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatIconModule,
    MatInputModule,
    MatStepperModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatDialogModule,
    MatCheckboxModule,
    MatMenuModule,
  ],
  exports: [
    AppBannerComponent,
    InfoTooltipComponent,
    KeypressComponent,
    YesNoPipe,
    MonthDateYearPipe,
    DuelListboxComponent,
    CstepperComponent,
    BscInptFldComponent,
    BscCstmFldComponent,
    CdkStepperModule,
    CrudStepperComponent,
    ImpactTblComponent,
    DataImpactComponent,
    AppRfMappingReviewTableComponent,
    MappingReviewTableComponent,
    AppRfMappingReviewTableComponent,
  ],
})
export class SharedModule {}
