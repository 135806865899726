import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { IAccPkgSml, IAccPkgSmlPayload } from '../models/AccPkg';
import { API } from '../util/API';
import { AppWithMappedBrs, IAppWithMappedBrs } from '../models/AppWithMapping';
import { PopUpService } from '../singletons/popup/popup.service';
import { SpinnerService } from '../singletons/spinner/spinner.service';
import { ToasterService } from '../singletons/toaster/toaster.service';
import { AppOnboardingStatus } from '../models/AppOnboardingStatus';
import { LaunchdarklyService } from '../singletons/launchdarkly.service';

@Injectable({
  providedIn: 'root',
})
export class MsmApiService extends API {
  constructor(
    http: HttpClient,
    pService: PopUpService,
    spinner: SpinnerService,
    toaster: ToasterService,
    ldService: LaunchdarklyService
  ) {
    super(http, pService, spinner, toaster, ldService);
  }

  private readonly paths = {
    accPkgs: `${this.rootPath}accesspackages`,
    apps: `${this.rootPath}applications`,
  };

  /** @description get all access packages */
  public getAccPkgs(): Observable<IAccPkgSml[]> {
    return this.getReqHelper<IAccPkgSmlPayload>(
      this.paths.accPkgs,
      `Getting business roles`,
    ).pipe(map((data) => data.accessPackages));
  }

  /** @description get application with mapped BRs */
  public getAppWithMappedBrs(igaAppID: number): Observable<AppWithMappedBrs> {
    return this.getReqHelper<IAppWithMappedBrs>(
      `${this.paths.apps}/${igaAppID}/businessroles`,
      `Getting business roles`,
    ).pipe(map((data) => new AppWithMappedBrs(data)));
  }

  /** @description get application onboarding status via `igaAppID`  */
  public getOnboardingStatus(
    igaAppID: number,
  ): Observable<AppOnboardingStatus> {
    return this.getReqHelper<AppOnboardingStatus>(
      `${this.paths.apps}/${igaAppID}/onboardingstatus`,
      `Getting application onboarding status: ${igaAppID}`,
    ).pipe(map((data) => new AppOnboardingStatus(data)));
  }
}
