import { EActionType } from 'src/app/models/Actions';

export interface IAppRFPayload {
  igaAppID?: number;
  applicationName?: string;
  active: boolean;
  mappedRoleFilters?: IAppRoleFilter[];
  availableRoleFilters?: IAppRoleFilter[];
}

export interface IAppRoleFilter {
  igaAppID: number;
  applicationName?: string;
  key?: string;
  displayName?: string;
  active: boolean;
  selected?: boolean;
}

export interface ICombinedRoleFilters {
  key?: string;
  selected?: boolean;
  actionType?: string;
  originalSelected?: boolean;
}

interface IPostRFKeyPayload {
  key: string[];
}

export interface IAppRFPostPayload {
  igaAppID: number;
  rf?: IPostRFKeyPayload;
  combinedRoleFilters?: IAppRoleFilter[];
  selected?: boolean;
  changed?: boolean;
  key?: string;
  active: boolean;
}

export class RoleFilter {
  igaAppID: number;
  applicationName = '';
  key = '';
  displayName = '';
  active = false;
  selected = false;
  actionType = '';
  originalSelected = false;
  originalMapped = '';

  constructor(
    params: IAppRoleFilter,
    isSelected: boolean,
    getPayload: IAppRFPayload,
    originalMapped: string,
  ) {
    if (params && getPayload) {
      this.key = params.key;
      this.displayName = params.displayName;
      this.active = params.active;
      this.selected = isSelected;
      this.originalSelected = this.selected;
      this.igaAppID = getPayload.igaAppID;
      this.applicationName = getPayload.applicationName;
      this.originalMapped = originalMapped;
    }
  }

  get displayIgaAndName(): string {
    return `${this.igaAppID}: ${this.applicationName}`;
  }

  get displayRfKeyAndName(): string {
    return `${this.key}: ${this.displayName}`;
  }
}

export class AppRF {
  igaAppID: number;
  applicationName = '';
  active = false;
  combinedRoleFilters: RoleFilter[] = [];

  constructor(param: IAppRFPayload) {
    if (param) {
      this.igaAppID = param.igaAppID;
      this.applicationName = param.applicationName;
      this.active = param.active;
      this.combinedRoleFilters = combinedRoleFilters(param);
    }
  }
}

export class AppRFPostPayload {
  igaAppID: number;
  combinedRoleFilters: ICombinedRoleFilters[];

  constructor(params: IAppRFPostPayload) {
    if (params) {
      this.igaAppID = params.igaAppID;
      this.combinedRoleFilters = params.combinedRoleFilters;
    }
  }

  /** payload for Post API : applications/applicationrolefilters */
  getPayload(): IAppRFPostPayload[] {
    const payloads: IAppRFPostPayload[] = [];
    this.combinedRoleFilters.forEach((x) => {
      if (x.selected && x.actionType === EActionType.activate) {
        const obj: IAppRFPostPayload = {
          igaAppID: this.igaAppID,
          key: x.key,
          active: true,
        };
        payloads.push(obj);
      }
      if (x.originalSelected && x.actionType === EActionType.inactivate) {
        const obj: IAppRFPostPayload = {
          igaAppID: this.igaAppID,
          key: x.key,
          active: false,
        };
        payloads.push(obj);
      }
    });
    return payloads;
  }
}

function combinedRoleFilters(params: IAppRFPayload): RoleFilter[] {
  const selected = (params.mappedRoleFilters ??= []);
  const unselected = (params.availableRoleFilters ??= []);

  const arr = selected.map(
    (d) => new RoleFilter(d, true, params, EActionType.activate),
  );
  return arr.concat(
    unselected.map(
      (d) => new RoleFilter(d, false, params, EActionType.inactivate),
    ),
  );
}
