export interface IAppOnboardingStatusPayload {
  application: IAppOnboardingStatus[];
}

export interface IAppOnboardingStatus {
  igaAppId: number;
  applicationName: string;
  onboardingStatus: string;
}

export class AppOnboardingStatus {
  igaAppId: number;
  applicationName = '';
  onboardingStatus = '';

  constructor(params?: IAppOnboardingStatus) {
    if (params) {
      this.igaAppId = params.igaAppId;
      this.applicationName = params.applicationName;
      this.onboardingStatus = params.onboardingStatus;
    }
  }
}
