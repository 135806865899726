import { CrudStppr, EAlterState } from './CrudStppr';

export interface IRoleFilterPayload {
  roleFilters: IRoleFilter[];
}

export interface IRoleFilterSml {
  key: string;
  displayName: string;
}

export interface IRoleFilter {
  key: string;
  displayName: string;
  selector?: string;
  desc?: string;
  title?: string;
  parentKey?: string;
  parentDisplayName?: string;
  parentRF?: IRoleFilterSml;
  required?: boolean;
  active: boolean;
  visible?: boolean;
}

export interface IRoleFilterValidationPayload {
  RoleFilterKey: string;
  FilterDisplayName: string;
  FilterSelectorType: string;
  FilterDescription?: string;
  FilterTitle?: string;
  ParentKey?: string;
  IsRequired: boolean;
  IsActive: boolean;
}

export class RoleFilterSml {
  key = '';
  displayName = '';

  constructor(params?: IRoleFilterSml) {
    if (params) {
      this.key = params.key;
      this.displayName = params.displayName;
    }
  }

  get display(): string {
    if (this.key && this.displayName) {
      return `${this.key}: ${this.displayName}`;
    }
    return this.key || this.displayName;
  }
}

export class RoleFilter extends CrudStppr<RoleFilter> {
  key = '';
  displayName = '';
  desc = '';
  selector = '';
  title = '';
  parentRF: RoleFilterSml;

  constructor(params?: IRoleFilter, i?: number) {
    super(i);
    if (params) {
      this.key = params.key;
      this.displayName = params.displayName;
      this.desc = params.desc;
      this.selector = params.selector;
      this.title = params.title;
      this.required = params.required;
      this.active = params.active;
      this.visible = params.visible;
      if (params.parentRF) {
        this.parentRF = new RoleFilterSml(params.parentRF);
      } else if (params.parentKey) {
        this.parentRF = new RoleFilterSml({
          displayName: params.parentDisplayName,
          key: params.parentKey,
        });
      }
    } else {
      this.required = false;
    }
  }

  get display() {
    if (!this.key) {
      return `${this.displayName}`;
    }
    return `${this.key}: ${this.displayName}`;
  }

  get displayParent() {
    if (this.parentRF) {
      return this.parentRF.display;
    }
    return '';
  }

  get payload() {
    return {
      key: this.key,
      displayName: this.displayName,
      desc: this.desc,
      selector: this.selector,
      title: this.title,
      parentKey: this.parentRF?.key || null,
      parentDisplayName: this.parentRF?.displayName || null,
      required: this.required,
      active: this.active,
      visible: this.visible,
    };
  }

  get ValidationPayload(): IRoleFilterValidationPayload {
    const obj: IRoleFilterValidationPayload = {
      RoleFilterKey: this.key,
      FilterDisplayName: this.displayName,
      FilterSelectorType: this.selector,
      IsRequired: this.required,
      IsActive: this.active,
    };

    if (this.desc) {
      obj.FilterDescription = this.desc;
    }
    if (this.title) {
      obj.FilterTitle = this.title;
    }
    if (this.parentRF) {
      obj.ParentKey = this.parentRF.key;
    }

    return obj;
  }

  reset(): void {
    const uc = this.updatedClass as RoleFilter;
    if (uc) {
      this.key = uc.key;
      this.displayName = uc.displayName;
      this.selector = uc.selector;
      this.desc = uc.desc;
      this.title = uc.title;
      this.parentRF = uc.parentRF;
      this.required = uc.required;
      this.active = uc.active;
      this.visible = uc.visible;
    }
    this.action = EAlterState.nothing;
  }
}
