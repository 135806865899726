import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { EDataImpactHBState, IDataImpactCsv } from 'src/app/models/DataImpact';
import { DataImpactService } from './data-impact.service';

@Component({
  selector: 'app-data-impact',
  templateUrl: './data-impact.component.html',
  styleUrls: ['./data-impact.component.scss'],
})
export class DataImpactComponent implements OnInit, OnDestroy {
  @Input() mngr: DataImpactService;

  private subs: Subscription[] = [];

  public downloadState = EDataImpactHBState.notStarted;
  public csvs: IDataImpactCsv[] = [];
  public presignedUrl: string;

  private downloadId: string;
  private pollTimeout: NodeJS.Timeout;
  private pollrequest: Subscription;

  ngOnInit(): void {
    this.subs.push(
      this.mngr.dataImpPreview$.subscribe(this.previewChanged.bind(this)),
    );
    this.subs.push(
      this.mngr.dataImpDownload$.subscribe(this.downloadChanged.bind(this)),
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
    this.clearPoll();
  }

  public downloadClicked(): void {
    if (this.downloadState === EDataImpactHBState.notStarted) {
      this.downloadState = EDataImpactHBState.running;

      this.mngr.callDownload().subscribe(() => {
        this.pollHeartbeat();
      });
    }
  }

  private previewChanged(d: IDataImpactCsv[]): void {
    this.clearPoll();
    if (Array.isArray(d)) {
      this.csvs = d;
    } else {
      this.csvs = [];
    }
  }

  private downloadChanged(str: string): void {
    this.clearPoll();
    if (str) {
      this.downloadId = str;
    } else {
      this.downloadState = EDataImpactHBState.notStarted;
    }
  }

  private pollHeartbeat(): void {
    this.pollrequest = this.mngr.apiService
      .checkDataImpactHeartBeat(this.downloadId)
      .subscribe((status) => {
        if (status === EDataImpactHBState.succeeded) {
          this.mngr.apiService
            .getDataImpactPresignedUrl(this.downloadId)
            .subscribe((url) => {
              this.downloadState = status;
              this.presignedUrl = url;
              window.open(url, '_blank');
            });
        } else if (status === EDataImpactHBState.running) {
          this.downloadState = status;
          this.pollTimeout = setTimeout(() => {
            this.pollHeartbeat();
          }, 3000);
        } else {
          this.downloadState = EDataImpactHBState.failed;
        }
      });
  }

  private clearPoll(): void {
    clearTimeout(this.pollTimeout);
    if (this.pollrequest) {
      this.pollrequest.unsubscribe();
      this.pollrequest = undefined;
      this.downloadId = undefined;
      this.presignedUrl = undefined;
      this.downloadState = EDataImpactHBState.notStarted;
    }
  }
}
