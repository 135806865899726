import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { IngestionService } from 'src/app/singletons/ingestion.service';
import { MiscConfigApiService } from './misc-config-api.service';

export const miscConfigCanActivate: CanActivateFn = (
  route: ActivatedRouteSnapshot,
) => {
  if (route.routeConfig.path) {
    const ingService = inject(IngestionService);
    ingService.api = inject(MiscConfigApiService);
    return true;
  }

  return inject(Router).parseUrl('/securitymodel');
};
