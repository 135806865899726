import { DataSource } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Observable, merge, of, map } from 'rxjs';
import { UpdateEvents } from 'src/app/models/Release';

export class UpdateEventTblDataSource extends DataSource<UpdateEvents> {
  data: UpdateEvents[] = [];
  paginator: MatPaginator | undefined;
  sort: MatSort | undefined;

  constructor(
    eventsData, // Inject updateEventsData array
  ) {
    super();
    this.setFilteredData(eventsData); // Use injected data to set data array
  }
  connect(): Observable<UpdateEvents[]> {
    if (this.paginator && this.sort) {
      return merge(
        of(this.data),
        this.paginator.page,
        this.sort.sortChange,
      ).pipe(
        map(() => {
          return this.getPagedData(this.getSortedData([...this.data]));
        }),
      );
    } else {
      return of([]);
    }
  }

  disconnect(): void {
    //not needed
  }

  getPagedData(data: UpdateEvents[]): UpdateEvents[] {
    if (this.paginator) {
      const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
      return data.splice(startIndex, this.paginator.pageSize);
    } else {
      return data;
    }
  }

  getSortedData(data: UpdateEvents[]): UpdateEvents[] {
    if (!this.sort || !this.sort.active || this.sort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      const isAsc = this.sort?.direction === 'asc';
      switch (this.sort?.active) {
        // Adjust according to your column names
        case 'event':
          return this.compare(a.event, b.event, isAsc);
        case 'createdBy':
          return this.compare(a.createdBy, b.createdBy, isAsc);
        case 'updatedBy':
          return this.compare(a.updatedBy, b.updatedBy, isAsc);
        default:
          return this.compare(a.event, b.event, isAsc);
      }
    });
  }

  private setFilteredData(data: UpdateEvents[]): void {
    this.data = data;
  }

  private compare(
    a: string | number,
    b: string | number,
    isAsc: boolean,
  ): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
