import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

@Component({
  selector:
    'app-bsc-inpt-fld[abstrctCntrl][fg][frmCntrlNm][frmFldClss][inptId][label]',
  templateUrl: './bsc-inpt-fld.component.html',
})
export class BscInptFldComponent {
  @Input() abstrctCntrl: AbstractControl;
  @Input() fg: FormGroup;
  @Input() frmCntrlNm: string;
  @Input() frmFldClss: string;
  @Input() inptId: string;
  @Input() label: string;

  @Input() isTxtArea = false;
  @Input() tooltip = '';
  @Input() errPttrn = 'Only ASCII characters are allowed';
  @Input() isReadOnly = false;
  @Output() blurEvent = new EventEmitter<any>();

  onBlur(event: any) {
    this.blurEvent.emit(event);
  }
}
