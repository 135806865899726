import { CrudStppr, EAlterState } from './CrudStppr';

export interface IAreaOfInterestPayload {
  areaOfInterests: IAreaOfInterest[];
}

export interface IAreaOfInterest {
  key: string;
  name: string;
  description?: string;
  active: boolean;
  visible?: boolean;
}

export interface IAreaOfInterestValidationPayload {
  AreaOfInterestKey: string;
  AreaOfInterestName: string;
  AreaOfInterestDescription?: string;
  IsActive: boolean;
  IsVisible: boolean;
}

export class AreaOfInterest extends CrudStppr<AreaOfInterest> {
  key = '';
  name = '';
  description? = '';

  constructor(params?: IAreaOfInterest, i?: number) {
    super(i);
    if (params) {
      Object.assign(this, params);
    }
  }

  get displayName() {
    if (!this.key) {
      return `${this.name}`;
    }
    return `${this.key}: ${this.name}`;
  }

  get display() {
    return `${this.key}: ${this.displayName}`;
  }

  get payload() {
    return {
      key: this.key,
      name: this.name,
      description: this.description,
      active: this.active,
      visible: this.visible,
    };
  }

  get ValidationPayload(): IAreaOfInterestValidationPayload {
    const obj: IAreaOfInterestValidationPayload = {
      AreaOfInterestKey: this.key,
      AreaOfInterestName: this.name,
      IsActive: this.active,
      IsVisible: this.visible,
    };

    if (this.description) {
      obj.AreaOfInterestDescription = this.description;
    }

    return obj;
  }

  reset(): void {
    const uc = this.updatedClass as AreaOfInterest;
    if (uc) {
      this.key = uc.key;
      this.name = uc.name;
      this.description = uc.description;
      this.active = uc.active;
      this.visible = uc.visible;
    }
    this.action = EAlterState.nothing;
  }
}
