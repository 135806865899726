export enum EActionType {
  activate = 'Activate',
  inactivate = 'Inactivate',
}
export interface IBRCapPayload {
  businessRoleKey: string;
  businessRoleName: string;
  active: boolean;
  capabilities?: ICapability[];
  availableCapabilities?: ICapability[];
}

export interface ICapability {
  capabilityName: string;
  capabilityDescription: string;
  active: boolean;
}

export class CapabilityPayload {
  businessRoleKey: string;
  businessRoleName: string;
  capabilityName: string;
  capabilityDescription: string;
  active: boolean;
  selected?: boolean;
  actionType?: string;
  originalMapped?: string;
}

interface IPostCapNamePayload {
  capabilities: string[];
}

export interface IBRCapabilityPostPayload {
  businessRoleKey: string;
  lower?: IPostCapNamePayload;
  higher?: IPostCapNamePayload;
  combinedCapabilitiesLower?: CapabilityPayload[];
  combinedCapabilitiesHigher?: CapabilityPayload[];
  selected?: boolean;
  changed?: boolean;
  active?: boolean;
  actionType?: string;
  originalMapped?: string;
}

export class Capability {
  businessRoleKey = '';
  businessRoleName = '';
  capabilityName = '';
  capabilityDescription = '';
  active = false;
  selected = false;
  actionType = '';
  originalSelected = false;
  originalMapped = '';

  constructor(
    params: ICapability,
    isSelected: boolean,
    getPayload: IBRCapPayload,
    originalMapped: string,
  ) {
    if (params && getPayload) {
      this.capabilityName = params.capabilityName;
      this.capabilityDescription = params.capabilityDescription;
      this.active = params.active;
      this.selected = isSelected;
      this.businessRoleKey = getPayload.businessRoleKey;
      this.businessRoleName = getPayload.businessRoleName;
      this.originalSelected = this.selected;
      this.originalMapped = originalMapped;
    }
  }

  get displayBrKeyAndName(): string {
    return `${this.businessRoleKey}: ${this.businessRoleName}`;
  }
}

export class BRCapability {
  businessRoleKey = '';
  businessRoleName = '';
  active = false;
  combinedCapabilitiesLower?: Capability[] = [];
  combinedCapabilitiesHigher?: Capability[] = [];

  constructor(param: IBRCapPayload, isLower?: boolean) {
    if (param && isLower) {
      this.businessRoleKey = param.businessRoleKey;
      this.businessRoleName = param.businessRoleName;
      this.active = param.active;
      this.combinedCapabilitiesLower = combineCapability(param);
    }
    if (param && !isLower) {
      this.businessRoleKey = param.businessRoleKey;
      this.businessRoleName = param.businessRoleName;
      this.active = param.active;
      this.combinedCapabilitiesHigher = combineCapability(param);
    }
  }
}

export class BRCapabilityPostPayload {
  businessRoleKey = '';
  combinedCapabilitiesLower: any[];
  combinedCapabilitiesHigher: any[];
  lower?: any;
  higher?: any;

  constructor(low?: IBRCapabilityPostPayload, high?: IBRCapabilityPostPayload) {
    if (low) {
      this.businessRoleKey = low.businessRoleKey;
      this.combinedCapabilitiesLower = low.combinedCapabilitiesLower;
    }

    if (high) {
      this.businessRoleKey = high.businessRoleKey;
      this.combinedCapabilitiesHigher = high.combinedCapabilitiesHigher;
    }
  }

  /** payload for Post API : businessroles/businessroleCapabilities */
  getPayload(): IBRCapabilityPostPayload {
    const lowerCapNameStrs: string[] = [];
    const higherCapNameStrs: string[] = [];
    this.combinedCapabilitiesLower.forEach((ed) => {
      if (ed.selected === true) {
        lowerCapNameStrs.push(ed.capabilityName);
      }
    });
    this.combinedCapabilitiesHigher.forEach((ed) => {
      if (ed.selected === true) {
        higherCapNameStrs.push(ed.capabilityName);
      }
    });
    const obj: IBRCapabilityPostPayload = {
      businessRoleKey: this.businessRoleKey,
      lower: {
        capabilities: lowerCapNameStrs,
      },
      higher: {
        capabilities: higherCapNameStrs,
      },
    };

    return obj;
  }
}

function combineCapability(params: IBRCapPayload): Capability[] {
  const selected = (params.capabilities ??= []);
  const unselected = (params.availableCapabilities ??= []);

  const arr = selected.map(
    (d) => new Capability(d, true, params, EActionType.activate),
  );
  return arr.concat(
    unselected.map(
      (d) => new Capability(d, false, params, EActionType.inactivate),
    ),
  );
}
