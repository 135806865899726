import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { constants } from 'src/app/models/constants';
import {
  BRFilterSelections,
  EActionType,
  IRoleFilterByIgaAppId,
} from 'src/app/models/BusinessRoleFilterSelections';
import { AppBrEdmApiService } from '../../../app-br-edm-api.service';
import { BrFgService } from '../br-fg.service';

@Injectable({
  providedIn: 'any',
})
export class MapFSFGService {
  fg: FormGroup;
  subs: Subscription[] = [];
  fb: FormBuilder;
  activatedFs: any[] = [];
  removeActivatedFs$ = new Subject<boolean>();
  defaultFsAc: BRFilterSelections[] = [];
  fgResetFlag = false;
  isValidTriggerFsPost = false;
  displayFs = false;
  rfByIgaAppIdResults: IRoleFilterByIgaAppId[] = [];

  constructor(
    fb: FormBuilder,
    private appBrEdmApiService: AppBrEdmApiService,
    private brFgService: BrFgService,
  ) {
    this.fb = fb;
    this.setFg();
  }

  private setFg(): void {
    this.fg = this.fb.group({
      businessRoleKey: [
        '',
        [
          Validators.required,
          Validators.pattern(constants.regExNoSpecialChars),
        ],
      ],
      businessRoleName: [
        '',
        [
          Validators.required,
          Validators.pattern(constants.regExNoSpecialChars),
        ],
      ],
      active: false,
      selected: false,
      combinedFilterSelections: [[]],
      filterSelectKey: '',
      filterSelectValue: '',
    });
  }

  onInit(): void {
    this.resetFG();
  }

  onDestroy(): void {
    this.defaultFsAc = [];
    this.activatedFs = [];
    this.fgResetFlag = false;
    this.isValidTriggerFsPost = false;
    this.subs.forEach((s) => s.unsubscribe());
  }

  resetFG(): void {
    this.fgResetFlag = true;
    this.fg.reset(new BRFilterSelections(null));
    this.fgResetFlag = false;
  }

  checkBrTableProcess(): void {
    if (!this.fgResetFlag) {
      this.checkBrTable();
      this.checkCapsArray();
    }
  }

  checkBrTable(): void {
    for (const item of this.acCombinedFS.value) {
      if (
        (item.actionType === EActionType.activate &&
          item.originalMapped === EActionType.inactivate) ||
        (item.actionType === EActionType.inactivate &&
          item.originalMapped === EActionType.activate)
      ) {
        this.activatedFs.push(item);
        return;
      }
    }
  }

  removefromActivatedFs(key: string): void {
    if (this.activatedFs && this.activatedFs.length > 0) {
      this.activatedFs = this.activatedFs.filter(
        (item) => item.businessRoleKey !== key,
      );
    }
  }

  checkCapsArray(): void {
    if (this.activatedFs.length > 0) {
      this.isValidTriggerFsPost = true;
    } else {
      this.isValidTriggerFsPost = false;
    }
  }

  removeFsFromStaged(key: string): void {
    this.defaultFsAc = this.defaultFsAc.filter(
      (fs) => fs.businessRoleKey !== key,
    );

    this.removefromActivatedFs(key);
    this.removeActivatedFs$.next(true);
    this.removeActivatedFs$.next(false);
  }

  getCurrFsAc(key: string): any {
    return this.defaultFsAc.find((ac) => ac.businessRoleKey === key);
  }
  hasBrFsChanged(): boolean {
    return this.fg.dirty;
  }

  getRfMappingByIgaAppId(rhtData: any[]): void {
    const igaAppIDs = rhtData.map((app) => app.igaAppID);

    this.rfByIgaAppIdResults = [];

    igaAppIDs.forEach((igaAppID) => {
      this.appBrEdmApiService.getRfKeyByIgaAppId(igaAppID).subscribe((data) => {
        if (data.roleFilterKeys) {
          this.rfByIgaAppIdResults.push({
            igaAppId: igaAppID,
            roleFilterKeys: data.roleFilterKeys,
          });
        }
      });
    });

    this.brFgService.changeData(this.rfByIgaAppIdResults);
  }

  removeIgaAppId(d: any): void {
    const igaAppID = d['igaAppID'].toString();
    const index = this.rfByIgaAppIdResults.findIndex(
      (result) => result.igaAppId.toString() === igaAppID,
    );

    if (index !== -1) {
      this.rfByIgaAppIdResults.splice(index, 1);
    }

    this.brFgService.changeData(this.rfByIgaAppIdResults);
  }

  /* --------------------------------------- getters --------------------------------------- */
  /** AbstractControl value is a `FilterSelections` */
  get acCombinedFS(): AbstractControl {
    return this.fg.get('combinedFilterSelections');
  }
}
