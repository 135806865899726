import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ReleaseApiService } from '../release-api.service';
import { Subscription } from 'rxjs';
import {
  IEnvironment,
  IReleaseEventsViewModel,
  Schedule,
} from 'src/app/models/Release';
import { PopUpService } from 'src/app/singletons/popup/popup.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-form-schedule',
  templateUrl: './form-schedule.component.html',
  styleUrls: ['./form-schedule.component.scss'],
})
export class FormScheduleComponent implements OnInit, OnDestroy {
  title = '';
  actionTitle = '';
  fg: FormGroup;
  environments: IEnvironment[] = [];
  scheduleEvent: string;
  scheduleID: string;
  events: any;
  data?: IReleaseEventsViewModel;
  isAdding: boolean;
  environmentsSubs: Subscription;
  eventsSubs: Subscription;

  constructor(
    private fb: FormBuilder,
    private api: ReleaseApiService,
    public popup: PopUpService,
    private dialogRef: MatDialogRef<FormScheduleComponent>,
    @Inject(MAT_DIALOG_DATA) public matDialogData: any,
  ) {
    this.getReleaseEnvironments();
    this.getReleaseEvents();
  }

  ngOnInit(): void {
    this.callback = this.callback.bind(this);
    this.data = null;
    this.setForm();
    this.open(this.matDialogData);
  }

  addSchedule(): void {
    const schedulePayload = this.createSchedulePayload();
    let msg: string;
    if (this.isAdding) {
      this.api
        .createSchedule(schedulePayload, 'create', this.callback)
        .subscribe({
          next: (result) => {
            if (!result.message.includes('success')) {
              msg = Array.isArray(result.message)
                ? result.message[0]
                : result.message;
              this.popup.show('Create Schedule errors', msg);
              return;
            } else {
              this.callback();
            }
          },
          error: (err) => {
            msg = Array.isArray(err.error.message)
              ? err.error.message[0]
              : err.error.message;
            this.popup.show('Create Schedule errors', msg);
            return;
          },
        });
    } else {
      this.api
        .createSchedule(schedulePayload, 'update', this.callback)
        .subscribe({
          next: (result) => {
            if (!result.message.includes('success')) {
              msg = Array.isArray(result.message)
                ? result.message[0]
                : result.message;
              this.popup.show('Update Schedule errors', msg);
              return;
            } else {
              this.callback();
            }
          },
          error: (err) => {
            msg = Array.isArray(err.error.message)
              ? err.error.message[0]
              : err.error.message;
            this.popup.show('Update Schedule errors', msg);
            return;
          },
        });
    }
  }

  open(data?: any): void {
    if (data?.from === 'update') {
      this.setupForUpdate(data);
    } else {
      this.setupForAdd(data);
    }
  }

  setupForUpdate(data: any): void {
    let msg: string;
    this.data = { original: null };
    this.data.original = data;
    this.scheduleID = data.events.find(
      (e) => e.event === data.events[0].event,
    ).key;
    this.title = 'Update Schedule';
    this.actionTitle = 'Update';
    this.isAdding = false;
    this.api.getCalScheduleById(this.scheduleID).subscribe({
      next: (schedules) => {
        if (schedules.schedules.length === 0) {
          this.popup.show('Get Schedule errors', 'No schedule found');
          return;
        }
        this.fg.setValue({
          startDttm: schedules.schedules[0].startDttm,
          endDttm: schedules.schedules[0].endDttm,
          environment: schedules.schedules[0].environment,
          event: schedules.schedules[0].event,
        });
      },
      error: (err) => {
        msg = Array.isArray(err.error.message)
          ? err.error.message[0]
          : err.error.message;
        this.popup.show('Get Schedule errors', msg);
        return;
      },
    });
  }

  setupForAdd(data?: any): void {
    this.title = 'Add Schedule';
    this.actionTitle = 'Add';
    this.data = null;
    this.isAdding = true;

    // if the data is coming from the calendar, set the start and end date
    if (data?.from === 'calendar') {
      const start = data.startDate;
      const end = data.endDate;
      const startDttm = this.formatDate(start);
      const endDttm = this.formatDate(end);

      this.fg.setValue({
        startDttm,
        endDttm,
        environment: '',
        event: '',
      });
    }
  }
  close(dialogClose: string): void {
    this.fg.reset();
    if (dialogClose === 'callback') {
      this.dialogRef.close('callback');
      return;
    }
    // indicator that the form schedule dialog is closed using cancel button
    this.dialogRef.close('cancel');
  }

  callback(): void {
    this.getReleaseEvents();
    this.close('callback');
  }

  setForm(): void {
    this.fg = this.fb.group(
      {
        startDttm: ['', [Validators.required]],
        endDttm: ['', [Validators.required]],
        environment: ['', [Validators.required]],
        event: ['', [Validators.required]],
      },
      {
        validators: this.checkIfUnchanged(),
      },
    );
  }

  getReleaseEnvironments(): void {
    this.environmentsSubs = this.api
      .getReleaseEnvironments()
      .subscribe((data: IEnvironment[]) => {
        this.environments = data;
      });
  }

  getReleaseEvents(): void {
    this.eventsSubs = this.api.getCalEvents().subscribe((data) => {
      this.events = data;
    });
  }

  // this will return the date in the format of yyyy-mm-ddT00:00:00.000Z
  formatDate(dateString: string): string {
    const date = new Date(dateString);
    const timezoneOffsetMinutes = date.getTimezoneOffset();
    date.setMinutes(date.getMinutes() - timezoneOffsetMinutes);
    date.setUTCHours(0, 0, 0, 0);
    const isoString = date.toISOString();

    return isoString;
  }

  formatSelectedDttm(dateString: string): string {
    const date = new Date(dateString);
    const month = date.getMonth() + 1; // getMonth() returns month index starting from 0
    const year = date.getFullYear();

    // Pad the month with a leading zero if it's less than 10
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${year}-${formattedMonth}`;
  }

  private createSchedulePayload(): Schedule {
    const selectedDttm = this.formatSelectedDttm(this.fg.value.startDttm);
    const startDttm = this.formatDate(this.fg.value.startDttm);
    const endDttm = this.formatDate(this.fg.value.endDttm);
    return {
      key: this.scheduleID,
      startDttm,
      endDttm,
      selectedDttm,
      event: this.fg.value.event,
      environment: this.fg.value.environment,
    };
  }

  ngOnDestroy(): void {
    if (this.environmentsSubs) {
      this.environmentsSubs.unsubscribe();
    }

    if (this.eventsSubs) {
      this.eventsSubs.unsubscribe();
    }
  }

  checkIfUnchanged(): ValidatorFn {
    return (ac: AbstractControl): ValidationErrors | null => {
      if (!ac || !this.data) {
        return null;
      }
      const d = this.data.changed ?? this.data.original;

      if (
        this.getStartDttm.value === d.date &&
        this.getEndDttm.value === d.date &&
        this.getEnvironment.value === d.environment &&
        this.getEvent.value === this.scheduleEvent
      ) {
        return {
          unchanged: true,
        };
      }

      return null;
    };
  }

  updateStartDateValidity(): void {
    this.getStartDttm.updateValueAndValidity();
  }

  updateEndDateValidity(): void {
    this.getEndDttm.updateValueAndValidity();
  }

  get getEnvironment(): AbstractControl {
    return this.fg.get('environment');
  }

  get getEvent(): AbstractControl {
    return this.fg.get('event');
  }

  get getStartDttm(): AbstractControl {
    return this.fg.get('startDttm');
  }

  get getEndDttm(): AbstractControl {
    return this.fg.get('endDttm');
  }
}
