import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, of, tap } from 'rxjs';
import { API } from 'src/app/util/API';
import {
  AsmDimensionPostResponse,
  AsmPostResponse,
} from 'src/app/models/AsmPostResponse';
import {
  DataSel,
  IDataSel,
  IDataSelPayload,
  IDataSelGrpPayload,
  DSG,
} from 'src/app/models/DataSel';
import { IDimension } from 'src/app/models/Dim';
import { CrudStppr, CrudStpprPost } from 'src/app/models/CrudStppr';
import { PopUpService } from 'src/app/singletons/popup/popup.service';
import { SpinnerService } from 'src/app/singletons/spinner/spinner.service';
import { ToasterService } from 'src/app/singletons/toaster/toaster.service';
import { DataSelGrp, IDataSelGroupPayload } from 'src/app/models/DataSelGrp';

@Injectable({
  providedIn: 'root',
})
export class DdtdsdsgApiService extends API {
  private cacheDataSels: DataSel[];
  private cacheDataSelGroups: DSG[];

  constructor(
    http: HttpClient,
    pService: PopUpService,
    spinner: SpinnerService,
    toaster: ToasterService,
  ) {
    super(http, pService, spinner, toaster);
  }

  private readonly paths = {
    dataImpact: `${this.rootPath}dataImpact`,
    dataSel: `${this.rootPath}dataselections`,
    dataSelGrp: `${this.rootPath}dataselectiongroups`,
    dataSelMapping: `${this.rootPath}dataselectiondimensions`,
    dims: `${this.rootPath}dimensions`,
    dimType: `${this.rootPath}dimensiontypes`,
  };

  // ----------------------------------------------------- data sel
  /** @description get data selection via key */
  public getDataSel(key: string): Observable<DataSel> {
    return this.getReqHelper<IDataSel>(
      `${this.paths.dataSel}/${key}`,
      `Getting data selection for key ${key}`,
    ).pipe(map((dss) => new DataSel(dss)));
  }

  /** @description get all data selections */
  public getDataSels(fromCache = true): Observable<DataSel[]> {
    if (this.cacheDataSels && fromCache) return of(this.cacheDataSels);
    return this.getReqHelper<IDataSelPayload>(
      `${this.paths.dataSel}`,
      'Getting all data selections',
    ).pipe(
      map((dss) => dss.dataSelections.map((d, i) => new DataSel(d, i))),
      tap((ds) => (this.cacheDataSels = ds)),
    );
  }

  /** @description get all data selections */
  public getDataSelGroup(): Observable<DSG[]> {
    if (this.cacheDataSelGroups) return of(this.cacheDataSelGroups);
    return this.getReqHelper<IDataSelGrpPayload>(
      `${this.paths.dataSelGrp}`,
      'Getting data selection groups',
    ).pipe(
      map((d) => d.dataSelectionGroups.map((dsg) => new DSG(dsg))),
      tap((dsg) => (this.cacheDataSelGroups = dsg)),
    );
  }
  // ----------------------------------------------------- data sel group
  /** get all data selection groups */
  public getDataSelGroups(): Observable<DataSelGrp[]> {
    return this.getReqHelper<IDataSelGroupPayload>(
      `${this.paths.dataSelGrp}`,
      'Getting data selection groups',
    ).pipe(
      map((data) => {
        return data.dataSelectionGroups.map((d, i) => new DataSelGrp(d, i));
      }),
    );
  }

  // ----------------------------------------------------- data sel
  /** @description add/update data selection */
  public updateDataSel<T>(
    arr: CrudStppr<T>[],
    action: string,
  ): Observable<AsmPostResponse> {
    const data = { action: action, data: arr };
    return this.postReqHelper<AsmPostResponse>(
      `${this.paths.dataSel}`,
      data,
      action,
    ).pipe(
      tap({
        complete: () => {
          this.cacheDataSels = undefined;
          this.toaster.show('Data selections', `${action} was successful`);
        },
      }),
    );
  }

  /** @description cancel the add/update ingestion */
  public cancelDataSel(): Observable<AsmPostResponse> {
    return this.cancelIngestions(this.getReqId(), {
      csvs: ['DataSelection.csv'],
    });
  }

  // ----------------------------------------------------- dimensions
  /** @description add/update data selection */
  public updateDimensions<T>(
    arr: CrudStppr<T>[],
    action: string,
  ): Observable<AsmPostResponse> {
    const data = { action: action, data: arr };
    return this.updateDimensionHelper(data);
  }

  /** @description cancel the add/update ingestion */
  public cancelDim(): Observable<AsmPostResponse> {
    return this.cancelIngestions(this.getReqId(), {
      csvs: ['Dimension.csv', 'DataSelectionDimensionMapping.csv'],
    });
  }

  // ----------------------------------------------------- dim types
  /**
   * @description add/update data selection
   * this is done via bulk (forkjoin) so dont call
   * catcherror.
   * */
  public updateDimensionTypes<T>(arr: CrudStppr<T>[], action: string) {
    const data = { action: action, data: arr };
    return this.postReqHelper<AsmPostResponse>(
      `${this.paths.dimType}`,
      data,
      action,
    ).pipe(
      tap({
        complete: () => {
          this.toaster.show('Dimension types', `${action} was successful`);
        },
      }),
    );
  }
  /** @description cancel the add/update ingestion */
  public cancelDimType(): Observable<AsmPostResponse> {
    return this.cancelIngestions(this.getReqId(), {
      csvs: ['DimensionType.csv'],
    });
  }

  private updateDimensionHelper(
    data: CrudStpprPost<IDimension>,
  ): Observable<AsmDimensionPostResponse> {
    return this.postReqHelper<AsmDimensionPostResponse>(
      `${this.paths.dims}`,
      data,
      `${data.action} dimension`,
    ).pipe(
      tap({
        complete: () => {
          this.toaster.show('Dimension', `${data.action} was successful`);
        },
      }),
    );
  }

  // ----------------------------------------------------- data sel group
  /** @description add/update data selection group */
  public updateDataSelGrp<T>(
    arr: CrudStppr<T>[],
    action: string,
  ): Observable<AsmPostResponse> {
    const data = { action: action, data: arr };
    return this.postReqHelper<AsmPostResponse>(
      `${this.paths.dataSelGrp}`,
      data,
      action,
    ).pipe(
      tap({
        complete: () => {
          this.cacheDataSelGroups = undefined;
          this.toaster.show(
            'Data selection groups',
            `${action} was successful`,
          );
        },
      }),
    );
  }

  /** @description cancel the add/update ingestion */
  public cancelDataSelGrp(): Observable<AsmPostResponse> {
    return this.cancelIngestions(this.getReqId(), {
      csvs: ['DataSelectionGroup.csv'],
    });
  }
}
