<div class="popup">
    <div class="back-drop">&nbsp;</div>
    <div class="modal-wrapper">
        <div class="modal">
            <div class="title-space" cdkFocusInitial tabindex="-1">
                <label>{{title}}</label>
            </div>
            <form [formGroup]="fg" class="form-group">
                <fieldset>
                    <legend>Date <sup>&#9733;</sup></legend>
                    <div class="date-wrapper">
                        <div class="range-wrapper">
                            <mat-form-field appearance="fill">
                                <mat-date-range-input [rangePicker]="picker">
                                    <input matStartDate formControlName="startDttm" placeholder="Start date"
                                        (blur)="updateEndDateValidity()">
                                    <input matEndDate formControlName="endDttm" placeholder="End date"
                                        (blur)="updateStartDateValidity()">
                                </mat-date-range-input>
                                <mat-hint>MM/DD/YYYY - MM/DD/YYYY</mat-hint>
                                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-date-range-picker #picker></mat-date-range-picker>
                            </mat-form-field>
                            <mat-error *ngIf="
                                (getStartDttm.invalid && (getStartDttm.dirty || getStartDttm.touched)) &&
                                !getStartDttm.errors?.matDatepickerParse && !getStartDttm.errors?.matStartDateInvalid">
                                Start date is required.
                            </mat-error>
                            <mat-error *ngIf="getStartDttm.errors?.matDatepickerParse">
                                Start date is invalid
                            </mat-error>
                            <mat-error *ngIf="getStartDttm.errors?.matStartDateInvalid">
                                Start date is after end date
                            </mat-error>
                            <mat-error *ngIf="
                                (getEndDttm.invalid && (getEndDttm.dirty || getEndDttm.touched)) &&
                                !getEndDttm.errors?.matDatepickerParse && !getEndDttm.errors?.matEndDateInvalid">
                                End date is required.
                            </mat-error>
                            <mat-error *ngIf="getEndDttm.errors?.matDatepickerParse">
                                End date is invalid
                            </mat-error>
                            <mat-error *ngIf="getEndDttm.errors?.matEndDateInvalid">
                                End date is before start date
                            </mat-error>
                        </div>
                    </div>

                </fieldset>
                <!-- Environment -->
                <fieldset>
                    <legend>Environment <sup>&#9733;</sup></legend>
                    <app-bsc-cstm-fld [abstrctCntrl]="getEnvironment" [customLabelName]="'Environment'"
                        inptId="sched-env" label="" frmFldClss="sched-env">
                        <mat-select id="sched-env" formControlName="environment">
                            <mat-option>-- Select an environment--</mat-option>
                            <ng-container *ngFor="let env of environments">
                                <mat-option [value]="env.name">
                                    {{env.name}}
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </app-bsc-cstm-fld>
                </fieldset>
                <!-- Event -->
                <fieldset>
                    <legend>Event <sup>&#9733;</sup></legend>
                    <app-bsc-cstm-fld [abstrctCntrl]="getEvent" [customLabelName]="'Event'" inptId="sched-event"
                        label="" frmFldClss="sched-event">
                        <mat-select id="sched-event" formControlName="event">
                            <mat-option>-- Select an event--</mat-option>
                            <ng-container *ngFor="let events of events">
                                <mat-option [value]="events.event">
                                    {{events.event}}
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </app-bsc-cstm-fld>
                </fieldset>
            </form>
            <div>
                <button [disabled]="!fg.valid || fg.pristine" id="add" mat-button type="button"
                    class="btn btn-two mr-10" (click)="addSchedule()">{{actionTitle}}</button>
                <button id="close" mat-button type="button" class="btn btn-one" id="cancelBtn"
                    (click)="close('cancel')">Cancel</button>
            </div>
        </div>
    </div>
</div>
