import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { IngestionService } from 'src/app/singletons/ingestion.service';
import { DdtdsdsgApiService } from './ddtdsdsg-api.service';

export const ddtdsdsgConfigCanActivate: CanActivateFn = (
  route: ActivatedRouteSnapshot,
) => {
  if (route.routeConfig.path) {
    const ingService = inject(IngestionService);
    ingService.api = inject(DdtdsdsgApiService);
    return true;
  }

  return inject(Router).parseUrl('/securitymodel');
};
