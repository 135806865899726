<app-bsc-cstm-fld [abstrctCntrl]="abstrctCntrl" [tooltip]="tooltip" [errPttrn]="errPttrn" [inptId]="inptId"
    [label]="label" [frmFldClss]="frmFldClss">
    <ng-container rht-header>
        <ng-content select="[rht-header]"></ng-content>
    </ng-container>
    <ng-container [formGroup]="fg">
        <input *ngIf="!isTxtArea" matInput [id]="inptId" type="text" [formControlName]="frmCntrlNm"
            [readonly]="isReadOnly" (blur)="onBlur($event)">
        <textarea *ngIf="isTxtArea" matInput [id]="inptId" [formControlName]="frmCntrlNm"
            [readonly]="isReadOnly"></textarea>
    </ng-container>
</app-bsc-cstm-fld>
