<div class="app-user">
    <div *ngIf="authService.currUser; else signInBlock" class="signed-in">
        <div class="user-name" (click)="clicked()">{{authService.currUser.displayName}}</div>
        <div class="log-out" [class.is-expanded]="isExpanded">
            <div>{{authService.currUser.email}}</div>
            <div class="btn-wrapper">
                <div class="btn" (click)="signOut()">Sign out</div>
            </div>
            <div class="switch-acc" (click)="authService.login()">Switch accounts</div>
        </div>
        <div class="backsplash" (click)="setToFalse()" [class.is-expanded]="isExpanded">&nbsp;</div>
    </div>
    <ng-template #signInBlock>
        <div class="btn" (click)="this.authService.login()">Sign in</div>
    </ng-template>
</div>