import { BrApp, IBrApp } from './Application';

export interface IBrPayload {
  businessRoles: IBrMd[];
}

export interface IAccPkgMappedBrPayload {
  key: string;
  businessRoles: IBrSml[];
}

export interface IBrSml {
  key: string;
  name: string;
  desc: string;
  applications: IBrApp[];
}

export interface IBrMd extends IBrSml {
  active: boolean;
  visible: boolean;
}

export interface IBrSmlVal {
  IsActive: boolean;
  AccessPackageKey: string;
  BusinessRoleKey: string;
}

export interface IBrSmlUpdate {
  brKey: string;
}

export class BrMd {
  key = '';
  name = '';
  desc = '';
  applications: BrApp[] = [];
  active = true;
  visible = true;
  selected = false;
  original = false;
  removed = false;

  constructor(params?: IBrMd | BrMd) {
    if (params) {
      this.key = params.key;
      this.name = params.name;
      this.desc = params.desc;
      if (Array.isArray(params.applications)) {
        if (params.applications[0] instanceof BrApp) {
          this.applications = params.applications as BrApp[];
        } else {
          this.applications = params.applications.map((d) => new BrApp(d));
        }
      }
      this.active = params.active;
      this.visible = params.visible;
    }
  }

  get display(): string {
    return `${this.key}: ${this.name}`;
  }

  get applicationInfo(): string {
    if (this.applications.length === 0) {
      return 'No applications available';
    }

    const applicationInfoString = this.applications
      .map((d) => d.longDisplay)
      .join('\n');
    return `Business role name: ${this.name}\n\n${applicationInfoString}`;
  }

  get appInfo(): string {
    return this.applications.map((app) => app.longDisplay).join(';');
  }

  get matchingAppIDs(): string {
    const apps = this.applications;

    if (apps && apps.length === 1) {
      if (apps[0].airID === apps[0].igaAppID) {
        return `AIR ${apps[0].airID}`;
      } else {
        return `IGA app ${apps[0].igaAppID}`;
      }
    } else if (apps && apps.length > 1) {
      return 'Cross Applications';
    }
    return 'No applications';
  }

  get payload(): IBrMd {
    return {
      key: this.key,
      name: this.name,
      desc: this.desc,
      applications: this.applications.map((d) => d.payload),
      active: this.active,
      visible: this.visible,
    };
  }
}
