import { CrudStppr, EAlterState } from './CrudStppr';

export interface IDataSelGroupPayload {
  dataSelectionGroups: IDataSelGrp[];
}
export interface IDataSelGrpSml {
  key: string;
  name: string;
}

export interface IDataSelGrp {
  key: string;
  name: string;
  selectorDisplayName?: string;
  selectorDescription?: string;
  selectorTitle?: string;
  selectorType?: string;
  parents?: IDataSelGrpSml[];
  order?: number;
  active: boolean;
  visible: boolean;
}

export interface IDataSelGrpValidationPayload {
  DataSelectionGroupKey: string;
  DataSelectionGroupName: string;
  SelectorDisplayName: string;
  SelectorDescription?: string;
  SelectorTitle?: string;
  SelectorType: string;
  Parent?: string;
  Order?: number;
  IsActive: boolean;
  IsVisible: boolean;
}

export class DataSelGrpSml {
  key = '';
  name = '';

  constructor(params?: IDataSelGrpSml) {
    if (params) {
      this.key = params.key;
      this.name = params.name;
    }
  }

  get display(): string {
    if (this.key && this.name) {
      return `${this.key}: ${this.name}`;
    }
    return this.key || this.name;
  }
}

export class DataSelGrp extends CrudStppr<DataSelGrp> {
  key = '';
  name = '';
  selectorDisplayName = '';
  selectorDescription? = '';
  selectorTitle? = '';
  selectorType = '';
  order?: number;
  parents: DataSelGrpSml[] = [];

  constructor(params?: IDataSelGrp, i?: number) {
    super(i);
    if (params) {
      this.key = params.key;
      this.name = params.name;
      this.selectorDisplayName = params.selectorDisplayName;
      this.selectorDescription = params.selectorDescription;
      this.selectorTitle = params.selectorTitle;
      this.selectorType = params.selectorType;
      this.order = params.order;
      this.active = params.active;
      this.visible = params.visible;
      if (params.parents) {
        params.parents.forEach((parent) => {
          this.parents.push(new DataSelGrpSml(parent));
        });
      }
    } else {
      this.required = false;
    }
  }
  get display() {
    return `${this.key}: ${this.name}`;
  }

  get displayParent() {
    if (this.parents) {
      let parentStr = '';
      this.parents.forEach((parent) => {
        parentStr += `${parent.display}, `;
      });
      parentStr = parentStr.slice(0, -2); // Remove last two characters
      return parentStr;
    }
    return '';
  }

  get payload() {
    return {
      key: this.key,
      name: this.name,
      selectorDisplayName: this.selectorDisplayName,
      selectorDescription: this.selectorDescription,
      selectorTitle: this.selectorTitle,
      selectorType: this.selectorType,
      parents: this.getParentsKeys(this.parents) || null,
      order: this.order,
      active: this.active,
      visible: this.visible,
    };
  }

  getParentsKeys(parents: DataSelGrpSml[]): string {
    let parentsKey = '';
    parents.forEach((parent) => {
      parentsKey += `${parent.key},`;
    });
    parentsKey = parentsKey.slice(0, -1); // Remove last character
    return parentsKey;
  }

  get ValidationPayload(): IDataSelGrpValidationPayload {
    const obj: IDataSelGrpValidationPayload = {
      DataSelectionGroupKey: this.key,
      DataSelectionGroupName: this.name,
      SelectorDisplayName: this.selectorDisplayName,
      SelectorType: this.selectorType,
      Order: this.order,
      IsActive: this.active,
      IsVisible: this.visible,
    };

    if (this.selectorDescription) {
      obj.SelectorDescription = this.selectorDescription;
    }
    if (this.selectorTitle) {
      obj.SelectorTitle = this.selectorTitle;
    }
    if (this.parents) {
      obj.Parent = this.getParentsKeys(this.parents);
    }

    return obj;
  }

  reset(): void {
    const uc = this.updatedClass as DataSelGrp;
    if (uc) {
      this.key = uc.key;
      this.name = uc.name;
      this.selectorDisplayName = uc.selectorDisplayName;
      this.selectorDescription = uc.selectorDescription;
      this.selectorTitle = uc.selectorTitle;
      this.selectorType = uc.selectorType;
      this.parents = uc.parents;
      this.order = uc.order;
      this.active = uc.active;
      this.visible = uc.visible;
    }
    this.action = EAlterState.nothing;
  }
}
