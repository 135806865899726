import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EActionType } from 'src/app/models/Actions';
import { PopUpService } from 'src/app/singletons/popup/popup.service';

type selectable = {
  highlighted: boolean;
};

@Component({
  selector: 'app-duel-listbox',
  templateUrl: './duel-listbox.component.html',
  styleUrls: ['./duel-listbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DuelListboxComponent),
      multi: true,
    },
  ],
})
export class DuelListboxComponent implements ControlValueAccessor {
  @Input() displayKey: string;
  @Input() sortKey: string;
  @Input() selectedKey: string;
  @Input() leftListboxLabel: string;
  @Input() rightListboxLabel: string;
  @Input() leftTooltipText?: string;
  @Input() rightTooltipText?: string;
  @Input() emptyException: string;
  @Input() isTooltipDisabled = false;
  @Input() isSpecificComponent = false;
  @Input() actionType: string;
  @Output() itemsAdded = new EventEmitter<any[]>();
  @Output() itemsRemoved = new EventEmitter<any>();

  @ContentChild(TemplateRef) itemTemplate?: TemplateRef<any>;
  readonly pMaxNumber = 30;

  public data: any[] = [];

  public lftFilteredData: any[] = [];
  public rhtData: any[] = [];
  public isDisabled: boolean;

  public lftLen = 0;
  public lftCurrPage = 1;
  public lftTotalPages = 10;
  public lftPageStart = 0;
  public lftPageEnd = this.pMaxNumber;

  private isTouched = false;
  private filterStr = '';
  private lftHighlights: selectable[] = [];
  private rhtHighlights: selectable[] = [];
  private onChange: (arr: any[]) => void;
  private onTouched: () => void;

  constructor(private popupService: PopUpService) {}

  writeValue(arr: selectable[] | any[]): void {
    if (!Array.isArray(arr)) return;
    this.data = arr.sort((a, b) => {
      if (a[this.sortKey] > b[this.sortKey]) return 1;
      if (a[this.sortKey] < b[this.sortKey]) return -1;
      return 0;
    });
    this.rhtData = [];
    this.lftFilteredData = [];
    this.data.forEach((d) => {
      d.highlighted = false;
      if (d[this.selectedKey]) {
        this.rhtData.push(d);
      } else {
        this.lftFilteredData.push(d);
      }
    });
    this.filterLftBox('');
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  filterLftBox(value: string): void {
    if (value) {
      this.filterStr = value.toLocaleLowerCase();
      this.lftFilteredData = this.data.filter(
        (d) =>
          !d[this.selectedKey] &&
          d[this.displayKey].toLocaleLowerCase().includes(this.filterStr),
      );
    } else {
      this.filterStr = '';
      this.lftFilteredData = this.data.filter((d) => !d[this.selectedKey]);
    }

    this.lftLen = this.lftFilteredData.length;
    this.lftCurrPage = 1;
    this.lftPageEnd = this.pMaxNumber;
    this.lftPageStart = 0;
    this.lftTotalPages = this.getTotalPages();
  }

  addItems(): void {
    if (this.isDisabled) return;
    if (this.lftHighlights.length > 0) {
      this.lftHighlights.forEach((d) => {
        d[this.selectedKey] = true;
        d.highlighted = false;
        d[this.actionType] = EActionType.activate;
        this.removeSorted(d, this.lftFilteredData);
        this.insertSorted(d, this.rhtData);
      });
      this.lftHighlights = [];
      this.lftLen = this.lftFilteredData.length;
      this.lftTotalPages = this.getTotalPages();
      if (this.lftCurrPage > this.lftTotalPages) {
        this.prevPage();
      }
    }
    setTimeout(() => {
      this.onChange(this.data);
      this.markTouched();
      this.itemsAdded.emit(this.rhtData);
    }, 50);
  }

  removeItems(): void {
    if (this.isDisabled) return;
    const len = this.rhtHighlights.length;
    if (len > 0) {
      if (this.emptyException && len === this.rhtData.length) {
        this.popupService.show('Empty exception', this.emptyException);
      } else {
        this.rhtHighlights.forEach((d) => {
          d[this.selectedKey] = false;
          d.highlighted = false;
          d[this.actionType] = EActionType.inactivate;
          this.removeSorted(d, this.rhtData);
          if (
            this.filterStr &&
            d[this.displayKey].toLocaleLowerCase().includes(this.filterStr)
          ) {
            this.insertSorted(d, this.lftFilteredData);
          } else if (!this.filterStr) {
            this.insertSorted(d, this.lftFilteredData);
          }

          this.itemsRemoved.emit(d);
        });

        this.rhtHighlights = [];
        this.lftLen = this.lftFilteredData.length;
        this.lftTotalPages = this.getTotalPages();
      }
    }
    setTimeout(() => {
      this.onChange(this.data);
      this.markTouched();
    }, 50);
  }

  onLftHighlight(d: selectable): void {
    this.highlightHelper(d, this.lftHighlights);
  }

  onRhtHighlight(d: selectable): void {
    this.highlightHelper(d, this.rhtHighlights);
  }

  markTouched(): void {
    if (this.isTouched === true) return;
    this.isTouched = true;
    this.onTouched();
  }

  prevPage(): void {
    if (this.lftCurrPage === 1) return;
    this.lftCurrPage -= 1;
    this.lftPageStart -= this.pMaxNumber;
    this.lftPageEnd -= this.pMaxNumber;
  }

  nextPage(): void {
    if (this.lftCurrPage >= this.lftTotalPages) return;
    this.lftCurrPage += 1;
    this.lftPageStart += this.pMaxNumber;
    this.lftPageEnd += this.pMaxNumber;
  }

  shouldDisable(d: any): boolean {
    return this.isSpecificComponent && !d.active;
  }

  private highlightHelper(d: selectable, highlights: selectable[]): void {
    if (this.isDisabled) return;
    if (d.highlighted) {
      d.highlighted = false;
      const i = highlights.findIndex(
        (x) => d[this.displayKey] === x[this.displayKey],
      );
      if (i >= 0) highlights.splice(i, 1);
    } else {
      d.highlighted = true;
      highlights.push(d);
    }
    this.markTouched();
  }

  private sortedIndex(d: selectable, arr: selectable[]): number {
    let low = 0,
      high = arr.length;

    while (low < high) {
      const mid = (low + high) >>> 1;
      if (arr[mid][this.sortKey] < d[this.sortKey]) low = mid + 1;
      else high = mid;
    }
    return low;
  }

  private insertSorted(d: selectable, arr: selectable[]): void {
    arr.splice(this.sortedIndex(d, arr), 0, d);
  }

  private removeSorted(d: selectable, arr: selectable[]): void {
    arr.splice(this.sortedIndex(d, arr), 1);
  }

  private getTotalPages(): number {
    if (this.lftLen > this.pMaxNumber) {
      const tp = this.lftLen / this.pMaxNumber;
      if (Number.isInteger(tp)) {
        return (this.lftTotalPages = tp);
      }
      return (this.lftTotalPages = parseInt(`${tp}`, 10) + 1);
    } else {
      return 1;
    }
  }
}
