import { EAlterState, CrudStppr, TReviewVals } from './CrudStppr';

export interface IDimensionType {
  active: boolean;
  desc: string;
  display: string;
  key: string;
  name: string;
}

export interface IDimTypePayload {
  dimensionTypes: IDimensionType[];
}

export class DimType extends CrudStppr<DimType> {
  display = '';
  desc = '';
  name = '';

  constructor(params?: IDimensionType, i?: number) {
    super(i);
    if (params) {
      this.active = params.active;
      this.desc = params.desc;
      this.display = params.display;
      this.key = params.key;
      this.name = params.name;
    }
  }

  get displayName() {
    if (!this.key) {
      return `${this.name}`;
    }
    return `${this.key}: ${this.name}`;
  }

  get payload() {
    return {
      key: this.key,
      name: this.name,
      desc: this.desc,
      display: this.display,
      active: this.active,
    };
  }

  reset(): void {
    const uc = this.updatedClass as DimType;
    if (uc) {
      this.key = uc.key;
      this.name = uc.name;
      this.desc = uc.desc;
      this.display = uc.display;

      this.visible = uc.visible;
      this.active = uc.active;
      this.updatedClass = undefined;
    }
    this.action = EAlterState.nothing;
  }
}

export type TReviewValsDimType = TReviewVals<DimType>;
