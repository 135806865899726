<div class="crud-table">
    <mat-stepper linear #stepper>
        <!-- Step 1: CRUD table -->
        <mat-step [label]="stepOneLbl">
            <ng-template matStepContent>
                <div class="header">
                    <div class="lft">
                        <ng-content select="[header-lft]"></ng-content>
                    </div>
                    <div class="rht">
                        <app-input-keypress [ngClass]="{'disabled': !mngr.canShowAdd}" class="options-search"
                            label="Filter" [placeholder]="filterLbl" (keyPressed)="mngr.filterKeyPress($event)"
                            [clear]="mngr.clearfilterStr$">
                        </app-input-keypress>
                        <button mat-button id="add-btn" type="button" [disabled]="!mngr.canShowAdd" class="btn btn-two"
                            (click)="mngr.addUpdateClicked()">Add</button>
                    </div>
                </div>
                <app-crud-tbl [mngr]="mngr" [showActions]="true"></app-crud-tbl>
                <div class="btn-wrapper lft-rht">
                    <div class="lft">
                        <button mat-button id="nxt-btn" matStepperNext type="button" [disabled]="!mngr.hasReviewData()"
                            class="btn btn-two">Next</button>
                    </div>
                    <div class="rht">
                        <a mat-button class="btn btn-one" id="cncl-btn" [routerLink]="['/securitymodel']">Cancel</a>
                    </div>
                </div>
            </ng-template>
        </mat-step>
        <!-- Step 2: Impact -->
        <mat-step label="Data impact">
            <ng-template matStepContent>
                <app-data-impact [mngr]="mngr"></app-data-impact>
                <div class="btn-wrapper lft-rht">
                    <div class="lft">
                        <button mat-button id="prv-btn" matStepperPrevious type="button" class="btn">Back</button>
                        <button mat-button id="nxt-btn" matStepperNext type="button" class="btn btn-two">Next</button>
                    </div>
                    <div class="rht">
                        <a class="btn btn-one" [routerLink]="['/securitymodel']">Cancel</a>
                    </div>
                </div>
            </ng-template>
        </mat-step>
        <!-- Step 3: Review -->
        <mat-step label="Review">
            <ng-template matStepContent>
                <h3>Review</h3>
                <p *ngIf="!mngr.hasReviewData()">No data selections have been added/updated</p>
                <app-crud-tbl [mngr]="mngr" [showActions]="false"></app-crud-tbl>
                <div class="btn-wrapper lft-rht">
                    <div class="lft">
                        <button mat-button id="prv-btn" matStepperPrevious type="button" class="btn">Back</button>
                        <button mat-button id="btn-sub" type="button" class="btn btn-two"
                            [disabled]="!mngr.hasReviewData()" (click)="mngr.submitClicked()">Submit</button>
                    </div>
                    <div class="rht">
                        <a class="btn btn-one" [routerLink]="['/securitymodel']">Cancel</a>
                    </div>
                </div>
            </ng-template>
        </mat-step>
    </mat-stepper>
</div>
<app-crud-form-popup *ngIf="mngr.showForm" [mngr]="mngr">
    <ng-content select="[form]"></ng-content>
</app-crud-form-popup>