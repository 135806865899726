import { Component, Input, EventEmitter, Output } from '@angular/core';
import { NavItem } from '../nav-items';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss'],
})
export class NavMenuComponent {
  @Input() item: NavItem;
  @Output() notify = new EventEmitter<NavItem>();

  clicked(): void {
    this.item.isExpanded = !this.item.isExpanded;
    if (this.item.isExpanded) {
      this.notify.emit(this.item);
    }
  }

  setToFalse(): void {
    this.item.isExpanded = false;
  }
}
