import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IPopup } from './ipopup';

@Injectable({
  providedIn: 'root',
})
export class PopUpService {
  private queue: IPopup[] = [];
  private isInView = false;
  private popup = new BehaviorSubject<IPopup | null>(null);
  content = this.popup.asObservable();

  show(title: string, desc: string | string[], btnTitle = 'Ok'): void {
    this.queue.push({
      title,
      desc,
      btnTitle,
      isActionable: false,
      cb: () => {
        this.isInView = false;
        this.updateQueue();
      },
    });
    this.updateQueue();
  }

  confirm(
    title: string,
    desc: string | string[],
    btnTitle = 'Ok',
    cancelTitle = 'Cancel',
  ): Observable<boolean> {
    return new Observable((subscriber) => {
      this.queue.push({
        title,
        desc,
        btnTitle,
        cancelTitle,
        isActionable: true,
        cb: (confirmed) => {
          subscriber.next(confirmed);
          subscriber.complete();
          this.isInView = false;
          this.updateQueue();
        },
      });
      this.updateQueue();
    });
  }

  private updateQueue(): void {
    if (!this.isInView && this.queue.length > 0) {
      this.isInView = true;
      this.popup.next(this.queue.shift());
    }
  }
}
