import { DataSource } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { map } from 'rxjs/operators';
import { Observable, merge } from 'rxjs';
import { EAlterState, CrudStppr } from 'src/app/models/CrudStppr';
import { CRUDStpprMngr } from '../CRUDStpprMngr';

export class CrudTblDataSource<T> extends DataSource<CrudStppr<T>> {
  data: CrudStppr<T>[] = [];
  paginator: MatPaginator | undefined;
  sort: MatSort | undefined;

  constructor(
    private mngr: CRUDStpprMngr<T>,
    private showActions: boolean,
  ) {
    super();
    this.setFilteredData(this.mngr.tableVals);
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<CrudStppr<T>[]> {
    if (this.paginator && this.sort) {
      return merge(
        this.mngr.tableVals$,
        this.paginator.page,
        this.sort.sortChange,
      ).pipe(
        map((d) => {
          if (Array.isArray(d)) {
            this.setFilteredData(d);
          }
          return this.getPagedData(this.getSortedData([...this.data]));
        }),
      );
    } else {
      throw Error(
        'Please set the paginator and sort on the data source before connecting.',
      );
    }
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect(): void {
    //disconnect
  }

  /**
   * Paginate the data (client-side). If you're using server-side pagination,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getPagedData(data: CrudStppr<T>[]): CrudStppr<T>[] {
    if (this.paginator) {
      let startIndex = this.paginator.pageIndex * this.paginator.pageSize;
      const len = data.length;
      if (len !== 0 && startIndex >= len) {
        this.paginator.pageIndex =
          (len - (len % this.paginator.pageSize)) / this.paginator.pageSize;
        startIndex = this.paginator.pageIndex * this.paginator.pageSize;
      }
      return data.splice(startIndex, this.paginator.pageSize);
    } else {
      return data;
    }
  }

  /**
   * Sort the data (client-side). If you're using server-side sorting,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getSortedData(data: CrudStppr<T>[]): CrudStppr<T>[] {
    if (!this.sort || !this.sort.active || this.sort.direction === '') {
      return data;
    }
    return this.mngr.tabSort(data, this.sort);
  }

  private setFilteredData(data: CrudStppr<T>[]): void {
    if (this.showActions) {
      this.data = data;
    } else {
      this.data = data.filter((d) => {
        return d.action !== EAlterState.nothing;
      });
    }
  }
}
