import { EAlterState, CrudStppr, TReviewVals } from './CrudStppr';

export interface IDataSel {
  key: string;
  value: string;
  groupKey: string;
  disclaimer: string;
  dsg: IDSGPayload;
  active: boolean;
  visible: boolean;
}

export interface IDataSelPayload {
  dataSelections: IDataSel[];
}

export class DataSel extends CrudStppr<DataSel> {
  value = '';
  disclaimer = '';
  dsg: DSG;

  private _groupKey = '';

  constructor(params?: IDataSel, i?: number) {
    super(i);
    if (params) {
      this.active = params.active;
      this.visible = params.visible;
      this.key = params.key || '';
      this.value = params.value || '';
      this._groupKey = params.groupKey || '';
      this.disclaimer = params.disclaimer || '';
      this.dsg = params.dsg ? new DSG(params.dsg) : undefined;
    }
  }

  get displayName() {
    if (!this.key) {
      return `${this.value}`;
    }
    return `${this.key}: ${this.value}`;
  }

  get displayGroup(): string {
    return this.dsg?.display || '';
  }

  get groupKey(): string {
    return this.dsg?.key || this._groupKey;
  }

  get payload() {
    return {
      key: this.key,
      value: this.value,
      groupKey: this.groupKey,
      disclaimer: this.disclaimer,
      visible: this.visible,
      active: this.active,
      dsg: this.dsg?.getPayload(),
    };
  }

  reset(): void {
    const uc = this.updatedClass as DataSel;
    if (uc) {
      this.key = uc.key;
      this.value = uc.value;
      this._groupKey = uc.groupKey;
      this.disclaimer = uc.disclaimer;
      this.visible = uc.visible;
      this.active = uc.active;
      this.dsg = uc.dsg;
      this.updatedClass = undefined;
    }
    this.action = EAlterState.nothing;
  }

  clearDsg(): void {
    this.dsg = undefined;
  }
}

export interface IDSG {
  key: string;
  name: string;
}

/** Data Sel Group payload */
export interface IDSGPayload {
  key: string;
  name: string;
  selectorDisplayName?: string;
  selectorDescription?: string;
  selectorTitle?: string;
  selectorType?: string;
  parent?: string;
  order?: number;
  active: boolean;
  visible: boolean;
}

/** Data sel group */
export class DSG {
  key = '';
  name = '';
  active = true;
  visible = true;

  constructor(data?: IDSGPayload) {
    if (data) {
      this.key = data.key;
      this.name = data.name;
      this.active = data.active;
      this.visible = data.visible;
    }
  }

  get display(): string {
    if (!this.key || !this.name) {
      return this.key || this.name || '';
    }
    return `${this.key}: ${this.name}`;
  }

  getPayload(): IDSG {
    return {
      key: this.key,
      name: this.name,
    };
  }
}

export interface IDataSelGrpPayload {
  dataSelectionGroups: IDSGPayload[];
}

export type TReviewValsDataSel = TReviewVals<DataSel>;
