import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { ReleaseApiService } from '../release-api.service';
import { UpdateEventTblDataSource } from './update-event-tbl-datasource';
import { UpdateEvents } from 'src/app/models/Release';
import { ReleaseComponent } from '../release.component';
import { ReleaseService } from '../release.service';
import { AuthService } from 'src/app/auth/auth.service';
import { catchError, of, Subject, switchMap } from 'rxjs';
import { PopUpService } from 'src/app/singletons/popup/popup.service';

export enum EActions {
  create = 'create',
  update = 'update',
  delete = 'delete',
}

@Component({
  selector: 'app-update-event-form',
  templateUrl: './update-event-form.component.html',
  styleUrls: ['./update-event-form.component.scss'],
})
export class UpdateEventFormComponent implements OnInit {
  @Input() title = '';
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<UpdateEvents>;
  clearSubject: Subject<boolean> = new Subject<boolean>();

  dataSource: UpdateEventTblDataSource;
  originalEventsData: UpdateEvents[] = []; // Initialize updateEventsData
  searchInputDisabled = false; // Initially set to false
  displayedColumns = ['event', 'createdBy', 'updatedBy', 'actions'];
  isEditing = false;
  editingRow: UpdateEvents | null = null;
  originalEventValueMap: Map<UpdateEvents, string> = new Map<
    UpdateEvents,
    string
  >();
  showErrorMessage = false;
  errorMessage = '';
  ac: AbstractControl;
  popupTitle = 'Delete Event';
  popupDescription = 'Are you sure you want to delete this event?';
  constructor(
    public relCom: ReleaseComponent,
    public apiService: ReleaseApiService,
    public relSer: ReleaseService,
    private authService: AuthService,
    private popup: PopUpService,
  ) {}

  ngOnInit(): void {
    this.fetchUpdateEvents();
    this.relSer.acEventName.reset();
  }
  fetchUpdateEvents(): void {
    this.apiService.getCalEvents().subscribe((data) => {
      this.originalEventsData = data;
      this.searchInputDisabled = !data || data.length === 0; // Set to true if no data is available
      this.setupDataSource(this.originalEventsData);
    });
  }
  setupDataSource(data: UpdateEvents[]): void {
    this.dataSource = new UpdateEventTblDataSource(data);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }
  toggleEditMode(row: UpdateEvents): void {
    row.apiError = '';
    // add form control
    this.relSer.handleFormControl(EActions.create, row);

    if (!row.editing) {
      // Store the original value of the event
      this.originalEventValueMap.set(row, row.event);
    }
    row.editing = !row.editing;
  }

  onUpdateValue(row: any, event): void {
    this.relSer.updateControlValue(row, event.target.value);
  }

  addEvent(): void {
    const newEvent: UpdateEvents = {
      key: '',
      event: this.relSer.acEventName.value,
      createdBy: this.authService.currUser.email,
      updatedBy: this.authService.currUser.email,
      createdByTime: new Date().toISOString(),
      updatedByTime: new Date().toISOString(),
    };

    const callback = () => {
      this.fetchUpdateEvents();
      this.relSer.acEventName.reset();
      this.clearSubject.next(true);
    };

    this.apiService
      .updateEvents(newEvent, EActions.create, callback)
      .subscribe({
        next: (result) => {
          if (!result.message.includes('success')) {
            this.ac = this.relSer.acEventName;
            this.ac.markAsTouched();
            this.ac.markAsDirty();
            this.ac.setErrors({
              apiErr: Array.isArray(result.message)
                ? result.message[0]
                : result.message,
            });
          } else {
            callback();
          }
        },
        error: (err) => {
          this.ac = this.relSer.acEventName;
          this.ac.markAsTouched();
          this.ac.markAsDirty();
          this.ac.setErrors({
            apiErr: Array.isArray(err.error.message)
              ? err.error.message[0]
              : err.error.message,
          });
        },
      });
  }
  saveRowChange(row: UpdateEvents): void {
    const newEvent: UpdateEvents = {
      key: row.key,
      event: this.relSer.getAbstractControl(row.key).value,
      createdBy: row.createdBy,
      updatedBy: this.authService.currUser.email,
      createdByTime: row.createdByTime,
      updatedByTime: new Date().toISOString(),
    };

    const callback = () => {
      row.editing = false;
      const index = this.originalEventsData.findIndex(
        (event) => event.key === row.key,
      );
      if (index !== -1) {
        this.originalEventsData[index] = newEvent;
      }
      this.setupDataSource(this.originalEventsData);
      this.clearSubject.next(true);
    };

    this.apiService
      .updateEvents(newEvent, EActions.update, callback)
      .subscribe({
        next: (result) => {
          if (!result.message.includes('success')) {
            row.editing = true;
            row.apiError = Array.isArray(result.message)
              ? result.message[0]
              : result.message;
          } else {
            callback();
          }
        },
        error: (err) => {
          row.editing = true;
          row.apiError = Array.isArray(err.error.message)
            ? err.error.message[0]
            : err.error.message;
          return;
        },
      });
  }
  clearError(row: UpdateEvents): void {
    row.apiError = '';
  }
  cancelEdit(row: UpdateEvents): void {
    // Reset the entire row to its original state
    const originalValue = this.originalEventValueMap.get(row);
    if (originalValue !== undefined) {
      row.event = originalValue;
    }
    row.editing = false;
    // remove form control
    this.relSer.handleFormControl(EActions.delete, row);
  }

  deleteRow(row: UpdateEvents): void {
    const callback = () => {
      const index = this.originalEventsData.findIndex(
        (event) => event.key === row.key,
      );
      if (index !== -1) {
        this.originalEventsData.splice(index, 1);
      }
      this.setupDataSource(this.originalEventsData);
      this.clearSubject.next(true);
    }
    this.popup
      .confirm(this.popupTitle, this.popupDescription)
      .pipe(
        switchMap((proceed) => {
          if (!proceed) return of(null);
          return this.apiService.deleteEvent(row.key, EActions.delete, callback);
        }),
        catchError((err) => {
          row.apiErrorDelete = err.error.message;
          return of(null);
        }),
      )
      .subscribe((result) => {
        if (result && result.status !== 200) {
          row.apiErrorDelete = result.message;
        }
      });
  }

  isValueChanged(row: UpdateEvents): boolean {
    const currentValue = this.relSer.getAbstractControl(row.key).value;
    return currentValue !== row.event;
  }
  // Method to check if the form control for a specific row is valid
  isFormControlValid(row: UpdateEvents): boolean {
    const control = this.relSer.getAbstractControl(row.key);
    return control && control.valid && !row.apiError;
  }
  applyFilter(filterValue: string) {
    const filteredData = this.getFilteredData(filterValue);
    this.setupDataSource(filteredData);
    // Reset paginator after filtering
    if (this.paginator) {
      this.paginator.firstPage();
    }
  }

  getFilteredData(filterValue: string): UpdateEvents[] {
    const data = this.originalEventsData;
    const lowerCaseFilter = filterValue.toLowerCase();
    return data.filter((row) => {
      // Check specific fields for the filter value (case-insensitive)
      const eventMatches =
        row.event?.toString().toLowerCase().includes(lowerCaseFilter) || false;
      const createdByMatches =
        row.createdBy?.toString().toLowerCase().includes(lowerCaseFilter) ||
        false;
      const updatedByMatches =
        row.updatedBy?.toString().toLowerCase().includes(lowerCaseFilter) ||
        false;

      return eventMatches || createdByMatches || updatedByMatches;
    });
  }
}
