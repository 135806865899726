import { CdkStepper } from '@angular/cdk/stepper';
import { Component } from '@angular/core';

@Component({
  selector: 'app-cstepper',
  templateUrl: './cstepper.component.html',
  styleUrls: ['./cstepper.component.scss'],
  providers: [{ provide: CdkStepper, useExisting: CstepperComponent }],
})
export class CstepperComponent extends CdkStepper {}
