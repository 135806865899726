<div class="popup" *ngIf="mngr?.showForm">
  <div class="back-drop">&nbsp;</div>
  <div class="modal-wrapper">
    <div class="modal">
      <form [formGroup]="mngr.fg" class="form-group">
        <ng-content></ng-content>
        <section class="columned">
          <!-- Required -->
          <div class="form-field Required-field" *ngIf="mngr.showRequiredFld">
            <div class="stepper">
              <mat-slide-toggle labelPosition="before" formControlName="required">
                <span>Required</span>
              </mat-slide-toggle>
            </div>
          </div>
          <!-- Active -->
          <div class="form-field active-field">
            <div class="stepper">
              <mat-slide-toggle labelPosition="before" formControlName="active">
                <span>Active</span>
              </mat-slide-toggle>
            </div>
          </div>
          <!-- Visible -->
          <div class="form-field visible-field" *ngIf="mngr.showVisibleFld">
            <div class="stepper">
              <mat-slide-toggle labelPosition="before" formControlName="visible">
                <span>Visible</span>
              </mat-slide-toggle>
            </div>
          </div>
        </section>
        <div class="btn-wrapper">
          <button mat-button id="btn-acChcl" type="button" [disabled]="!mngr.canUpdateAdd()" class="btn btn-two"
            (click)="mngr.acceptChangesClicked()">{{ mngr.isAddBtnClicked? 'Add' :
            'Update'}}</button>
          <button mat-button id="btn-cncl" type="button" class="btn btn-one" (click)="mngr.hideForm()">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>