export enum EActionType {
  activate = 'Activate',
  inactivate = 'Inactivate',
}

/** returned from API call: businessroles/{brRoleKey}/filterselections`; */
export interface IBRFilterSelectionsPayload {
  businessRoleKey?: string;
  businessRoleName?: string;
  active: boolean;
  filterSelections?: IFilterSelection[];
  availableFilterSelections: IFilterSelection[];
}

export interface IFilterSelection {
  businessRoleKey: string;
  businessRoleName: string;
  filterSelectKey?: string;
  filterSelectValue?: string;
  active: boolean;
  selected?: boolean;
}

export interface ICombinedFilterSelections {
  filterSelectKey?: string;
  selected?: boolean;
  actionType?: string;
  originalMapped?: string;
}

interface IPostFSKeyPayload {
  filterSelectKey: string[];
}

export interface IBRFilterSelectionPostPayload {
  businessRoleKey: string;
  fs?: IPostFSKeyPayload;
  combinedFilterSelections?: IFilterSelection[];
  selected?: boolean;
  changed?: boolean;
  filterSelectKey?: string;
  active: boolean;
}

export interface IRoleFilterByIgaAppId {
  igaAppId: string;
  roleFilterKeys: IRoleFilterKeyByIgaAppId[];
}

export interface IRoleFilterKeyByIgaAppId {
  key: string;
}

export class FilterSelection {
  businessRoleKey = '';
  businessRoleName = '';
  filterSelectKey = '';
  filterSelectValue = '';
  active = false;
  selected = false;
  actionType = '';
  originalSelected = false;
  originalMapped = '';

  constructor(
    params: IFilterSelection,
    isSelected: boolean,
    getPayload: IBRFilterSelectionsPayload,
    originalMapped: string,
  ) {
    if (params && getPayload) {
      this.filterSelectKey = params.filterSelectKey;
      this.filterSelectValue = params.filterSelectValue;
      this.active = params.active;
      this.selected = isSelected;
      this.originalSelected = this.selected;
      this.businessRoleKey = getPayload.businessRoleKey;
      this.businessRoleName = getPayload.businessRoleName;
      this.originalMapped = originalMapped;
    }
  }

  get displayFsKeyAndName(): string {
    return `${this.filterSelectKey}: ${this.filterSelectValue}`;
  }

  get displayBrKeyAndName(): string {
    return `${this.businessRoleKey}: ${this.businessRoleName}`;
  }
}

export class BRFilterSelections {
  businessRoleKey = '';
  businessRoleName = '';
  active = false;
  combinedFilterSelections: FilterSelection[] = [];

  constructor(param: IBRFilterSelectionsPayload) {
    if (param) {
      this.businessRoleKey = param.businessRoleKey;
      this.businessRoleName = param.businessRoleName;
      this.active = param.active;
      this.combinedFilterSelections = combineFilterSelections(param);
    }
  }
}

export class BRFilterSelectionsPostPayload {
  businessRoleKey = '';
  combinedFilterSelections: ICombinedFilterSelections[];

  constructor(params: IBRFilterSelectionPostPayload) {
    if (params) {
      this.businessRoleKey = params.businessRoleKey;
      this.combinedFilterSelections = params.combinedFilterSelections;
    }
  }

  /** payload for Post API : businessroles/businessrolefilterselections */
  getPayload(): IBRFilterSelectionPostPayload[] {
    const payloads: IBRFilterSelectionPostPayload[] = [];

    this.combinedFilterSelections.forEach((x) => {
      if (
        x.selected &&
        x.actionType === EActionType.activate &&
        x.originalMapped !== EActionType.activate
      ) {
        const obj: IBRFilterSelectionPostPayload = {
          businessRoleKey: this.businessRoleKey,
          filterSelectKey: x.filterSelectKey,
          active: true,
        };
        payloads.push(obj);
      }

      if (
        !x.selected &&
        x.actionType === EActionType.inactivate &&
        x.originalMapped !== EActionType.inactivate
      ) {
        const obj: IBRFilterSelectionPostPayload = {
          businessRoleKey: this.businessRoleKey,
          filterSelectKey: x.filterSelectKey,
          active: false,
        };
        payloads.push(obj);
      }
    });

    return payloads;
  }
}

export class RoleFilterByIgaAppId {
  igaAppId = '';
  roleFilterKeys: IRoleFilterKeyByIgaAppId[] = [];

  constructor(param: IRoleFilterByIgaAppId) {
    if (param) {
      this.igaAppId = param.igaAppId;
      this.roleFilterKeys = param.roleFilterKeys;
    }
  }
}

export class RoleFilterKey {
  key = '';
}

function combineFilterSelections(
  params: IBRFilterSelectionsPayload,
): FilterSelection[] {
  const selected = (params.filterSelections ??= []);
  const unselected = (params.availableFilterSelections ??= []);

  const arr = selected.map(
    (d) => new FilterSelection(d, true, params, EActionType.activate),
  );
  return arr.concat(
    unselected.map(
      (d) => new FilterSelection(d, false, params, EActionType.inactivate),
    ),
  );
}
