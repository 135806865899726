import { EAlterState } from './CrudStppr';

export enum EActionType {
  activate = 'Activate',
  inactivate = 'Inactivate',
}

export interface IAOIAccPkgPayload {
  apKey?: string;
  apName?: string;
  active: boolean;
  mappedAreaOfInterests?: IAreaOfInterests[];
  availableAreaOfInterests: IAreaOfInterests[];
}

export interface IAreaOfInterests {
  apKey: string;
  apName: string;
  aoiKey?: string;
  aoiName?: string;
  active: boolean;
  selected?: boolean;
  actionType?: string;
  originalSelected?: boolean;
}

export interface ICombinedAccessPackages {
  apKey?: string;
  aoiKey?: string;
  selected?: boolean;
  actionType?: string;
  originalSelected?: boolean;
}

export interface IAOIAccessPackagePostPayload {
  aoiKey: string;
  apKey: string;
  active: boolean;
  combinedAreaOfInterests?: IAreaOfInterests[];
}

export interface Ifgs {
  ackPkgAoiFG?: any;
}

export class AreaOfInterests {
  apKey = '';
  apName = '';
  aoiKey = '';
  aoiName = '';
  active = false;
  selected = false;
  actionType = '';
  originalSelected = false;
  originalMapped = '';

  action: EAlterState;
  isDisabled = false;
  updatedState: AreaOfInterests;

  constructor(
    params: IAreaOfInterests,
    isSelected: boolean,
    getPayload: IAOIAccPkgPayload,
    originalMapped: string,
  ) {
    if (params && getPayload) {
      this.aoiKey = params.aoiKey;
      this.aoiName = params.aoiName;
      this.active = params.active;
      this.selected = isSelected;
      this.originalSelected = this.selected;
      this.apKey = getPayload.apKey;
      this.apName = getPayload.apName;
      this.originalMapped = originalMapped;
    }
  }

  get displayaoiKeyAndName(): string {
    return `${this.aoiKey}: ${this.aoiName}`;
  }
  get displayAccPkgKeyAndName(): string {
    return `${this.apKey}: ${this.apName}`;
  }
}

export class AOIAccessPackages {
  apKey = '';
  apName = '';
  active = false;
  combinedAreaOfInterests: AreaOfInterests[] = [];

  constructor(param: IAOIAccPkgPayload) {
    if (param) {
      this.apKey = param.apKey;
      this.apName = param.apName;
      this.active = true;
      this.combinedAreaOfInterests = combineAreaOfInterests(param);
    }
  }
}

export class AOIAccessPackagePostPayload {
  aoiKey = '';
  combinedAreaOfInterests: ICombinedAccessPackages[];

  constructor(params: IAOIAccessPackagePostPayload) {
    if (params) {
      this.aoiKey = params.aoiKey;
      this.combinedAreaOfInterests = params.combinedAreaOfInterests;
    }
  }

  /** payload for Post API : AreaOfInterests/AccessPackage */
  postPayloadAoiMapping(): IAOIAccessPackagePostPayload[] {
    const payloads: IAOIAccessPackagePostPayload[] = [];

    this.combinedAreaOfInterests.forEach((x) => {
      /** Gets the newly mapped AOI for post payload so that will be will saved to DB */
      if (x.selected && x.actionType === EActionType.activate) {
        const obj: IAOIAccessPackagePostPayload = {
          aoiKey: x.aoiKey,
          apKey: x.apKey,
          active: true,
        };
        payloads.push(obj);
      }

      /** Gets the actively mapped AOI for post payload so that it will still be mapped */
      if (x.originalSelected && x.actionType === EActionType.inactivate) {
        const obj: IAOIAccessPackagePostPayload = {
          aoiKey: x.aoiKey,
          apKey: x.apKey,
          active: false,
        };
        payloads.push(obj);
      }
    });

    return payloads;
  }
}

function combineAreaOfInterests(params: IAOIAccPkgPayload): AreaOfInterests[] {
  const selected = (params.mappedAreaOfInterests ??= []);
  const unselected = (params.availableAreaOfInterests ??= []);

  const arr = selected.map(
    (d) => new AreaOfInterests(d, true, params, EActionType.activate),
  );
  return arr.concat(
    unselected.map(
      (d) => new AreaOfInterests(d, false, params, EActionType.inactivate),
    ),
  );
}
