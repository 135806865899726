import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PopUpComponent } from './popup/popup.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { ToasterComponent } from './toaster/toaster.component';

@NgModule({
  declarations: [PopUpComponent, SpinnerComponent, ToasterComponent],
  imports: [CommonModule],
  exports: [PopUpComponent, SpinnerComponent, ToasterComponent],
})
export class SingletonsModule {}
