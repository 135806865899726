import { Injectable } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { EActions } from './update-event-form/update-event-form.component';

@Injectable({
  providedIn: 'root',
})
export class ReleaseService {
  showForm = false;
  isNew = false;
  fg: FormGroup;
  inlineEditFormGroup: FormGroup; // Declare inlineEditForm variable
  fb = new FormBuilder();
  selectedRow: any;
  constructor(fb: FormBuilder) {
    this.fg = fb.group({
      eventName: ['', [Validators.required, Validators.maxLength(100)]],
    });
    this.inlineEditFormGroup = fb.group({});
  }

  handleFormControl(action: string, row: any): void {
    this.selectedRow = row;
    const formControlName = `editEventName_${row.key}`;

    if (action === EActions.create) {
      this.inlineEditFormGroup.removeControl(formControlName);
      this.inlineEditFormGroup.addControl(
        formControlName,
        this.fb.control(row.event, [
          Validators.required,
          Validators.maxLength(100),
        ]),
      );
    } else {
      this.inlineEditFormGroup.removeControl(formControlName);
    }
  }

  getFormControlName(key: string): any {
    const formControlName = `editEventName_${key}`;
    return formControlName;
  }

  getAbstractControl(key: string): AbstractControl {
    const formControlName = `editEventName_${key}`;
    let control = this.inlineEditFormGroup.get(formControlName);

    if (!control) {
      control = this.fb.control('', [
        Validators.required,
        Validators.maxLength(100),
      ]);
      this.inlineEditFormGroup.addControl(formControlName, control);
    }

    return control;
  }

  updateControlValue(row: any, newValue: string): void {
    const formControlName = `editEventName_${row.key}`;
    this.inlineEditFormGroup.controls[formControlName].setValue(newValue);
    this.inlineEditFormGroup.controls[formControlName].markAsTouched();
  }

  /* --------------------------------------- getters --------------------------------------- */
  /** AbstractControl value is a string */
  get acEventName(): AbstractControl {
    return this.fg.get('eventName');
  }
}
