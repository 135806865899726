import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatChipsModule } from '@angular/material/chips';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { MsmComponent } from './msm.component';
import { IvbComponent } from './ivb/ivb.component';
import { TrimWithEllipsisPipe } from '../shared/pipes/trim-with-ellipsis.pipe';

@NgModule({
  declarations: [MsmComponent, TrimWithEllipsisPipe, IvbComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
    MatIconModule,
    MatCheckboxModule,
    MatMenuModule,
    MatRadioModule,
    MatChipsModule,
    SharedModule,
  ],
  exports: [MsmComponent],
})
export class MsmModule {}
