import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { MappingReviewTableDataSource } from './mapping-review-datasource';
import { AbstractControl } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { MapFSFGService } from '../br-flds/map-fs/map-fs-fg.service';
@Component({
  selector: 'app-mapping-review-table',
  templateUrl: './mapping-review-table.component.html',
  styleUrls: ['./mapping-review-table.component.scss'],
})
export class MappingReviewTableComponent<T>
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<T>;
  @Input() dispRowKeys: string[] = [];
  @Input() dispColumns: string[] = [];
  @Input() acDataBRFS: AbstractControl;
  @Input() filterKeyOriginal = '';
  @Input() filterKeyCurrent = '';
  @Input() originalMapped = '';
  @Input() actionType = '';

  dataSource: MappingReviewTableDataSource<T>;

  private behSub: BehaviorSubject<any[]>;
  private subs: Subscription[] = [];

  constructor(
    private cdr: ChangeDetectorRef,
    private mapFSFGService: MapFSFGService,
  ) {}

  ngOnInit(): void {
    if (this.acDataBRFS) {
      this.behSub = new BehaviorSubject(this.acDataBRFS.value);
      this.dataSource = new MappingReviewTableDataSource(
        this.behSub.asObservable(),
        this.filterKeyOriginal,
        this.filterKeyCurrent,
        this.originalMapped,
        this.actionType,
      );

      this.subs.push(
        this.acDataBRFS.valueChanges.subscribe((d) => {
          this.mapFSFGService.checkBrTableProcess();
          this.dataSource.updateValidBrKeys(this.mapFSFGService.activatedFs);
          this.behSub.next(d);
        }),
      );
      this.subs.push(
        this.mapFSFGService.removeActivatedFs$.subscribe((value) => {
          if (value === true) {
            this.acDataBRFS.updateValueAndValidity();
          }
        }),
      );
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
      this.dataSource.updateValidBrKeys(this.mapFSFGService.activatedFs);
      this.table.dataSource = this.dataSource;
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
