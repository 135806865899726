<div class="mat-elevation-z8">
    <table mat-table class="full-width-table" matSort aria-label="alter rows">
        <!-- loop table display column keys -->
        <ng-container *ngFor="let key of dispColKeys; let i = index" [matColumnDef]="key">
            key:{{key}}
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ dispColHdrs[i] }}</th>
            <td mat-cell *matCellDef="let row">
                <p [ngClass]="{'parent': dispColHdrs[i] === 'Parents'}">{{(!row[key] && row[key] !== false) ?
                    '(Empty)' : row[key]}}</p>
                <p [ngClass]="{'parent': dispColHdrs[i] === 'Parents'}" class="sbld-italic"
                    *ngIf="row.updatedClass !== undefined && row.updatedClass[key] !== row[key]">
                    {{row.updatedClass[key] || '(Empty)'}}</p>
            </td>
        </ng-container>

        <!-- active Column -->
        <ng-container matColumnDef="active">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Active
                <div *ngIf="showActions">
                    <mat-icon class="filterIcon" [ngClass]="{'disabled': !mngr.canShowAdd}" (click)="mngr.handleCheckboxState('active'); $event.stopPropagation()" [matMenuTriggerFor]="menu" style="cursor: pointer;" aria-label="Example filter icon" fontIcon="filter_alt"></mat-icon>
                    <mat-menu #menu="matMenu">
                        <ng-container *ngFor="let option of mngr.filterOptions">
                            <button mat-menu-item>
                                <mat-checkbox color="primary" [checked]="mngr.filterValues.active[option.val]" (click)="$event.stopPropagation()"
                                            (change)="mngr.onFilterChange('active', option.val, $event.checked)">
                                    {{ option.name }}
                                </mat-checkbox>
                            </button>
                        </ng-container>
                        <div class="btn-wrapper">
                            <button mat-button class="btn btn-two" (click)="mngr.applyFilter('active')" [disabled]="!mngr.anyOptionChecked('active')">Apply</button>
                            <button mat-button class="btn btn-one" (click)="mngr.clearFilter('active')">Clear</button>
                        </div>
                    </mat-menu>
                </div>
            </th>
            <td mat-cell *matCellDef="let row">
                <p>{{ row.activeDisplay }} </p>
                <p class="sbld-italic" *ngIf="row.updatedClass !== undefined && row.updatedClass.activeDisplay !== row.activeDisplay">{{row.updatedClass.activeDisplay}}</p>
            </td>
        </ng-container>

         <!-- visible Column -->
         <ng-container matColumnDef="visible" *ngIf="mngr.showVisibleFld">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Visible
                <div *ngIf="showActions">
                    <mat-icon class="filterIcon" [ngClass]="{'disabled': !mngr.canShowAdd}" (click)="mngr.handleCheckboxState('visible'); $event.stopPropagation()" [matMenuTriggerFor]="menu1" style="cursor: pointer;" aria-label="Example filter icon" fontIcon="filter_alt"></mat-icon>
                    <mat-menu #menu1="matMenu">
                        <ng-container *ngFor="let option of mngr.filterOptions">
                            <button mat-menu-item>
                                <mat-checkbox color="primary" [checked]="mngr.filterValues.visible[option.val]" (click)="$event.stopPropagation()"
                                            (change)="mngr.onFilterChange('visible', option.val, $event.checked)">
                                    {{ option.name }}
                                </mat-checkbox>
                            </button>
                        </ng-container>
                        <div class="btn-wrapper">
                            <button mat-button class="btn btn-two" (click)="mngr.applyFilter('visible')" [disabled]="!mngr.anyOptionChecked('visible')">Apply</button>
                            <button mat-button class="btn btn-one" (click)="mngr.clearFilter('visible')">Clear</button>
                        </div>
                    </mat-menu>
                </div>
            </th>
            <td mat-cell *matCellDef="let row">
                <p>{{ row.visibleDisplay }} </p>
                <p class="sbld-italic" *ngIf="row.updatedClass !== undefined && row.updatedClass.visibleDisplay !== row.visibleDisplay">{{row.updatedClass.visibleDisplay}}</p>
            </td>
        </ng-container>

        <!-- action Column -->
        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Action</th>
            <td mat-cell *matCellDef="let row">
                <div class="btn-wrap">
                    <div mat-button (click)="mngr.addUpdateClicked(row)" class="btn btn-two btn-updt"
                        *ngIf="row.action !== 'delete'">Update
                    </div>
                    <div mat-button *ngIf="row.action !== 'nothing'" (click)="mngr.deleteRevertClicked(row)"
                        class="btn btn-del" [class.btn-one]="row.action !== 'update' && row.action !== 'delete'">
                        {{ row.action === 'new' ? 'Remove' : (row.action === 'update' ? 'Revert'
                        : 'Delete') }}
                    </div>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="row.action"></tr>
    </table>

    <mat-paginator #paginator [length]="dataSource?.data?.length" [pageIndex]="0" [pageSize]="10"
        [showFirstLastButtons]="true" aria-label="Select page">
    </mat-paginator>
</div>
