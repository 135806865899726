import { Component, OnInit } from '@angular/core';
import { MsmService } from '../../msm/msm.service';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-app-banner',
  templateUrl: './app-banner.component.html',
  styleUrls: ['./app-banner.component.scss'],
})
export class AppBannerComponent implements OnInit {
  currentRoute: string = ''; // To store the current route
  private readonly release_route = '/release';
  private readonly ivb_route = '/ivb';
  constructor(
    private msmFgService: MsmService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.currentRoute = this.router.url;
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = this.router.url;
        if (
          this.router.url === this.release_route ||
          this.router.url === this.ivb_route
        ) {
          this.msmFgService.resetBanner();
        }
      }
    });
  }

  get appDisplayName(): string {
    return this.msmFgService.appDisplayName;
  }
  get actionName(): string {
    if (this.msmFgService.msmActionName) {
      return this.msmFgService.msmActionName;
    }

    return (
      {
        [this.release_route]: this.releaseName,
        [this.ivb_route]: this.ivbName,
      }[this.currentRoute] ?? ''
    );
  }

  get brName(): string {
    return this.msmFgService.brName;
  }

  get releaseName(): string {
    return `Release Schedule`;
  }

  get ivbName(): string {
    return `Intake Validation Box`;
  }
}
