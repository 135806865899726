<div class="popup" *ngIf="visible">
  <div class="back-drop">&nbsp;</div>
  <div class="modal-wrapper">
    <div class="modal">
      <div class="content">
        <h3>{{title}}</h3>
        <p *ngIf="isDescString(); else descArr">{{desc}}</p>
        <ng-template #descArr>
          <p *ngFor="let d of desc">{{d}}</p>
        </ng-template>
      </div>
      <div class="btn-wrapper">
        <ng-container *ngIf="isActionable; else notActionable">
          <div class="btn" (click)="closeClicked()">{{cancelTitle}}</div>
          <div class="btn btn-two" (click)="actionClicked()">{{btnTitle}}</div>
        </ng-container>
        <ng-template #notActionable>
          <div class="btn" (click)="closeClicked()">{{btnTitle}}</div>
        </ng-template>
      </div>
    </div>
  </div>
</div>