import { Injectable } from '@angular/core';
import {
  LDClient,
  LDSingleKindContext,
  initialize,
} from 'launchdarkly-js-client-sdk';
import { environment } from 'src/environments';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { EDarklyflags, msmActions } from '../msm/actions-objective';

const kindContext: LDSingleKindContext = {
  kind: 'user',
  anonymous: true,
};

@Injectable({
  providedIn: 'root',
})
export class LaunchdarklyService {
  private flagsChangedSub: Subject<EDarklyflags>;
  private ldInstance: LDClient;
  private flagBehSubs: { [key: string]: BehaviorSubject<boolean> } = {};
  private requestedFlags$: { [key: string]: Observable<boolean> } = {};

  flagsChanged$: Observable<EDarklyflags>;

  constructor() {
    for (const f in EDarklyflags) {
      this.flagBehSubs[EDarklyflags[f]] = new BehaviorSubject(false);
    }
  }

  onInit(isAdmin = false): Observable<void> {
    this.flagsChangedSub = new Subject<EDarklyflags>();
    this.flagsChanged$ = this.flagsChangedSub.asObservable();
    return new Observable((subscribe) => {
      if (this.ldInstance) throw new Error('flags already set');
      this.ldInstance = darklyHelper.getInstance(isAdmin);

      this.ldInstance.on('ready', () => {
        const flags = this.ldInstance.allFlags();
        for (const key in flags) {
          this.changeMsmActions(key, flags[key]);
          if (this.flagBehSubs[key]) {
            this.flagBehSubs[key].next(flags[key]);
          }
        }
        subscribe.complete();
      });

      // set change event
      for (const f in EDarklyflags) {
        this.ldInstance.on(`change:${EDarklyflags[f]}`, (curr) => {
          this.flagsChangedSub.next(EDarklyflags[f]);
          this.changeMsmActions(EDarklyflags[f], curr);
          this.flagBehSubs[EDarklyflags[f]].next(curr);
        });
      }
    });
  }

  onDestroy(): void {
    for (const f in EDarklyflags) {
      this.flagBehSubs[EDarklyflags[f]].complete();
    }
    this.flagBehSubs = undefined;
    this.requestedFlags$ = undefined;
    this.flagsChangedSub.complete();
    this.flagsChangedSub = undefined;
    this.flagsChanged$ = undefined;
  }

  getFlag$(key: EDarklyflags): Observable<boolean> {
    if (!this.requestedFlags$[key]) {
      this.requestedFlags$[key] = this.flagBehSubs[key].asObservable();
    }
    return this.requestedFlags$[key];
  }

  private changeMsmActions(flag: string, visible: boolean): void {
    for (const key in msmActions) {
      if (msmActions[key].darklyFlag === flag) {
        msmActions[key].visible = visible;
      }
    }
  }
}

class DarklyHelper {
  getInstance(isAdmin: boolean): LDClient {
    kindContext.admin = isAdmin;
    return initialize(environment.ldClientId, kindContext);
  }
}
/**
 * Returns an instance of the launch darkly client.
 *
 * This is an export for testing purposes
 */
export const darklyHelper = new DarklyHelper();
