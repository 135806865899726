import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, tap, forkJoin, mergeMap, catchError } from 'rxjs';
import {
  IAccPkgMappedBrDim,
  AccPkg,
  IAccPkgPayload,
  IAccPkgMappedBrDimPayload,
  IAccPkgValApprovers,
  IAccPkgValBase,
  IAccPkgValMbrDim,
  IAccPkgMBRdimPayload,
  IAccPkgMappedDataSel,
  IAccPkgMappedDataSelPayload,
  IAccPkgValMdataSel,
  IAccPkgMDataSelPayload,
  IAccPkgMappedDataSelAvailPayload,
  AccPkgMappedDataSelAvail,
} from 'src/app/models/AccPkg';
import { API } from 'src/app/util/API';
import {
  AsmAccPkgPostResponse,
  AsmPostResponse,
  IFGvalidation,
} from 'src/app/models/AsmPostResponse';
import {
  IAccPkgMappedBrPayload,
  IBrSml,
  IBrSmlUpdate,
  IBrSmlVal,
} from 'src/app/models/BrSml';
import { PopUpService } from 'src/app/singletons/popup/popup.service';
import { SpinnerService } from 'src/app/singletons/spinner/spinner.service';
import { ToasterService } from 'src/app/singletons/toaster/toaster.service';
import {
  ISignedEmails,
  ISignedUrlBody,
  awsHeader,
} from 'src/app/models/ISignedUrl';
import {
  AOIAccessPackages,
  IAOIAccPkgPayload,
  IAOIAccessPackagePostPayload,
} from 'src/app/models/AreaOfInterestAccessPackageMapping';
import { LaunchdarklyService } from 'src/app/singletons/launchdarkly.service';

@Injectable({
  providedIn: 'root',
})
export class AccPkgApiService extends API {
  public readonly maxPostlen = 25;
  constructor(
    http: HttpClient,
    pService: PopUpService,
    spinner: SpinnerService,
    toaster: ToasterService,
    ldService: LaunchdarklyService,
  ) {
    super(http, pService, spinner, toaster, ldService);
  }

  private readonly paths = {
    accPkgs: `${this.rootPath}accesspackages`,
    brs: `${this.rootPath}businessroles`,
    validations: `${this.rootPath}validations`,
    uploadFileUrl: `${this.rootPath}identify/accesspackagebusinessroledimensionmapping/signed-url`,
  };

  /* upload File */
  public uploadFile(emails: string[], params: FormData): Observable<void> {
    this.spinner.show();
    return this.getPresignedUrl({
      emails: emails.map((e) => {
        return { email: e };
      }),
    }).pipe(
      mergeMap((body) => {
        return this.uploadFileHelper(body, params);
      }),
      tap({
        complete: () => {
          this.spinner.hide();
          this.toaster.show(
            'File upload',
            `The file upload was successful. Queued for background processing...`,
          );
        },
      }),
      catchError(this.handleError<any>('Uploading a file')),
    );
  }

  /** @description get access package */
  public getAccPkg(key: string): Observable<AccPkg> {
    return this.getReqHelper<IAccPkgPayload>(
      `${this.paths.accPkgs}/${key}`,
      `Getting access package`,
    ).pipe(map((data) => new AccPkg(data)));
  }

  /** @description get access package */
  public postAccPkg(accPkg: AccPkg): Observable<AsmPostResponse> {
    return this.postReqHelper<AsmAccPkgPostResponse>(
      `${this.paths.accPkgs}`,
      {
        action: accPkg.key ? 'update' : 'create',
        data: [accPkg.payload],
      },
      `Getting access package`,
    ).pipe(
      tap(() =>
        this.toaster.show(
          'Access package',
          `Access package ${accPkg.name} successfully ${
            accPkg.key ? 'updated' : 'created'
          }`,
        ),
      ),
    );
  }

  /** @description sets access package with mapped BRs */
  public getAccPkgWMappedBrs(key: string): Observable<IBrSml[]> {
    return this.getReqHelper<IAccPkgMappedBrPayload>(
      `${this.paths.accPkgs}/${key}/businessroles`,
      `Getting access package with mapped business roles`,
    ).pipe(map((d) => d.businessRoles));
  }

  /** @description get access package */
  public postAccPkgWMappedBrs(
    brKeys: IBrSmlUpdate[],
    accPkg: AccPkg,
  ): Observable<AsmPostResponse[]> {
    let start = 0;
    let end = this.maxPostlen;
    const len = parseInt(`${brKeys.length / this.maxPostlen}`) + 1;
    const arr: Observable<AsmPostResponse>[] = [];
    for (let i = 0; i < len; ++i) {
      arr.push(
        this.postAccPkgWMappedBrsHelper(brKeys.slice(start, end), accPkg),
      );
      start += end;
      end += this.maxPostlen;
    }
    return forkJoin(arr).pipe(
      tap(() => {
        this.toaster.show(
          'Access package mapped BRs',
          `Access package ${accPkg.name} successfully updated`,
        );
      }),
    );
  }

  /** @description get access package with available and mapped AOI */
  public getAccPkgWMappedAoi(
    accessPackageKey: string,
  ): Observable<AOIAccessPackages> {
    return this.getReqHelper<IAOIAccPkgPayload>(
      `${this.paths.accPkgs}/${accessPackageKey}/areaofinterests`,
      `Getting access package with available and mapped AOI`,
    ).pipe(
      tap((data) => console.log('this is the data from tap: ', data)),
      map((data) => new AOIAccessPackages(data)),
    );
  }

  public postAccPkgAoiMapping(
    accPkgAoi: IAOIAccessPackagePostPayload[],
    apKey: string,
  ): Observable<AsmPostResponse> {
    const aoiKeys = accPkgAoi.map((item) => ({
      aoiKey: item.aoiKey,
      active: item.active,
    }));

    const params = {
      action: 'update',
      data: aoiKeys,
    };

    return this.postReqHelper<AsmPostResponse>(
      `${this.paths.accPkgs}/${apKey}/areaofinterests`,
      params,
      'Update access package areas of interest',
    ).pipe(
      tap({
        complete: () => {
          this.toaster.show(
            'Update Access Package AOI Mapping',
            `Updating access package areas of interest`,
          );
        },
      }),
    );
  }

  private postAccPkgWMappedBrsHelper(
    brKeys: IBrSmlUpdate[],
    accPkg: AccPkg,
  ): Observable<AsmPostResponse> {
    return this.postReqHelper<AsmPostResponse>(
      `${this.paths.accPkgs}/${accPkg.key}/businessroles`,
      { action: 'update', data: brKeys },
      `Getting access package`,
    );
  }

  /** @description get access package with BR dimensions */
  public getAccPkgWMappedBrDims(key: string): Observable<IAccPkgMappedBrDim[]> {
    return this.getReqHelper<IAccPkgMappedBrDimPayload>(
      `${this.paths.accPkgs}/${key}/businessroledimensions`,
      `Getting access package with business role dimensions`,
    ).pipe(map((data) => data.businessRoleDimensions));
  }

  /** @description get access package */
  public postAccPkgWMappedBrDims(
    accPkg: AccPkg,
    arr: IAccPkgMBRdimPayload[],
  ): Observable<AsmPostResponse> {
    return this.postReqHelper<AsmPostResponse>(
      `${this.paths.accPkgs}/${accPkg.key}/businessroledimensions`,
      {
        action: 'update',
        data: arr,
      },
      `Updating access package mapped BR dimensions`,
    ).pipe(
      tap(() =>
        this.toaster.show(
          'Access package mapped BR dimensions',
          `Access package ${accPkg.name} successfully updated`,
        ),
      ),
    );
  }

  /** @description get access package with Data Selections */
  public getAccPkgWMappedDataSels(
    key: string,
  ): Observable<IAccPkgMappedDataSel[]> {
    return this.getReqHelper<IAccPkgMappedDataSelPayload>(
      `${this.paths.accPkgs}/${key}/dataselections`,
      `Getting access package with data selections`,
    ).pipe(map((data) => data.accessPackageDataSelections));
  }

  /** @description get access package with available Data Selections */
  public getAccPkgWMappedDataSelsAvail(
    key: string,
  ): Observable<AccPkgMappedDataSelAvail[]> {
    return this.getReqHelper<IAccPkgMappedDataSelAvailPayload>(
      `${this.paths.accPkgs}/${key}/dataselections/available`,
      `Getting access package with available data selections`,
    ).pipe(
      map((data) =>
        data.dataSelections.map((d) => new AccPkgMappedDataSelAvail(d)),
      ),
    );
  }

  /** @description post access package data selections*/
  public postAccPkgWMappedDataSels(
    accPkg: AccPkg,
    arr: IAccPkgMDataSelPayload[],
    mappedAction: string,
  ): Observable<AsmPostResponse> {
    return this.postReqHelper<AsmPostResponse>(
      `${this.paths.accPkgs}/${accPkg.key}/dataselections`,
      {
        action: 'update',
        mappingAction: mappedAction,
        data: arr,
      },
      `Updating access package mapped data selections`,
    ).pipe(
      tap(() =>
        this.toaster.show(
          'Access package mapped data selections',
          `Access package ${accPkg.name} successfully updated`,
        ),
      ),
    );
  }

  /** @description validate access package */
  public validateAccPkg(
    data: IAccPkgValApprovers | IAccPkgValBase,
    isUpdate: boolean,
  ): Observable<IFGvalidation> {
    const payload = { AccessPackage: [data] };
    const action = isUpdate ? 'update' : 'create';
    return this.postReqHelper<IFGvalidation>(
      `${this.paths.validations}?action=${action}`,
      payload,
      'Access package validation',
    );
  }

  /** @description validate access package */
  public validateMappedBrs(data: IBrSmlVal[]): Observable<IFGvalidation> {
    const payload = { AccessPackageBusinessRoleMapping: data };
    return this.postReqHelper<IFGvalidation>(
      `${this.paths.validations}?action=update`,
      payload,
      'Access package mapped BRs validation',
    );
  }

  /** @description validate access package */
  public validateMappedBrDims(
    data: IAccPkgValMbrDim[],
  ): Observable<IFGvalidation> {
    const payload = { AccessPackageBusinessRoleDimensionMapping: data };
    return this.postReqHelper<IFGvalidation>(
      `${this.paths.validations}?action=update`,
      payload,
      'Access package mapped BR dims validation',
    );
  }

  public validateMappedDataSels(
    data: IAccPkgValMdataSel[],
    isNew: boolean,
  ): Observable<IFGvalidation> {
    const payload = { DataSelectionAccessPackageMapping: data };
    const mappedAction = isNew ? 'mc' : 'mu';
    return this.postReqHelper<IFGvalidation>(
      `${this.paths.validations}?mappingAction=${mappedAction}&action=update`, //mc or mu
      payload,
      'Access package mapped Data Selections validation',
    );
  }

  /** Get presigned url for file upload */
  private getPresignedUrl(emails: ISignedEmails): Observable<ISignedUrlBody> {
    const url = `${this.paths.uploadFileUrl}`;
    return this.http.post<ISignedUrlBody>(url, emails);
  }

  private uploadFileHelper(
    sub: ISignedUrlBody,
    params: FormData,
  ): Observable<void> {
    if (!sub.signedUrl || !sub.tag) {
      throw new Error('No signed url/header');
    }
    const h = {};
    h[awsHeader] = sub.tag;
    return this.http.put<void>(sub.signedUrl, params.get('file'), {
      headers: h,
    });
  }
}
