import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-login-fault',
  template: '<h1>{{title}}</h1><p>{{desc}}</p>',
  styles: ['p { max-width: 800px; }'],
})
export class LoginFaultComponent implements OnInit {
  title = 'Login Error';
  desc = 'Error trying to login in';

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    if (
      this.authService.loginErr instanceof Object &&
      typeof this.authService.loginErr.errorMessage === 'string'
    ) {
      this.desc = this.authService.loginErr.errorMessage;
    } else if (typeof this.authService.loginErr === 'string') {
      this.desc = this.authService.loginErr;
    }
  }
}
