import { Component, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  MatChipEditedEvent,
  MatChipInputEvent,
  MatChipInput,
} from '@angular/material/chips';
import { validateIvbEmail } from 'src/app/util/email';
import { invalidFilePropsValidator } from './ivb.validators';
import { environment } from 'src/environments';
import { IvbApiService } from './ivb-api.service';
import { Router } from '@angular/router';
import { IworkItem } from 'src/app/models/IVB';
@Component({
  selector: 'app-ivb',
  templateUrl: './ivb.component.html',
  styleUrls: ['./ivb.component.scss'],
})
export class IvbComponent implements OnInit {
  @ViewChild(MatChipInput) chipInput!: MatChipInput;

  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  environmentData: { [key: string]: any } = {};

  MSMEnvironment: any[] = [];
  currentEnv = '';
  href = '';

  fg!: FormGroup;
  hasInput: boolean = false; // Flag to track interaction
  initialFormState: any;
  attachmentCount = 0;
  attachmentFilenames: string[] = [];
  hasAttachments = false;
  attachmentData: IworkItem | null = null;
  attachmentErrorMessage: string | null = null; // Error message variable

  private subs: Subscription[] = [];
  constructor(
    private fb: FormBuilder,
    public apiService: IvbApiService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.loadEnvironmentSettings();
    this.fg = this.fb.group({
      emails: [[], Validators.required],
      environment: ['', [Validators.required]],
      fileToUpload: [
        undefined,
        [Validators.required, invalidFilePropsValidator],
      ],
      procedure: ['1'],
      workItem: ['', [Validators.required, Validators.min(1)]],
    });
    this.initialFormState = this.fg.getRawValue();
    this.subs.push(
      this.acProcedure.valueChanges.subscribe(this.updateProcedure.bind(this)),
    );
    this.updateProcedure();
  }

  loadEnvironmentSettings(): void {
    this.currentEnv = environment.env;
    const environments = ['local', 'dev', 'test', 'uat', 'stage', 'prod'];
    environments.forEach((env) => {
      this.environmentData[env] = environmentData[this.currentEnv];
      this.MSMEnvironment = this.environmentData[env];
    });
  }
  updateProcedure(): void {
    if (this.acProcedure.value === '1') {
      this.acWorkItem.clearValidators();
      this.fileToUpload.setValidators([
        Validators.required,
        invalidFilePropsValidator,
      ]);
    } else {
      this.acWorkItem.setValidators([Validators.required, Validators.min(1)]);
      this.fileToUpload.clearValidators();
    }
    this.acWorkItem.updateValueAndValidity();
    this.fileToUpload.updateValueAndValidity();
  }
  onInputFocus(): void {
    this.hasInput = true; // Track that user has focused on the input
  }

  chipAdd(event: MatChipInputEvent): void {
    const val = (event.value || '').trim();
    if (val) {
      const arr = val.split(/[,;]+/);
      const invalidEmails: string[] = [];
      let addedOne = false;
      arr.forEach((str) => {
        str = str.trim();
        const { valid, message } = validateIvbEmail(str);
        if (!valid) {
          invalidEmails.push(message);
        } else if (this.isDuplicateEmail(str)) {
          invalidEmails.push(`Duplicate email: ${str}`);
        } else if (this.emails.length >= 10) {
          invalidEmails.push(
            `Maximum of 10 email recipients are allowed: ${str}`,
          );
        } else {
          this.emails.push(str);
          addedOne = true;
        }
      });

      if (addedOne) {
        this.acEmails.setValue(this.emails);
      }
      if (invalidEmails.length > 0) {
        this.acEmails.setErrors({ invalid: invalidEmails });
      } else {
        this.acEmails.setErrors(null); // Clear errors if valid emails were added
      }

      if (arr.length > 1 || invalidEmails.length === 0) {
        event.chipInput?.clear();
      }
    }
  }

  chipEdit(currEmail: string, event: MatChipEditedEvent) {
    const value = event.value.trim();
    // Remove str if it no longer has a value
    if (!value) {
      this.chipRemove(currEmail);
      return;
    }

    const invalidEmails: string[] = [];
    // Validate the email format and get error message if any
    const { valid, message } = validateIvbEmail(value);

    if (!valid) {
      invalidEmails.push(message);
    } else if (this.isDuplicateEmail(value)) {
      invalidEmails.push(`Duplicate email: ${value}`);
    }

    if (invalidEmails.length > 0) {
      this.acEmails.setErrors({ invalid: invalidEmails });
    } else {
      const arr = this.emails;
      const index = arr.indexOf(currEmail);
      if (index >= 0) {
        arr[index] = value;
      }
    }
  }

  chipRemove(email: string): void {
    const arr = this.emails;
    const index = arr.findIndex((d) => d === email);
    if (index >= 0) {
      arr.splice(index, 1);
      this.acEmails.setValue(arr);
    }
  }

  onFilesSelected(event: any): void {
    if (typeof event.target.files === 'object') {
      const filesArray = Array.from(event.target.files);
      this.fileToUpload.markAsTouched();
      this.fileToUpload.setValue(filesArray);
    }
  }

  onSubmit(): void {
    if (this.fg.valid) {
      const formData = new FormData();
      const emailsList = this.emails.map((e) => {
        return { email: e };
      });
      formData.append('emails', JSON.stringify(emailsList));
      formData.append('environment', this.acEnvironment.value);
      formData.append('emailsArray', JSON.stringify(this.emails));
      if (this.acProcedure.value === '1') {
        const uploadedFileNames = this.fileToUpload.value.map(
          (file: File, index: number) => {
            formData.append(`file${index}`, file);
            return file.name;
          },
        );
        formData.append('fileNames', JSON.stringify(uploadedFileNames));
        this.apiService.uploadCsv(formData).subscribe(() => {
          this.reloadIVBPage();
        });
      } else {
        if (this.attachmentData) {
          formData.append(
            'workItem',
            JSON.stringify(this.attachmentData.workItem),
          );
          formData.append('sessionID', this.attachmentData.sessionID);
          this.apiService.uploadWorkItem(formData).subscribe(() => {
            this.reloadIVBPage();
          });
        }
      }
    }
  }
  reloadIVBPage(): void {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/ivb']);
    });
  }

  private isDuplicateEmail(email: string): boolean {
    if (
      this.emails.find(
        (x) => x.toLocaleLowerCase() === email.toLocaleLowerCase(),
      )
    ) {
      return true;
    }
    return false;
  }
  getAttchments(): void {
    this.apiService.getWorkAttachments(this.acWorkItem.value).subscribe({
      next: (data: IworkItem) => {
        this.attachmentData = data;
        if (data.workItem.attachments && data.workItem.attachments.length > 0) {
          this.attachmentCount = data.workItem.attachments.length;
          this.attachmentFilenames = data.workItem.attachments.map(
            (attachment) => attachment.filename,
          );
          this.hasAttachments = true; // Set to true on successful data retrieval
          this.attachmentErrorMessage = null; // Clear error message if attachments are found
        } else {
          this.attachmentCount = 0; // Ensure attachmentCount is zero if none are found
          this.hasAttachments = true; // Set to false if no attachments
          this.attachmentErrorMessage =
            'There are no attachments found for the specified work item ID.';
        }
      },
      error: () => {
        this.attachmentCount = 0; // Reset attachment count on error
        this.hasAttachments = false; // Set to false on error
        this.attachmentErrorMessage = null;
        this.attachmentData = null;
      },
    });
  }
  /** AbstractControl value is a `file` */
  get fileToUpload(): AbstractControl {
    return this.fg.get('fileToUpload');
  }
  /** AbstractControl value is a `string` */
  get acEmails(): AbstractControl {
    return this.fg.get('emails');
  }
  /** The Form Group Emails value `string[]` */
  get emails(): string[] {
    return this.acEmails.value;
  }
  get acEnvironment(): AbstractControl {
    return this.fg.get('environment');
  }
  get acProcedure(): AbstractControl {
    return this.fg.get('procedure');
  }
  get acWorkItem(): AbstractControl {
    return this.fg.get('workItem');
  }
}
//environment reference data.
export const environmentData = {
  local: [
    { displayName: 'Development', environment: 'dev' },
    { displayName: 'Development 2', environment: 'dev2' },
  ],
  dev: [
    { displayName: 'Development', environment: 'dev' },
    { displayName: 'Development 2', environment: 'dev2' },
  ],
  test: [
    { displayName: 'Testing', environment: 'test' },
    { displayName: 'Testing 2', environment: 'test2' },
    { displayName: 'Perf Test', environment: 'apt' },
  ],
  uat: [{ displayName: 'UAT', environment: 'uat' }],
  stage: [
    { displayName: 'Staging', environment: 'stage' },
    { displayName: 'Staging 2', environment: 'stage2' },
  ],
  prod: [{ displayName: 'Production', environment: 'prod' }],
};
