export interface IAppAirPayload {
  application: IAppAir[];
}

export interface IAppAir {
  appId: number;
  appName: string;
  appDescription: string;
  appSponsor: string;
  appOwner: string;
  status: string;
  appStartDttm: string;
  appEndDttm: string;
}

export class AppAirDeets {
  appId: number;
  appName = '';
  appDescription = '';
  appSponsor = '';
  appOwner = '';
  status = '';
  appStartDttm = '';
  appEndDttm = '';

  constructor(params?: IAppAir) {
    if (params) {
      this.appId = params.appId;
      this.appName = params.appName;
      this.appDescription = params.appDescription;
      this.appSponsor = params.appSponsor;
      this.appOwner = params.appOwner;
      this.status = params.status;
      this.appStartDttm = params.appStartDttm;
      this.appEndDttm = params.appEndDttm;
    }
  }
}
