import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { HamburgerComponent } from './hamburger/hamburger.component';
import { HeaderNavComponent } from './header-nav.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { UserComponent } from './user/user.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { ReleaseComponent } from './release/release.component';
import { ArrowClickDirective } from './release/arrow-click.directive';
import { DatePipe } from '@angular/common';
import { FormScheduleComponent } from './release/form-schedule/form-schedule.component';
import { UpdateEventFormComponent } from './release/update-event-form/update-event-form.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { SharedModule } from '../shared/shared.module';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  declarations: [
    HamburgerComponent,
    HeaderNavComponent,
    NavMenuComponent,
    UserComponent,
    ReleaseComponent,
    ArrowClickDirective,
    FormScheduleComponent,
    UpdateEventFormComponent,
  ],
  providers: [DatePipe],
  exports: [HeaderNavComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    MatInputModule,
    MatTooltipModule,
    MatCardModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    SharedModule,
    MatSelectModule,
  ],
})
export class HeaderNavModule {}
