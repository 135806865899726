<section class="custom-stepper">
    <header>
        <div *ngFor="let step of steps; let i = index;"
            [ngClass]="{'past': selectedIndex > i, 'present': selectedIndex === i, 'future': selectedIndex < i}">
            {{ step.label }}<img *ngIf="selectedIndex > i" src="/assets/images/arrow-right-white.png" /></div>
    </header>

    <div [style.display]="selected ? 'block' : 'none'">
        <!-- Content from the CdkStep is projected here -->
        <ng-container [ngTemplateOutlet]="selected.content"></ng-container>
    </div>
</section>