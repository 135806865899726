import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
  ) {}
  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (this.authService.isAuthenticated()) {
      if (
        route.routeConfig?.path === 'release' &&
        !this.authService.currUser?.isAdmin
      ) {
        this.router.navigate(['/home']);
        return false;
      }
      return true;
    } else if (this.authService.loginErr) {
      this.router.navigate(['/loginError']);
    } else {
      this.router.navigate(['/loggedOut']);
    }
    return false;
  }
}

@Injectable({ providedIn: 'root' })
export class FaultAuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
  ) {}

  canActivate(): boolean {
    if (this.authService.isAuthenticated()) {
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }
}
