import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Toast } from './toast';
import { Util } from 'src/app/util';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  private toaster = new BehaviorSubject<Toast | null>(null);
  content = this.toaster.asObservable();

  show(title: string, desc: string): void {
    this.toaster.next({
      title: Util.upprCaseFrstLttr(title),
      desc: Util.upprCaseFrstLttr(desc),
    });
  }
}
