import { Component } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-auth-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent {
  isExpanded = false;

  constructor(public authService: AuthService) {}

  clicked(): void {
    this.isExpanded = !this.isExpanded;
  }

  setToFalse(): void {
    this.isExpanded = false;
  }

  signOut(): void {
    this.authService.logout();
  }
}
