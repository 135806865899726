import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { CRUDStpprMngr } from './CRUDStpprMngr';
import { MatStepper } from '@angular/material/stepper';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-crud-stepper',
  templateUrl: './crud-stepper.component.html',
  styleUrls: ['./crud-stepper.component.scss'],
})
export class CrudStepperComponent<T> implements AfterViewInit {
  @ViewChild('stepper') private stepper: MatStepper;

  @Input() stepOneLbl: string;
  @Input() filterLbl: string;
  @Input() mngr: CRUDStpprMngr<T>;

  private subs: Subscription[] = [];

  ngAfterViewInit(): void {
    this.subs.push(
      this.stepper.selectionChange.subscribe((i) => {
        if (i.selectedIndex === 1 && i.previouslySelectedIndex === 0) {
          this.mngr.loadDataImpact();
        }
        if (i.selectedIndex === 2 && i.previouslySelectedIndex === 1) {
          this.mngr.hasReviewDataHelper();
        }
        if (i.selectedIndex === 0) {
          this.mngr.clearDataImpact();
          this.mngr.filterKeyPress(this.mngr.filterString);
        }
      }),
    );
  }
}
