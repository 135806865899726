<div class="toaster" *ngIf="toasts.length > 0">
  <div class="toast" *ngFor="let t of toasts">
    <div class="content">
      <div class="title-wrapper">
        <img src="/assets/images/green-thumbs-up.png" />
        <div class="title">{{t.title}}</div>
      </div>
      <p class="desc">{{t.desc}}</p>
    </div>
    <div class="close" (click)="clearToast(t)">&times;</div>
  </div>
</div>
