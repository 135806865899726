import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Subscription, forkJoin } from 'rxjs';
import { AppDeets } from 'src/app/models/AppDeets';
import { constants } from 'src/app/models/constants';
import { AppBrEdmApiService } from '../../app-br-edm-api.service';
import { MsmService } from 'src/app/msm/msm.service';
import { Application } from 'src/app/models/Application';
import { AppAirDeets } from 'src/app/models/AppAir';

@Injectable({
  providedIn: 'root',
})
export class AppDeetsFgHelper {
  fg: FormGroup;
  public appAir = new AppAirDeets();
  origApp: AppDeets;

  private subs: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
    private apiService: AppBrEdmApiService,
    private msmFgService: MsmService,
  ) {
    this.resetFg();
  }

  public setFg(appDeets: AppDeets): void {
    this.fg = this.fb.group({
      airID: appDeets.airID,
      igaAppID: [
        { value: appDeets.igaAppID, disabled: !appDeets.ceIgaAppID },
        [
          Validators.required,
          Validators.pattern(`^${appDeets.airID}[0-9]*`),
          Validators.maxLength(150),
        ],
      ],
      appName: [
        { value: appDeets.appName, disabled: !appDeets.ceAppName },
        [
          Validators.required,
          Validators.maxLength(100),
          Validators.pattern(constants.regExNoSpecialChars),
        ],
      ],
      appDescription: [
        { value: appDeets.appDescription, disabled: !appDeets.ceAppDesc },
        [
          Validators.maxLength(4200),
          Validators.pattern(constants.regExNoSpecialChars),
        ],
      ],
      privilegedAccess: [appDeets.privilegedAccess, Validators.required],
      additionalFilters: [
        { value: appDeets.additionalFilters, disabled: !appDeets.ceAddFilter },
        Validators.required,
      ],
      appOwner: [
        { value: appDeets.appOwner, disabled: !appDeets.ceAppOwner },
        [
          Validators.maxLength(100),
          Validators.pattern(constants.regExNoSpecialChars),
        ],
      ],
      appSponsor: [
        { value: appDeets.appSponsor, disabled: !appDeets.ceAppSponsor },
        [
          Validators.maxLength(100),
          Validators.pattern(constants.regExNoSpecialChars),
        ],
      ],
      onboardingStatus: appDeets.onboardingStatus,
      active: appDeets.active,
      visible: appDeets.visible,
      appStartDttm: appDeets.appStartDttm,
      appEndDttm: appDeets.appEndDttm,
    });
    for (const i in this.fg.controls) {
      this.fg.controls[i].markAsTouched();
    }
  }

  onInit(isElevate: boolean): void {
    const app: Application = this.msmFgService.acApp.value;
    forkJoin([
      this.apiService.getOnboardedAppDeets(app.igaAppID, isElevate),
      this.apiService.getAppAirDeets(app.airID),
      this.apiService.getOnboardingStatus(app.igaAppID),
    ]).subscribe(([apps, airDeets, onboardingStatus]) => {
      if (apps) this.setFg(apps);
      if (airDeets) {
        this.appAir = airDeets;
        this.acStartDate.patchValue(airDeets.appStartDttm);
        this.acEndDate.patchValue(airDeets.appEndDttm);
        if (
          !this.msmFgService.isMsmActionUpdateAppDeets &&
          apps.appName !== airDeets.appName
        ) {
          this.fg.patchValue(airDeets);
        }
      }
      if (onboardingStatus) this.fg.patchValue(onboardingStatus);
    });
    for (const i in this.fg.controls) {
      this.fg.controls[i].markAsTouched();
    }
  }

  onCopyAppName(): void {
    this.acAppName.setValue(this.appAir.appName);
  }

  onCopyAppDesc(): void {
    this.acAppDesc.setValue(this.appAir.appDescription);
  }

  onCopyAppOwner(): void {
    this.acAppOwner.setValue(this.appAir.appOwner);
  }

  onCopyAppSponsor(): void {
    this.acAppSponsor.setValue(this.appAir.appSponsor);
  }

  onDestroy(): void {
    this.subs.forEach((s) => s.unsubscribe());
    this.subs = [];
  }

  fgToModel(isElevate: boolean): AppDeets {
    return new AppDeets(this.fg.getRawValue(), isElevate);
  }

  resetFg(): void {
    this.fg = this.fb.group({
      airID: '',
      igaAppID: '',
      appName: '',
      appDescription: '',
      privilegedAccess: '',
      additionalFilters: '',
      appOwner: '',
      appSponsor: '',
      onboardingStatus: '',
      active: true,
      visible: true,
      appStartDttm: '',
      appEndDttm: '',
    });
  }

  /* --------------------------------------- getters --------------------------------------- */
  /** AbstractControl value is a number */
  get acAirID(): AbstractControl {
    return this.fg.get('airID');
  }
  /** AbstractControl value is a string */
  get acIgaAppID(): AbstractControl {
    return this.fg.get('igaAppID');
  }
  /** AbstractControl value is a string */
  get acAppName(): AbstractControl {
    return this.fg.get('appName');
  }
  /** AbstractControl value is a string */
  get acAppDesc(): AbstractControl {
    return this.fg.get('appDescription');
  }
  /** AbstractControl value is a string */
  get acPrivilegedAccess(): AbstractControl {
    return this.fg.get('privilegedAccess');
  }
  /** AbstractControl value is a string */
  get acAdditionalFilters(): AbstractControl {
    return this.fg.get('additionalFilters');
  }
  /** AbstractControl value is a string */
  get acAppOwner(): AbstractControl {
    return this.fg.get('appOwner');
  }
  /** AbstractControl value is a string */
  get acAppSponsor(): AbstractControl {
    return this.fg.get('appSponsor');
  }
  /** AbstractControl value is a string */
  get acOnboardingStatus(): AbstractControl {
    return this.fg.get('onboardingStatus');
  }
  /** AbstractControl value is a `Date` */
  get acStartDate(): AbstractControl {
    return this.fg.get('appStartDttm');
  }
  /** AbstractControl value is a `Date` */
  get acEndDate(): AbstractControl {
    return this.fg.get('appEndDttm');
  }
}
