<div class="mat-elevation-z8">
  <table mat-table class="full-width-table" aria-label="alter rows">
    <!-- loop table display column keys -->
    <ng-container *ngFor="let key of dispColKeys; let i = index" [matColumnDef]="key">
      <th mat-header-cell *matHeaderCellDef>{{ dispColumns[i] }}</th>
      <td mat-cell *matCellDef="let row">
        <p>{{row[key]}}</p>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="dispColKeys"></tr>
    <tr mat-row *matRowDef="let row; columns: dispColKeys;"></tr>
  </table>
</div>