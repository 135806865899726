import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PopUpService } from './popup.service';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
})
export class PopUpComponent implements OnInit, OnDestroy {
  visible = false;
  title = '';
  desc: string | string[] = '';
  btnTitle = 'Ok';
  cancelTitle = 'Cancel';
  isActionable = false;

  private actionCb?: (confirmed: boolean) => void;
  private keyUpLocked = false;
  private sub: Subscription;

  constructor(private pService: PopUpService) {}

  ngOnInit(): void {
    this.sub = this.pService.content.subscribe((p) => {
      if (p) {
        this.title = p.title;
        this.desc = p.desc;
        this.btnTitle = p.btnTitle;
        this.cancelTitle = p.cancelTitle || 'Cancel';
        this.isActionable = p.isActionable;
        this.actionCb = p.cb;
        this.toggleVisible(true);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.sub) this.sub.unsubscribe();
  }

  closeClicked(): void {
    this.toggleVisible(false);
    if (this.actionCb) {
      this.actionCb(false);
    }
  }

  actionClicked(): void {
    this.toggleVisible(false);
    if (this.actionCb) {
      this.actionCb(true);
    }
  }

  isDescString(): boolean {
    return typeof this.desc === 'string';
  }

  private keyEventHelper(event: KeyboardEvent): void {
    if (this.keyUpLocked || !this.visible) {
      return;
    }
    if (event.key === 'Escape') {
      this.closeClicked();
    } else if (event.key === 'Enter') {
      this.actionClicked();
    }
  }

  private toggleVisible(isVisible: boolean): void {
    if (isVisible) {
      document.onkeyup = this.keyEventHelper.bind(this);
    } else {
      document.onkeyup = null;
    }
    this.visible = isVisible;
    this.keyUpLocked = true;
    setTimeout(() => {
      this.keyUpLocked = false;
    }, 1000);
  }
}
