import { AuthService } from '../auth/auth.service';
import { EDarklyflags } from '../msm/actions-objective';
import { LaunchdarklyService } from '../singletons/launchdarkly.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

export interface INavItem {
  name: string;
  route: string;
  children?: INavItem[];
}

export class NavItem {
  name: string;
  route?: string;
  children?: NavItem[];
  isExpanded = false;

  constructor(obj: INavItem, base?: string) {
    this.name = obj.name;
    this.route = `${base || ''}${obj.route}`;
    if (obj.children) {
      this.children = obj.children.map(
        (child) => new NavItem(child, this.route),
      );
    }
  }
}

export function getNavItems(
  authService: AuthService,
  ldService: LaunchdarklyService,
): Observable<NavItem[]> {
  const navItems: NavItem[] = [
    new NavItem({ name: 'Home', route: '/home' }),
    new NavItem({ name: 'Security model', route: '/securitymodel' }),
  ];

  // Check if the current user is an admin
  const isAdmin = authService.currUser?.isAdmin;

  if (!isAdmin) {
    return of(navItems);
  }
  navItems.push(new NavItem({ name: 'Releases', route: '/release' }));
  return ldService.getFlag$(EDarklyflags.intakeValidationBox).pipe(
    map((ivbFlag: boolean) => {
      if (ivbFlag) {
        navItems.push(
          new NavItem({ name: 'Intake Validation Box', route: '/ivb' }),
        );
      }

      return navItems;
    }),
  );
}
