import {
  Component,
  ViewChild,
  ViewEncapsulation,
  OnInit,
  OnDestroy,
} from '@angular/core';
import {
  DateRange,
  DefaultMatCalendarRangeStrategy,
  MAT_DATE_RANGE_SELECTION_STRATEGY,
  MatCalendar,
} from '@angular/material/datepicker';
import { ReleaseApiService } from './release-api.service';
import { FormScheduleComponent } from './form-schedule/form-schedule.component';
import { EActions } from './update-event-form/update-event-form.component';
import { IReleaseEvents, IUpdateEvents } from 'src/app/models/Release';
import { PopUpService } from 'src/app/singletons/popup/popup.service';
import { MatDialog } from '@angular/material/dialog';
import { catchError, of, Subscription, switchMap } from 'rxjs';
@Component({
  selector: 'app-release',
  templateUrl: './release.component.html',
  styleUrls: ['./release.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: DefaultMatCalendarRangeStrategy,
    },
  ],
})
export class ReleaseComponent implements OnInit, OnDestroy {
  showEventsForm = false;
  updateEventsLabel = 'Update Events';
  groupedEvents: any;
  loading = true; // Add this line
  selectedMonthYear = '';
  currentMonthIndex: string;
  currentYear: number;
  schedulesData: any[] = []; // Define events array at the class level
  @ViewChild(MatCalendar) calendar!: MatCalendar<Date>;
  isInMonthSelection = false;
  selectedDateRange: DateRange<Date>;
  popupTitle = 'Delete Schedule';
  popupDescription = 'Are you sure you want to delete this schedule?';
  subscriptions: Subscription[] = [];

  constructor(
    private apiService: ReleaseApiService,
    private popup: PopUpService,
    private dialog: MatDialog,
  ) {
    const currentDate = new Date();
    this.currentYear = currentDate.getFullYear();
    this.currentMonthIndex = (currentDate.getMonth() + 1)
      .toString()
      .padStart(2, '0');
  }

  ngOnInit() {
    this.callback = this.callback.bind(this);
    this.getReleaseSchedules();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  getReleaseSchedules(): void {
    const queryParam = `${this.currentYear}-${this.currentMonthIndex}`;
    const sub = this.apiService.getCalSchedules(queryParam).subscribe((d) => {
      this.schedulesData = Array.isArray(d) ? d : []; // Ensure this.schedulesData is always an array
      this.groupedEvents = this.groupByEnvandDate(this.schedulesData);
      this.loading = false; // Add this line
      if (this.calendar) {
        this.calendar.updateTodaysDate();
      }
    });
    this.subscriptions.push(sub);
  }
  groupByEnvandDate(data: any[]) {
    // Group events by environment and then by date
    const groupedData: { [key: string]: any } = {};
    data.forEach((schedule) => {
      const env = schedule.environment;
      if (!groupedData[env]) {
        groupedData[env] = {
          environment: env,
          dates: {},
        };
      }
      if (!groupedData[env].dates[schedule.date]) {
        groupedData[env].dates[schedule.date] = [];
      }
      schedule.events.forEach((event) => {
        groupedData[env].dates[schedule.date].push({
          ...event,
          date: schedule.date,
        });
      });
    });

    // Convert grouped data object to array
    return Object.keys(groupedData).map((env) => {
      return {
        environment: env,
        dates: Object.keys(groupedData[env].dates).map((date) => ({
          date,
          events: groupedData[env].dates[date],
          environment: env,
        })),
      };
    });
  }

  dateClass(d: Date): string | undefined {
    if (!this.schedulesData) {
      return undefined;
    }
    const currentMonth = d.getMonth() + 1;
    const currentYear = d.getFullYear();
    const currentDate = d.getDate();

    // Check if the current date has an event
    const hasEvent = this.schedulesData.some((event) => {
      const eventDate = new Date(
        event.date.endsWith('Z') ? event.date : `${event.date}Z`,
      );
      return (
        eventDate.getUTCFullYear() === currentYear &&
        eventDate.getUTCMonth() + 1 === currentMonth &&
        eventDate.getUTCDate() === currentDate
      );
    });

    // If the current date has an event, apply the custom class
    if (hasEvent && !this.isInMonthSelection) {
      return 'example-custom-date-class'; // Apply your custom class for dates
    }

    return undefined;
  }

  onPeriodButtonClick(): void {
    const currentMonth = this.calendar.activeDate.getMonth();
    const currentYear = this.calendar.activeDate.getFullYear();

    if (this.isInMonthSelection) {
      this.handleMonthChange(this.calendar.activeDate);

      const elements = document.querySelectorAll('.example-custom-date-class');
      elements.forEach((x) => {
        const currentDate = parseInt(x.textContent);
        const hasEvent = this.schedulesData.some((event) => {
          const eventDate = new Date(event.date);
          return (
            eventDate.getFullYear() === currentYear &&
            eventDate.getMonth() === currentMonth &&
            eventDate.getDate() === currentDate
          );
        });

        if (hasEvent) {
          x.classList.add('example-custom-date-class');
        } else {
          x.classList.remove('example-custom-date-class');
        }
      });
    }
  }
  updateEventsClicked(): void {
    this.showEventsForm = true;
  }
  hideForm(): void {
    this.showEventsForm = false;
  }
  onPrevArrowClick(): void {
    const currentDate = this.calendar.activeDate;
    this.currentYear = currentDate.getFullYear();
    this.currentMonthIndex = (currentDate.getMonth() + 1)
      .toString()
      .padStart(2, '0');
    this.getReleaseSchedules();
  }

  onNextArrowClick(): void {
    const currentDate = this.calendar.activeDate;
    this.currentYear = currentDate.getFullYear();
    this.currentMonthIndex = (currentDate.getMonth() + 1)
      .toString()
      .padStart(2, '0');
    this.getReleaseSchedules();
  }

  // This function will be called whenever the user navigates to a different month
  handleMonthChange(event: any): void {
    this.isInMonthSelection = false;
    this.currentYear = event.getFullYear();
    this.currentMonthIndex = (event.getMonth() + 1).toString().padStart(2, '0');
    this.getReleaseSchedules();
  }

  handleYearChange(): void {
    this.isInMonthSelection = true;
  }

  handleDateSelection(selectedDate: Date): void {
    if (
      this.selectedDateRange &&
      this.selectedDateRange.start &&
      selectedDate >= this.selectedDateRange.start &&
      !this.selectedDateRange.end
    ) {
      this.selectedDateRange = new DateRange(
        this.selectedDateRange.start,
        selectedDate,
      );
    } else {
      this.selectedDateRange = new DateRange(selectedDate, null);
    }

    const startDate = this.selectedDateRange.start;
    const endDate = this.selectedDateRange.end;

    if (startDate && endDate) {
      this.openFormScheduleDialogFromCalendar({
        startDate,
        endDate,
        from: 'calendar',
      });
    }
  }

  deleteSchedule(row: IUpdateEvents) {
    const sub = this.popup
      .confirm(this.popupTitle, this.popupDescription)
      .pipe(
        switchMap((proceed) => {
          if (!proceed) return of(null);
          return this.apiService.deleteSchedule(
            row.key,
            EActions.delete,
            this.callback,
          );
        }),
        catchError((err) => {
          this.handleError(err.error.message, 'Delete Schedule errors');
          return of(null);
        }),
      )
      .subscribe((result) => {
        if (result && !result.message.includes('success')) {
          this.handleError(result.message, 'Delete Event errors');
        }
      });
    this.subscriptions.push(sub);
  }

  handleError(message: any, title: string): void {
    const msg = Array.isArray(message) ? message[0] : message;
    this.popup.show(title, msg);
  }

  callback(): void {
    this.getReleaseSchedules();
  }

  openFormScheduleDialog(data?: any) {
    const dialogRef = this.dialog.open(FormScheduleComponent, { data });

    const sub = dialogRef.afterClosed().subscribe((result) => {
      if (result !== 'cancel') {
        this.getReleaseSchedules();
        this.selectedDateRange = null;
      }
    });
    this.subscriptions.push(sub);
  }

  openFormScheduleDialogFromCalendar(data: any) {
    this.openFormScheduleDialog(data);
  }

  openFormScheduleDialogFromUpdateButton(rowRef: IReleaseEvents) {
    this.openFormScheduleDialog({ ...rowRef, from: 'update' });
  }
}
