import { DataSource } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { map, tap } from 'rxjs/operators';
import { Observable, merge } from 'rxjs';
import { RoleFilter } from 'src/app/models/ApplicationRoleFilters';
import { EActionType } from 'src/app/models/Actions';

export class MappingReviewTableDataSource extends DataSource<RoleFilter> {
  data: RoleFilter[] = [];
  paginator: MatPaginator | undefined;
  storedData: RoleFilter[] = [];

  constructor(
    public data$: Observable<RoleFilter[]>,
    private filterKeyOriginal: string,
    private filterKeyCurrent: string,
    private originalMapped: string,
    private actionType: string,
  ) {
    super();
  }

  connect(): Observable<RoleFilter[]> {
    if (!this.paginator) {
      throw Error(
        'Please set the paginator and sort on the data source before connecting.',
      );
    }
    return merge(
      this.data$.pipe(
        tap((x) => {
          let newData = x.filter(
            (d) => d[this.filterKeyCurrent] !== d[this.filterKeyOriginal],
          );

          // Save filtered data to storedData
          this.storedData = [...this.storedData, ...newData];

          // Dont show the unmapped data when unmap is clicked in review table
          this.storedData = this.storedData.filter(
            (d) =>
              !(
                d[this.actionType] === EActionType.inactivate &&
                d[this.originalMapped] === EActionType.inactivate
              ),
          );

          // Dont show the mapped data when re-map is clicked in review table
          this.storedData = this.storedData.filter(
            (d) =>
              !(
                d[this.actionType] === EActionType.activate &&
                d[this.originalMapped] === EActionType.activate
              ),
          );

          // Clear newData
          newData = [];

          // Clear this.data and update it with storedData
          this.data = [];

          // Remove duplicates based on igaAppId
          this.data = this.storedData.filter(
            (obj, index, self) =>
              index ===
              self.findIndex(
                (t) =>
                  (t as RoleFilter).igaAppID === (obj as RoleFilter).igaAppID &&
                  (t as RoleFilter).key === (obj as RoleFilter).key &&
                  (t as RoleFilter).actionType ===
                    (obj as RoleFilter).actionType,
              ),
          );
        }),
      ),
      this.paginator.page,
    ).pipe(
      map(() => {
        return this.getPagedData([...this.data]);
      }),
    );
  }

  disconnect(): void {
    // No implementation needed for disconnect method
  }

  private getPagedData(data: RoleFilter[]): RoleFilter[] {
    if (this.paginator) {
      const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
      return data.splice(startIndex, this.paginator.pageSize);
    } else {
      return data;
    }
  }
}
