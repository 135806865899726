import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanDeactivate,
  Router,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { IngestionService } from 'src/app/singletons/ingestion.service';
import { PopUpService } from 'src/app/singletons/popup/popup.service';
import { MsmService } from '../msm.service';
import { AppBrEdmApiService } from './app-br-edm-api.service';

@Injectable({
  providedIn: 'any',
})
export class AppBrEdmGuard implements CanActivate, CanDeactivate<unknown> {
  constructor(
    private router: Router,
    private msmFgService: MsmService,
    private pupService: PopUpService,
    private apiService: AppBrEdmApiService,
    private ingService: IngestionService,
  ) {}

  canActivate(childRoute: ActivatedRouteSnapshot): boolean | UrlTree {
    if (childRoute.routeConfig.path && this.msmFgService.fg.valid) {
      this.ingService.api = this.apiService;
      return true;
    }

    return this.router.parseUrl('/securitymodel');
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.msmFgService.fg.invalid) {
      return true;
    }
    return this.pupService.confirm(
      'Discard unsaved changes?',
      `Changes to '${this.msmFgService.msmActionName}' will not be saved and you will go back to the application selection.`,
      'Discard',
    );
  }
}
