import { CrudStppr, EAlterState } from './CrudStppr';
import { RoleFilterSml } from 'src/app/models/RoleFilter';

export interface IRoleFilterSelectionPayload {
  roleFilterSelections: IRoleFilterSelection[];
}

export interface IRoleFilterSelection {
  filterSelectKey: string;
  filterSelectValue: string;
  roleFilterKey?: string;
  roleFilterDisplayName?: string;
  active: boolean;
  visible: boolean;
  rfSml?: RoleFilterSml;
}

export interface IRoleFilterSelectionValidationPayload {
  FilterSelectionKey: string;
  FilterSelectionValue: string;
  RoleFilterKey: string;
  IsActive: boolean;
  IsVisible: boolean;
}

export class RoleFilterSelection extends CrudStppr<RoleFilterSelection> {
  filterSelectKey = '';
  filterSelectValue = '';
  rfSml: RoleFilterSml;

  constructor(params?: IRoleFilterSelection, i?: number) {
    super(i);
    if (params) {
      this.filterSelectKey = params.filterSelectKey;
      this.filterSelectValue = params.filterSelectValue;
      this.active = params.active;
      this.visible = params.visible;

      if (params.rfSml) {
        this.rfSml = new RoleFilterSml(params.rfSml);
      } else if (params.roleFilterKey) {
        this.rfSml = new RoleFilterSml({
          displayName: params.roleFilterDisplayName,
          key: params.roleFilterKey,
        });
      }
    }
  }

  get display() {
    return `${this.filterSelectKey}: ${this.filterSelectValue}`;
  }

  get displayRoleFilter() {
    if (this.rfSml) {
      return this.rfSml.display;
    }

    return '';
  }

  get payload() {
    return {
      filterSelectKey: this.filterSelectKey,
      filterSelectValue: this.filterSelectValue,
      active: this.active,
      visible: this.visible,
      roleFilterKey: this.rfSml?.key || null,
      roleFilterDisplayName: this.rfSml?.displayName || null,
    };
  }

  get validationPayload(): IRoleFilterSelectionValidationPayload {
    const obj: IRoleFilterSelectionValidationPayload = {
      FilterSelectionKey: this.filterSelectKey,
      FilterSelectionValue: this.filterSelectValue,
      RoleFilterKey: this.rfSml?.key,
      IsActive: this.active,
      IsVisible: this.visible,
    };
    return obj;
  }

  reset(): void {
    const uc = this.updatedClass as RoleFilterSelection;
    if (uc) {
      this.filterSelectKey = uc.filterSelectKey;
      this.filterSelectValue = uc.filterSelectValue;
      this.rfSml = uc.rfSml;
      this.active = uc.active;
      this.visible = uc.visible;
    }
    this.action = EAlterState.nothing;
  }
}
