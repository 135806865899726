import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { ImpactTblDataSource } from './impact-tbl-datasource';

@Component({
  selector: 'app-impact-tbl',
  templateUrl: './impact-tbl.component.html',
  styleUrls: ['./impact-tbl.component.scss'],
})
export class ImpactTblComponent<T> implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<T>;
  dataSource;

  @Input() dispColKeys: string[] = [];
  @Input() dispColumns: string[] = [];
  @Input() data = [];

  ngOnInit(): void {
    this.dataSource = new ImpactTblDataSource(this.data);
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }
}
