import { DataSource } from '@angular/cdk/collections';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { map, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, merge } from 'rxjs';
import { MapFSFGService } from '../br-flds/map-fs/map-fs-fg.service';

export class MappingReviewTableDataSource<T> extends DataSource<T> {
  data: T[] = [];
  paginator: MatPaginator;
  storedData: T[] = [];
  private dataSource = new BehaviorSubject<any[]>([]);
  currentData = this.dataSource.asObservable();
  dataSubject: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);

  public validBrKeys: any[] = [];
  public brKeysFinal: any[] = [];
  updateFlag = false;
  mapFsFGService: MapFSFGService;
  constructor(
    public data$: Observable<any[]>,
    private filterKeyOriginal: string,
    private filterKeyCurrent: string,
    private originalMapped: string,
    private actionType: string,
  ) {
    super();
  }

  connect(): Observable<T[]> {
    if (!this.paginator) {
      throw Error(
        'Please set the paginator and sort on the data source before connecting.',
      );
    }

    return merge(this.data$, this.paginator.page).pipe(
      tap((x: any[] | PageEvent) => {
        if (Array.isArray(x)) {
          const newData = x.filter(
            (d) => d[this.filterKeyCurrent] !== d[this.filterKeyOriginal],
          );

          newData.forEach((item) => {
            if (!item.displayBrKeyAndName) {
              item.displayBrKeyAndName = `${item.businessRoleKey}: ${item.businessRoleName}`;
            }
          });
          // Save filtered data to storedData
          this.storedData = this.storedData.concat(newData);

          this.updateStoredData();
          // Dont show the unmapped data when unmap is clicked in review table
          this.storedData = this.storedData.filter(
            (d) =>
              !(
                d[this.actionType] === 'Inactivate' &&
                d[this.originalMapped] === 'Inactivate'
              ),
          );

          // Dont show the mapped data when re-map is clicked in review table
          this.storedData = this.storedData.filter(
            (d) =>
              !(
                d[this.actionType] === 'Activate' &&
                d[this.originalMapped] === 'Activate'
              ),
          );

          // Remove duplicates based on businessRoleKey
          this.data = this.storedData.filter(
            (obj, index, self) =>
              index ===
              self.findIndex(
                (t) =>
                  (t as any).businessRoleKey === (obj as any).businessRoleKey &&
                  (t as any).filterSelectKey === (obj as any).filterSelectKey &&
                  (t as any).actionType === (obj as any).actionType,
              ),
          );
        }
      }),
      map(() => {
        return this.getPagedData([...this.data]);
      }),
    );
  }

  disconnect(): void {
    // No implementation needed for disconnect method
  }

  getPagedData(data: T[]): T[] {
    if (this.paginator) {
      const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
      return data.splice(startIndex, this.paginator.pageSize);
    } else {
      return data;
    }
  }

  updateStoredData(): void {
    this.storedData = this.storedData.filter((item: any) => {
      return this.brKeysFinal.includes(item.businessRoleKey);
    });
  }

  updateValidBrKeys(brKeys: any[]) {
    this.validBrKeys = brKeys;
    if (Array.isArray(this.validBrKeys) && this.validBrKeys.length > 0) {
      const validBrKeysFinal = this.validBrKeys.map((item) => {
        return item.businessRoleKey;
      });
      this.brKeysFinal = validBrKeysFinal;
    }
  }
}
