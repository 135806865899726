/* ---------------- Ent Dim payload (EMP) ---------------- */
export interface IEMP {
  businessRoleKey: string;
  dimensions: IEMPDimension[];
  igaAppID: number;
}

interface IEMPDimension {
  entitlements: IEMPEnt[];
  dimensionTypeKey: string;
  key: string;
  value: string;
}

interface IEMPEnt {
  key: string;
  active: boolean;
  requiredSelection: boolean;
}
// end: Ent Dim payload

interface IPostEntTier {
  entitlements: IEMPEnt[];
}

export interface IPostEntitlementDimension {
  businessRoleKey: string;
  dimensionKey: string;
  lower: IPostEntTier;
  higher: IPostEntTier;
}

export interface IEntitlementDimension {
  active: boolean;
  selected?: boolean;
  dimensionTypeKey: string;
  dimensionKey: string;
  dimensionValue: string;
  entitlementKey: string;
  requiredSelection?: boolean;
}

export interface IEntMapperFG {
  businessRoleKey: string;
  lowerEntitlements: IEntitlementDimension[];
  higherEntitlements: IEntitlementDimension[];
  dimType: string;
  dimValue: string;
}

export interface IEntDimValPayload {
  BusinessRoleKey: string;
  EntitlementKey: string;
  DimensionKey: string;
  RequiredSelection: boolean;
  IsActive: boolean;
}

export class EntDims {
  businessRoleKey: string;
  ents: IEntitlementDimension[] = [];
  igaAppID: number;

  constructor(param: IEMP) {
    if (param) {
      this.igaAppID = param.igaAppID;
      this.businessRoleKey = param.businessRoleKey;
      param.dimensions.forEach((dim) => {
        this.ents.push(
          ...dim.entitlements.map((ent) => {
            return {
              requiredSelection: ent.requiredSelection,
              active: ent.active,
              selected: true,
              dimensionKey: dim.key,
              entitlementKey: ent.key,
              dimensionTypeKey: dim.dimensionTypeKey,
              dimensionValue: dim.value,
            };
          }),
        );
      });
    }
  }
}

export class Entitlement {
  businessRoleKey = '';
  lowerEntitlements: IEntitlementDimension[];
  higherEntitlements: IEntitlementDimension[];
  dimType = '';
  dimValue = '';

  constructor(entMapperFG: IEntMapperFG) {
    if (entMapperFG) {
      Object.assign(this, entMapperFG);
    }
  }

  getPayload(): IPostEntitlementDimension | undefined {
    if (
      !Array.isArray(this.lowerEntitlements) ||
      !Array.isArray(this.higherEntitlements)
    )
      return undefined;

    const loEntStrs: IEMPEnt[] = [];
    const hiEntStrs: IEMPEnt[] = [];

    this.lowerEntitlements.forEach((ed) => {
      if (ed.selected)
        loEntStrs.push({
          key: ed.entitlementKey,
          active: ed.active,
          requiredSelection: ed.requiredSelection,
        });
    });
    this.higherEntitlements.forEach((ed) => {
      if (ed.selected)
        hiEntStrs.push({
          key: ed.entitlementKey,
          active: ed.active,
          requiredSelection: ed.requiredSelection,
        });
    });

    return {
      businessRoleKey: this.businessRoleKey,
      dimensionKey: this.dimValue,
      lower: {
        entitlements: loEntStrs,
      },
      higher: {
        entitlements: hiEntStrs,
      },
    };
  }

  getEntDimValPayload(isLower: boolean): IEntDimValPayload[] {
    const ents = isLower ? this.lowerEntitlements : this.higherEntitlements;
    const arr: IEntDimValPayload[] = [];
    ents.forEach((row) => {
      if (row.selected) {
        arr.push({
          BusinessRoleKey: this.businessRoleKey,
          EntitlementKey: row.entitlementKey,
          DimensionKey: this.dimValue,
          RequiredSelection: true,
          IsActive: true,
        });
      }
    });
    return arr;
  }
}

export const enum ETier {
  lower = 'lower',
  higher = 'higher',
}

export interface IAvailableEntitlement {
  key: string;
  active: boolean;
}

export interface IAvailableEntitlements {
  availableEntitlements: IAvailableEntitlement[];
}

export const enum EChangeType {
  none = 'none',
  added = 'added',
  updated = 'updated',
}

export interface IEdmTableRowViewModel {
  changeType: EChangeType;
  original: IEdmTableRowData;
  changed?: IEdmTableRowData;
}

export interface IEdmTableRowData {
  dimensionType: string;
  dimension: string;
  dimensionValue: string;
  entitlement: string;
  requiredSelection: boolean;
  active: boolean;
}
