/** What state is the current crud stepper row in */
export enum EAlterState {
  new = 'new',
  update = 'update',
  delete = 'delete',
  nothing = 'nothing',
}

export enum EAddUpdateState {
  no,
  yes,
  continue,
}

export interface CrudStpprPost<T> {
  action: string;
  data: CrudStppr<T>[];
}

/**
 * The Crud stepper abstract class is used by the CRUD stepper module
 */
export abstract class CrudStppr<T> {
  key: string;
  filterSelectKey: string;
  id = -1;
  required = true;
  active = true;
  visible = true;
  action: EAlterState;
  updatedClass: CrudStppr<T>;

  constructor(i: number) {
    this.id = i;
    this.action = i < 0 ? EAlterState.new : EAlterState.nothing;
  }

  get requiredDisplay() {
    return this.required ? 'Yes' : 'No';
  }

  get activeDisplay() {
    return this.active ? 'Yes' : 'No';
  }

  get visibleDisplay() {
    return this.visible ? 'Yes' : 'No';
  }

  abstract get payload();
  abstract reset(): void;
}

export interface TReviewVals<T> {
  before: T;
  after: T;
}
