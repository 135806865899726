import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  showFooter = true;
  year = new Date().getFullYear();
  private readonly cHideFooter = 'hideFooter';

  ngOnInit(): void {
    this.showFooter = !this.isCookieFooterHidden();
    if (!environment.production) {
      console.log(`Current env: ${environment.aad}`);
    }
  }

  toggle(): void {
    if (this.showFooter) {
      this.showFooter = false;
      this.setCookie(true);
    } else {
      this.showFooter = true;
      this.setCookie(false);
    }
  }

  private isCookieFooterHidden(): boolean {
    if (!document.cookie) {
      return false;
    }
    const cSplit = document.cookie.split('; ');
    const val = cSplit.find((row) => row.startsWith(`${this.cHideFooter}=`));
    if (val) {
      return val.split('=')[1] === '1';
    }
    return false;
  }

  private setCookie(val: boolean): void {
    const expires = new Date();
    expires.setMonth(expires.getMonth() + 2);
    document.cookie = `${this.cHideFooter}=${
      val ? 1 : 0
    }; expires=${expires.toUTCString()}; path=/`;
  }
}
