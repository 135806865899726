import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { BehaviorSubject, Subscription, combineLatest, startWith } from 'rxjs';
import { Application, SimpleApplication } from 'src/app/models/Application';
import { Br } from 'src/app/models/Br';
import { constants } from 'src/app/models/constants';
import { DataClassification } from 'src/app/models/DataClassification';
import { InsiderAlert } from 'src/app/models/InsiderAlert';
import {
  invalidBRInfoValidator,
  invalidQuestionnaireValidator,
  invalidApproverValidator,
  invalidDefaultAllowedDaysValidator,
} from './br-fg.validators';
import { AppDeetsFgHelper } from '../app-flds/app-deets-fg.helper';
import { MsmService } from 'src/app/msm/msm.service';
import { IRoleFilterByIgaAppId } from 'src/app/models/BusinessRoleFilterSelections';
import { AuthService } from 'src/app/auth/auth.service';

@Injectable({
  providedIn: 'any',
})
export class BrFgService {
  fg: FormGroup;
  isNewBr = false;
  displayDataSel = false;
  private subs: Subscription[] = [];
  private apps: Application[];
  isAdminRole = false;

  constructor(
    private fb: FormBuilder,
    private appDeetsService: AppDeetsFgHelper,
    private msmFgService: MsmService,
    private authService: AuthService,
  ) {
    this.setFg();
  }

  private setFg(): void {
    this.fg = this.fb.group(
      {
        key: '',
        name: [
          '',
          [
            Validators.pattern(constants.regExNoSpecialChars),
            Validators.required,
            Validators.maxLength(100),
          ],
        ],
        description: [
          '',
          [
            Validators.pattern(constants.regExNoSpecialChars),
            Validators.maxLength(500),
          ],
        ],
        type: ['', Validators.required],
        userTypes: [[], Validators.required],
        certifiable: false,
        autoApprovalFlag: false,
        autoApprovalCondition: [
          '',
          [
            Validators.required,
            Validators.maxLength(100),
            Validators.pattern(constants.regExAutoApproval),
          ],
        ],
        maxAutoApprovalAllowed: [''],
        roleOwner: [
          '',
          [
            Validators.required,
            Validators.pattern(constants.regExNoSpecialChars),
            Validators.maxLength(1000),
          ],
        ],
        roleActivityFlag: [
          '',
          [
            Validators.required,
            Validators.pattern(constants.regExNoSpecialChars),
            Validators.maxLength(1000),
          ],
        ],
        roleProvisionApplication: [
          '',
          [
            Validators.required,
            Validators.pattern(constants.regExNoSpecialChars),
            Validators.maxLength(1000),
          ],
        ],
        roleDependency: [
          '',
          [
            Validators.required,
            Validators.pattern(constants.regExNoSpecialChars),
            Validators.maxLength(1000),
          ],
        ],
        functionalArea: [
          '',
          [
            Validators.required,
            Validators.pattern(constants.regExNoSpecialChars),
            Validators.maxLength(1000),
          ],
        ],
        primaryWGApprover: [
          '',
          [
            Validators.required,
            Validators.pattern(constants.regExNoSpecialChars),
            Validators.maxLength(500),
          ],
        ],
        secondaryWGApprover: [
          '',
          [
            Validators.required,
            Validators.pattern(constants.regExNoSpecialChars),
            Validators.maxLength(500),
          ],
        ],
        startDttm: '',
        endDttm: '',
        maxAllowedDays: [
          constants.maxAllowedDays,
          [
            Validators.required,
            Validators.min(5),
            Validators.max(constants.maxAllowedDays),
          ],
        ],
        defaultAllowedDays: [
          constants.defaultAllowedDays,
          [Validators.required, Validators.min(5)],
        ],
        restrictedRole: false,
        dataPrivacy: ['', Validators.required],
        insiderAlert: ['', Validators.required],
        riskScoreMultiplier: [
          { value: '1.00', disabled: true, default: '1.00' },
          {
            validators: [
              Validators.required,
              Validators.min(0.0),
              Validators.max(1.0),
            ],
            updateOn: 'blur',
          },
        ],
        licensing: ['', Validators.required],
        dataClassification: ['', Validators.required],
        provisionProfilesAndPolicies: false,
        productionSupport: false,
        mappedApps: [[]],
        approvalType: [constants.approvalType, Validators.required],
        approvalLevel: constants.approvalLevel,
        privilegedAccess: ['', Validators.required],
        profile: [
          '',
          [
            Validators.pattern(constants.regExProfile),
            Validators.maxLength(100),
          ],
        ],
        active: true,
        visible: true,
        selfCertPeriod: [
          '',
          [
            Validators.min(constants.minSelfCertPeriod),
            Validators.max(constants.maxSelfCertPeriod),
            Validators.pattern(/^\d+$/),
          ],
        ],
        certActionPeriod: [
          '',
          {
            validators: [Validators.required, Validators.min(0)],
          },
        ],
        // Human approval fields
        humanDataApprovalFlag: false,
        humanDataApprovalFilter: [
          '',
          [
            Validators.maxLength(constants.maxLenHumanDataApprovalFilter),
            Validators.pattern(constants.regExNoSpecialChars),
          ],
        ],
        autoExtensionFlag: false,
        autoExtensionCondition: ['', Validators.required],
        maxAutoExtensionAllowed: ['', Validators.required],
        defaultApproverIds: [],
      },
      {
        validators: [
          invalidBRInfoValidator,
          invalidQuestionnaireValidator,
          invalidApproverValidator,
          invalidDefaultAllowedDaysValidator,
        ],
      },
    );
  }

  onInit(apps?: Application[]): void {
    this.resetFG();

    if (apps) {
      this.isNewBr = true;
      this.apps = apps;
    } else {
      this.isNewBr = false;
    }
    this.subs.push(
      this.acRestrictedRole.valueChanges.subscribe(
        this.updateApprovalLevel.bind(this),
      ),
    );
    this.subs.push(
      this.acInsiderAlert.valueChanges.subscribe((v) => {
        this.updateApprovalLevel();
        this.tgglRskScrMltplrVldtrs(v);
      }),
    );
    this.subs.push(
      this.acDataClassification.valueChanges.subscribe(
        this.updateApprovalLevel.bind(this),
      ),
    );
    this.subs.push(
      this.acAutoApprovalFlag.valueChanges.subscribe(
        this.updateAutoApproval.bind(this),
      ),
    );
    this.subs.push(
      this.acHumanDataApprovalFlag.valueChanges.subscribe(
        this.updateHumanDataApproval.bind(this),
      ),
    );
    this.subs.push(
      this.acAutoExtensionFlag.valueChanges.subscribe(
        this.updateAutoExtension.bind(this),
      ),
    );
    this.subs.push(
      this.acApprovalType.valueChanges.subscribe(this.updateDataSel.bind(this)),
    );

    // This is to ensure that the acAutoApprovalCondition
    // is in sync with the value of acAutoApprovalFlag.
    this.updateAutoApproval(this.acAutoApprovalFlag.value);

    // This is to ensure that the HumanDataApproval fields
    // are in sync with the value of acHumanDataApprovalFlag.
    this.updateHumanDataApproval();

    // This is to ensure the Secondary workgroup approver
    // is updated based on specific flags.
    this.updateApprovalLevel();

    // This is to ensure the Auto extension
    // is updated based on specific flags.
    this.updateAutoExtension();

    // This is to ensure the Data Selection stepper
    // is updated based on specific flags.
    this.updateDataSel();

    // Add a subscription to acIgaAppID.valueChanges

    this.subs.push(
      combineLatest([
        this.appDeetsService.acIgaAppID.valueChanges.pipe(
          startWith(this.appDeetsService.acIgaAppID.value),
        ),
        this.appDeetsService.acAppName.valueChanges.pipe(
          startWith(this.appDeetsService.acAppName.value),
        ),
        this.appDeetsService.acAirID.valueChanges.pipe(
          startWith(this.appDeetsService.acAirID.value),
        ),
      ]).subscribe(([igaAppId, appName, airId]) => {
        this.apps = this.apps || [];
        const existingAppIndex = this.apps.findIndex(
          (app) => app.airID === airId,
        );

        const newApp = new Application({
          airID: airId,
          description: this.msmFgService.appDesc,
          igaAppID: igaAppId,
          name: appName,
          onboardingLevel: this.msmFgService.onboardingLvl,
          onboardingStatus: this.msmFgService.onboardingStatus,
          active: this.msmFgService.appActive,
          visible: this.msmFgService.appVisible,
        });

        if (existingAppIndex !== -1) {
          // Update the existing app
          this.apps[existingAppIndex] = newApp;
        } else {
          // Add a new app
          this.apps.push(newApp);
        }

        this.setMappedApps(igaAppId, appName);
      }),
    );

    this.isAdminRole = this.authService.currUser?.isAdmin;
    this.setPropertyStatus(this.isAdminRole);
  }

  setPropertyStatus(isAdminRole: boolean): void {
    if (!isAdminRole) {
      this.acRestrictedRole.disable();
    }
  }
  onDestroy(): void {
    this.apps = undefined;
    this.subs.forEach((s) => s.unsubscribe());
    this.setFg();
    this.changeData([]);
  }

  resetFG(): void {
    this.fg.reset(new Br(null));
    this.tgglRskScrMltplrVldtrs(this.acInsiderAlert.value);
  }

  /**
   * @description loops through mapped apps and sets
   * `selected` to true when igaAppId matches.
   * This is needed when creating a new BR, for at least
   * one app needs to be mapped.
   * @param igaAppId The current app igaAppId
   */
  setMappedApps(igaAppId: number, appName: string): void {
    if (!this.apps) return;

    const existingMappedApps = this.acMappedApps.value || [];

    this.acMappedApps.setValue(
      this.apps.map((app) => {
        const existingApp = existingMappedApps.find(
          (a) => a.igaAppID === app.igaAppID,
        );
        const sa = existingApp || new SimpleApplication(app);
        if (sa.igaAppID === igaAppId) {
          sa.selected = true;
        } else if (!existingApp) {
          sa.selected = false;
        }
        // Update the appName of the app with the matching igaAppId
        if (sa.igaAppID === igaAppId) {
          sa.name = appName;
        }
        return sa;
      }),
    );
  }

  /**
   * @description  updates the approval level value for the current BR,
   * either 1 or 2
   */
  updateApprovalLevel(): void {
    const appLevel =
      this.acRestrictedRole.value ||
      this.acInsiderAlert.value === InsiderAlert.Insider ||
      this.acDataClassification.value === DataClassification.Restricted
        ? 2
        : 1;
    this.acApprovalLevel.setValue(appLevel);
    if (appLevel === 1 && this.acSecondaryWGApprover.enabled) {
      this.acSecondaryWGApprover.setValue('');
      this.acSecondaryWGApprover.disable();
    } else if (appLevel === 2 && this.acSecondaryWGApprover.disabled) {
      this.acSecondaryWGApprover.enable();
    }
  }

  updateAutoApproval(isOn: boolean): void {
    if (isOn) {
      this.acAutoApprovalCondition.addValidators(Validators.required);
      this.acMaxAutoApprovalAllowed.addValidators(Validators.required);
    } else {
      this.acAutoApprovalCondition.removeValidators(Validators.required);
      this.acMaxAutoApprovalAllowed.removeValidators(Validators.required);
    }
    this.acAutoApprovalCondition.updateValueAndValidity();
    this.acMaxAutoApprovalAllowed.updateValueAndValidity();
  }

  updateHumanDataApproval(): void {
    if (this.acHumanDataApprovalFlag.value) {
      this.acHumanDataApprovalFilter.addValidators(Validators.required);
      this.acDefaultApproverIds.addValidators(Validators.required);
    } else {
      this.acHumanDataApprovalFilter.removeValidators(Validators.required);
      this.acDefaultApproverIds.removeValidators([Validators.required]);
    }
    this.acHumanDataApprovalFilter.updateValueAndValidity();
    this.acDefaultApproverIds.updateValueAndValidity();
  }

  updateAutoExtension(): void {
    if (this.acAutoExtensionFlag.value) {
      this.acAutoExtensionCondition.addValidators(Validators.required);
      this.acMaxAutoExtensionAllowed.addValidators(Validators.required);
    } else {
      this.acAutoExtensionCondition.removeValidators(Validators.required);
      this.acMaxAutoExtensionAllowed.removeValidators([Validators.required]);
    }
    this.acAutoExtensionCondition.updateValueAndValidity();
    this.acMaxAutoExtensionAllowed.updateValueAndValidity();
  }

  updateDataSel(): void {
    this.displayDataSel = this.acApprovalType.value === 'BR+DS';
  }

  /** Toggle the riskScoreMultiplier validators */
  tgglRskScrMltplrVldtrs(ia: InsiderAlert): void {
    if (ia === InsiderAlert.NotInsider || ia === InsiderAlert.Insider) {
      this.acRskScrMltplr.setValue('1.00');
      this.acRskScrMltplr.disable();
    } else {
      this.acRskScrMltplr.enable();
    }
  }

  isRskScrMltplrValid(): boolean {
    if (this.acRskScrMltplr.enabled) return this.acRskScrMltplr.valid;
    return true;
  }

  private rfByIgaAppIdResults = new BehaviorSubject<IRoleFilterByIgaAppId[]>(
    [],
  );
  currentData = this.rfByIgaAppIdResults.asObservable();

  changeData(data: IRoleFilterByIgaAppId[]) {
    this.rfByIgaAppIdResults.next(data);
  }

  /* --------------------------------------- getters --------------------------------------- */
  // --------------------------------------- business role info step
  /** AbstractControl value is a string */
  get acKey(): AbstractControl {
    return this.fg.get('key');
  }
  /** AbstractControl value is a string */
  get acName(): AbstractControl {
    return this.fg.get('name');
  }
  /** AbstractControl value is a string */
  get acDesc(): AbstractControl {
    return this.fg.get('description');
  }
  /** AbstractControl value is a string */
  get acType(): AbstractControl {
    return this.fg.get('type');
  }
  /** AbstractControl value is a boolean */
  get acAutoApprovalFlag(): AbstractControl {
    return this.fg.get('autoApprovalFlag');
  }
  /** AbstractControl value is a string */
  get acAutoApprovalCondition(): AbstractControl {
    return this.fg.get('autoApprovalCondition');
  }
  /** AbstractControl value is a string */
  get acMaxAutoApprovalAllowed(): AbstractControl {
    return this.fg.get('maxAutoApprovalAllowed');
  }
  /** AbstractControl value is a string */
  get acRoleOwner(): AbstractControl {
    return this.fg.get('roleOwner');
  }
  /** AbstractControl value is a string */
  get acRoleActFlag(): AbstractControl {
    return this.fg.get('roleActivityFlag');
  }
  /** AbstractControl value is a string */
  get acRoleProvApp(): AbstractControl {
    return this.fg.get('roleProvisionApplication');
  }
  /** AbstractControl value is a string */
  get acRoleDep(): AbstractControl {
    return this.fg.get('roleDependency');
  }
  /** AbstractControl value is a string */
  get acFuncArea(): AbstractControl {
    return this.fg.get('functionalArea');
  }
  /** AbstractControl value is array of string */
  get acUserTypes(): AbstractControl {
    return this.fg.get('userTypes');
  }
  /** AbstractControl value is array of `Application` */
  get acMappedApps(): AbstractControl {
    return this.fg.get('mappedApps');
  }
  /** AbstractControl value is a boolean */
  get acRestrictedRole(): AbstractControl {
    return this.fg.get('restrictedRole');
  }
  /** AbstractControl value is a string */
  get acPrivilegedAccess(): AbstractControl {
    return this.fg.get('privilegedAccess');
  }
  /** AbstractControl value is a number string */
  get acSelfCert(): AbstractControl {
    return this.fg.get('selfCertPeriod');
  }
  /** AbstractControl value is a number string */
  get acCertActionPeriod(): AbstractControl {
    return this.fg.get('certActionPeriod');
  }
  // --------------------------------------- Questionnaires step
  /** AbstractControl value is `InsiderAlert` */
  get acInsiderAlert(): AbstractControl {
    return this.fg.get('insiderAlert');
  }
  get acRskScrMltplr(): AbstractControl {
    return this.fg.get('riskScoreMultiplier');
  }
  /** AbstractControl value is `DataClassification` */
  get acDataClassification(): AbstractControl {
    return this.fg.get('dataClassification');
  }
  /** AbstractControl value is a boolean */
  get acDataPrivacy(): AbstractControl {
    return this.fg.get('dataPrivacy');
  }

  // --------------------------------------- Approver step
  /** AbstractControl value is a number (1 or 2) */
  get acApprovalLevel(): AbstractControl {
    return this.fg.get('approvalLevel');
  }
  /** AbstractControl value is a string: alway `BR` */
  get acApprovalType(): AbstractControl {
    return this.fg.get('approvalType');
  }
  /** AbstractControl value is `LicensingFlag` */
  get acLicensing(): AbstractControl {
    return this.fg.get('licensing');
  }
  /** AbstractControl value is a string */
  get acPrimaryWGApprover(): AbstractControl {
    return this.fg.get('primaryWGApprover');
  }
  /** AbstractControl value is a string */
  get acSecondaryWGApprover(): AbstractControl {
    return this.fg.get('secondaryWGApprover');
  }
  /** AbstractControl value is `MmsProfile` */
  get acProfile(): AbstractControl {
    return this.fg.get('profile');
  }
  /** AbstractControl value is a number */
  get acMaxAllowedDays(): AbstractControl {
    return this.fg.get('maxAllowedDays');
  }
  /** AbstractControl value is a number */
  get acDefaultAllowedDays(): AbstractControl {
    return this.fg.get('defaultAllowedDays');
  }
  /** AbstractControl value is a boolean */
  get acHumanDataApprovalFlag(): AbstractControl {
    return this.fg.get('humanDataApprovalFlag');
  }
  /** AbstractControl value is a string */
  get acHumanDataApprovalFilter(): AbstractControl {
    return this.fg.get('humanDataApprovalFilter');
  }
  /** FormArray of FormControl<string> */
  get acDefaultApproverIds(): AbstractControl {
    return this.fg.get('defaultApproverIds') as AbstractControl;
  }
  /** AbstractControl value is a string */
  get acAutoExtensionCondition(): AbstractControl {
    return this.fg.get('autoExtensionCondition');
  }
  /** AbstractControl value is a string */
  get acMaxAutoExtensionAllowed(): AbstractControl {
    return this.fg.get('maxAutoExtensionAllowed');
  }
  /** AbstractControl value is a boolean */
  get acAutoExtensionFlag(): AbstractControl {
    return this.fg.get('autoExtensionFlag');
  }
  /** AbstractControl value is a boolean */
  get acEnableBusinessRole(): AbstractControl {
    return this.fg.get('active');
  }
  /** AbstractControl value is a `Date` */
  get acStartDate(): AbstractControl {
    return this.fg.get('startDttm');
  }
  /** AbstractControl value is a `Date` */
  get acEndDate(): AbstractControl {
    return this.fg.get('endDttm');
  }
}
