const tester =
  /^[-!#$%&'*+0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
const ivbTester = /^[a-zA-Z]+(\.[a-zA-Z]+)*$/;

/** Validate that the string is a valid email address */
export function emailValid(email: string): boolean {
  if (!email) return false;

  const emailParts = email.split('@');

  if (emailParts.length !== 2) return false;

  const account = emailParts[0];
  const address = emailParts[1];

  if (account.length > 64) return false;
  else if (address.length > 255) return false;

  const domainParts = address.split('.');

  if (
    domainParts.some(function (part) {
      return part.length > 63;
    })
  )
    return false;

  return tester.test(email);
}

/** Validate that the string is a valid Accenture email and return error messages if invalid */
export function validateIvbEmail(email: string): {
  valid: boolean;
  message?: string;
} {
  if (!email)
    return { valid: false, message: 'At least one recipient is required.' };

  const emailParts = email.split('@');

  const account = emailParts[0];
  const domain = emailParts[1];

  // Check if the account contains only alphabets and dots
  if (!ivbTester.test(account)) {
    return {
      valid: false,
      message: 'Alphabet characters, "@" and "." are allowed.',
    };
  }
  // Check if the domain ends with 'accenture.com'
  if (domain !== 'accenture.com') {
    return {
      valid: false,
      message: `Email Recipient should be a valid Accenture email: ${email}`,
    };
  }

  if (emailParts.length !== 2)
    return { valid: false, message: 'Invalid email format.' };

  return { valid: true }; // Email is valid
}
