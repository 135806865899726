import { Application } from '../models/Application';
import { Dimension } from '../models/Dim';
import { DimType } from '../models/DimType';

export class APIVars {
  static allApps: Application[];
  static roleApps: Application[];
  static cacheDims: { [key: string]: Dimension[] } = {};
  static cacheDimTypes: DimType[];
  static reqId: string | undefined;
}
