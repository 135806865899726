import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, tap } from 'rxjs';
import {
  IEnvironment,
  IEnvironmentPayload,
  IReleaseEvents,
  IReleaseEventsPayload,
  IReleaseSchedule,
  IUpdateEvents,
  IUpdateEventsPayload,
  Schedule,
  UpdateEvents,
} from 'src/app/models/Release';
import { API } from 'src/app/util/API';
import { AsmPostResponse } from 'src/app/models/AsmPostResponse';
import { PopUpService } from 'src/app/singletons/popup/popup.service';
import { SpinnerService } from 'src/app/singletons/spinner/spinner.service';
import { ToasterService } from 'src/app/singletons/toaster/toaster.service';
import { AsmDeleteResponse } from 'src/app/models/AsmDeleteResponse';

@Injectable({
  providedIn: 'root',
})
export class ReleaseApiService extends API {
  constructor(
    http: HttpClient,
    pService: PopUpService,
    spinner: SpinnerService,
    toaster: ToasterService,
  ) {
    super(http, pService, spinner, toaster);
  }

  private readonly paths = {
    calSchedules: `${this.rootPathCalendar}schedules/by-date`,
    calSchedulesId: `${this.rootPathCalendar}schedules/by-id`,
    createSchedule: `${this.rootPathCalendar}schedules`,
    delCalSchedule: `${this.rootPathCalendar}schedules/by-id`,
    calEvents: `${this.rootPathCalendar}events`,
    delCalEvent: `${this.rootPathCalendar}events/by-id`,
    environments: `${this.rootPathCalendar}environments`,
    events: `${this.rootPathCalendar}events`,
  };

  /** @description get release events data */
  public getCalSchedules(key: string): Observable<IReleaseEvents[]> {
    return this.getReqHelper<IReleaseEventsPayload>(
      `${this.paths.calSchedules}/${key}`,
      `Getting calendar schedules data`,
    ).pipe(map((data) => data.releaseEvents));
  }
  public getCalScheduleById(key: string): Observable<IReleaseSchedule> {
    return this.getReqHelper<IReleaseSchedule>(
      `${this.paths.calSchedulesId}/${key}`,
      `Getting calendar schedules data`,
    );
  }

  /** @description get update events data */
  public getCalEvents(): Observable<IUpdateEvents[]> {
    return this.getReqHelper<IUpdateEventsPayload>(
      `${this.paths.calEvents}`,
      `Getting calendar events data`,
    ).pipe(map((data) => data.events));
  }
  /** @description get release environments data */
  public getReleaseEnvironments(): Observable<IEnvironment[]> {
    return this.getReqHelper<IEnvironmentPayload>(
      `${this.paths.environments}`,
      `Getting calendar environments data`,
    ).pipe(map((data) => data.environments));
  }

  /** @description post calendar schedules */
  public createSchedule(
    arr: Schedule,
    action: string,
    callback?: () => void,
  ): Observable<AsmPostResponse> {
    const operatorAction = action === 'create' ? 'Creating' : 'Updating';
    return this.postReqHelper<AsmPostResponse>(
      `${this.paths.createSchedule}`,
      { action: action, data: [arr] },
      `${operatorAction} Schedule`,
      callback,
    ).pipe(
      tap({
        complete: () => {
          this.toaster.show(`${action} Schedule`, `${action} was successful`);
        },
      }),
    );
  }

  /** @description update the Events */
  public updateEvents(
    arr: UpdateEvents,
    action: string,
    callback?: () => void, // Optional callback function with no parameters
  ): Observable<AsmPostResponse> {
    return this.postReqHelper<AsmPostResponse>(
      `${this.paths.calEvents}`,
      { action: action, data: [arr] },
      'Updating Events',
      callback, // Pass the callback function to the postReqHelper
    ).pipe(
      tap({
        complete: () => {
          this.toaster.show('Updating Events', `${action} was successful`);
        },
      }),
    );
  }

  /** @description delete schedule by id */
  public deleteSchedule(
    key: string,
    action: string,
    callback?: () => void, // Optional callback function with no parameters
  ): Observable<AsmDeleteResponse> {
    return this.deleteReqHelper<AsmDeleteResponse>(
      `${this.paths.delCalSchedule}/${key}`,
      'Deleting Schedule',
      callback, // Pass the callback function to the deleteReqHelper
    ).pipe(
      tap({
        complete: () => {
          this.toaster.show('Deleting Schedule', `${action} was successful`);
        },
      }),
    );
  }
  /** @description delete event by id */
  public deleteEvent(
    key: string,
    action: string,
    callback?: () => void,
  ): Observable<AsmDeleteResponse> {
    return this.deleteReqHelper<AsmDeleteResponse>(
      `${this.paths.delCalEvent}/${key}`,
      'Deleting Event',
      callback,
    ).pipe(
      tap({
        complete: () => {
          this.toaster.show('Deleting Event', `${action} was successful`);
        },
      }),
    );
  }
}
