import { DataSource } from '@angular/cdk/collections';
import { Observable, of as observableOf } from 'rxjs';

export class ImpactTblDataSource<T> extends DataSource<T> {
  constructor(public data: any[]) {
    super();
  }

  connect(): Observable<T[]> {
    return observableOf(this.data);
  }

  disconnect(): void {
    /** not disconnecting anything but can't remove :) */
  }
}
