<div class="popup">
    <div class="back-drop">&nbsp;</div>
    <div class="modal-wrapper">
        <div class="modal">
            <div class="title-space">
                <label>{{title}}</label>
            </div>
            <div class="mat-elevation-z8">
                <div class="search">
                    <app-input-keypress [placeholder]="'Filter Events...'" (keyPressed)="applyFilter($event)"
                        [ngClass]="{'disabled': searchInputDisabled}"  [clear]="clearSubject.asObservable()"></app-input-keypress>
                </div>
                <table mat-table class="full-width-table" matSort aria-label="update events">
                    <!-- Define columns -->
                    <ng-container matColumnDef="event">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="col-width"> Event</th>
                        <td mat-cell *matCellDef="let row" class="tdEvent">
                            <ng-container *ngIf="!row.editing; else editMode">
                                <span>{{ row.event }}</span>
                                <div *ngIf="row.apiErrorDelete" class="error-message">
                                    {{ row.apiErrorDelete }}
                                </div>
                            </ng-container>
                            <ng-template #editMode>
                                <form [formGroup]="relSer.inlineEditFormGroup" class="form-group">
                                    <div class="input-field-wrapper">
                                        <div class="left">
                                            <app-bsc-cstm-fld [abstrctCntrl]="relSer.getAbstractControl(row.key)"
                                                inptId="mi-edit-evt-name" label="" frmFldClss="edit-evt-name"
                                                [customLabelName]="'Event Name'" (keyup)="onUpdateValue(row,$event)">
                                                <input matInput id="mi-edit-evt-name" type="text"
                                                    [formControlName]="relSer.getFormControlName(row.key)"
                                                    [value]="row.event" [ngClass]="{'has-error': row.apiError}"
                                                    (input)="clearError(row)">
                                            </app-bsc-cstm-fld>
                                            <div *ngIf="row.apiError" class="error-message">
                                                {{ row.apiError }}
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </ng-template>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="createdBy">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="col-width"> Created By </th>
                        <td mat-cell *matCellDef="let row"> {{ row.createdBy }} </td>
                    </ng-container>
                    <ng-container matColumnDef="updatedBy">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="col-width"> Updated By </th>
                        <td mat-cell *matCellDef="let row"> {{ row.updatedBy }} </td>
                    </ng-container>
                    <!-- Repeat for other columns -->
                    <!-- Action Column -->
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef> Actions </th>
                        <td mat-cell *matCellDef="let row" class="action-buttons">
                            <ng-container *ngIf="row.editing; else saveCancelButtons">
                                <div class="button-group">
                                    <button mat-icon-button (click)="saveRowChange(row)"
                                        [disabled]="!isValueChanged(row) || !isFormControlValid(row)">
                                        <mat-icon>save</mat-icon>
                                    </button>
                                    <div class="separator"></div>
                                    <button mat-icon-button (click)="cancelEdit(row)">
                                        <mat-icon>cancel</mat-icon>
                                    </button>
                                </div>
                            </ng-container>
                            <ng-template #saveCancelButtons>
                                <div class="button-group">
                                    <button mat-icon-button class="edit-button" (click)="toggleEditMode(row)">
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                    <div class="separator"></div>
                                    <button mat-icon-button class="delete-button" (click)="deleteRow(row)">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                            </ng-template>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <mat-paginator #paginator [length]="dataSource?.data?.length" [pageIndex]="0" [pageSize]="5"
                    [showFirstLastButtons]="true" aria-label="Select page">
                </mat-paginator>

            </div>
            <div class="error-message" [hidden]="!showErrorMessage">{{ errorMessage }}</div>
            <form [formGroup]="relSer.fg" class="form-group">
                <div class="input-field-wrapper">
                    <div class="left">
                        <app-bsc-cstm-fld [abstrctCntrl]="relSer.acEventName" inptId="mi-evt-name" label=""
                            frmFldClss="evt-name" [customLabelName]="'Event Name'">
                            <input matInput id="mi-evt-name" type="text" formControlName="eventName"
                                placeholder="New Event" class="placeholder-grey">
                            <button mat-button type="button" class="btn btn-one" (click)="addEvent()"
                                [disabled]="!relSer.acEventName.value || relSer.fg.invalid">Add</button>
                        </app-bsc-cstm-fld>
                    </div>
                </div>
            </form>

            <div class="btn-wrapper lft-rht">
                <div class="lft">
                    <button mat-button id="btn-frm-cncl" type="button" class="btn btn-two"
                        (click)="relCom.hideForm()">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>
