import { Component, Input } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-bsc-cstm-fld[abstrctCntrl][frmFldClss][inptId][label]',
  templateUrl: './bsc-cstm-fld.component.html',
  styleUrls: ['./bsc-cstm-fld.component.scss'],
})
export class BscCstmFldComponent {
  @Input() abstrctCntrl: AbstractControl;
  @Input() frmFldClss: string;
  @Input() inptId: string;
  @Input() label: string;

  @Input() tooltip = '';
  @Input() errPttrn = 'Only ASCII characters are allowed';
  @Input() customLabelName: string;

  maxCharStr = '';

  get isRequired(): boolean {
    return this.abstrctCntrl.hasValidator(Validators.required);
  }
}
