import { Component, Input } from '@angular/core';
import { CRUDStpprMngr } from '../CRUDStpprMngr';

@Component({
  selector: 'app-crud-form-popup',
  templateUrl: './form-popup.component.html',
  styleUrls: ['./form-popup.component.scss'],
})
export class CrudFormPopupComponent<T> {
  @Input() mngr: CRUDStpprMngr<T>;
}
