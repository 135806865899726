import { Injectable } from '@angular/core';
import * as msal from '@azure/msal-browser';
import { idTokenClaims, User } from './User';
import { environment } from 'src/environments';
import { Observable, of, Subscriber } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly msalInstance = msalHelper.msalInstance;
  currUser: User;
  loginErr: any;

  onInit(): Observable<boolean> {
    return new Observable((sub) => {
      this.msalInstance.initialize().then(() => {
        this.msalInstance
          .handleRedirectPromise()
          .then((tokenResponse) => {
            if (tokenResponse) {
              this.currUser = new User(tokenResponse.account);
              this.currUser.refreshToken(tokenResponse);
              sub.next(true);
              sub.complete();
            } else {
              const user = this.getCorrectUser();
              if (user) {
                this.acquireTokenSilent(user, sub);
              } else {
                this.login();
              }
            }
          })
          .catch((err) => {
            this.loginErr = err;
            sub.complete();
          });
      });
    });
  }

  login(): void {
    this.loginErr = undefined;
    this.currUser = undefined;
    this.msalInstance.loginRedirect({
      scopes: environment.auth.scopes,
      redirectUri: environment.auth.redirectUri,
      prompt: 'select_account',
    });
  }

  logout(): void {
    if (this.isAuthenticated()) {
      this.msalInstance.logoutRedirect({
        account: this.msalInstance.getAccountByUsername(this.currUser.email),
        postLogoutRedirectUri: environment.auth.postLogoutRedirectUri,
      });
      this.currUser = undefined;
    }
  }

  isAuthenticated(): boolean {
    return !!this.currUser;
  }

  refreshToken(): Observable<boolean> {
    if (this.isAuthenticated() && !this.currUser.isTokenExpired) {
      return of(true);
    }
    const user = this.getCorrectUser();
    if (user) {
      return new Observable((sub) => {
        this.acquireTokenSilent(user, sub);
      });
    } else {
      this.login();
      return of(false);
    }
  }

  private acquireTokenSilent(
    user: msal.AccountInfo,
    sub: Subscriber<boolean>,
  ): void {
    const config = {
      scopes: environment.auth.scopes,
      account: this.msalInstance.getAccountByHomeId(user.homeAccountId),
    };
    this.currUser = undefined;
    this.loginErr = undefined;
    this.msalInstance
      .acquireTokenSilent(config)
      .then((token) => {
        if (token) {
          this.currUser = new User(token.account);
          this.currUser.refreshToken(token);
          sub.next(true);
          sub.complete();
        } else {
          sub.next(false);
          sub.complete();
        }
      })
      .catch((err) => {
        if (err instanceof msal.InteractionRequiredAuthError) {
          this.msalInstance.acquireTokenRedirect(config);
        } else {
          this.loginErr = err;
        }
        sub.next(false);
        sub.complete();
      });
  }

  private getCorrectUser(): msal.AccountInfo | null {
    const users = this.msalInstance.getAllAccounts();
    if (!users) {
      return null;
    }
    if (users.length === 0) {
      return null;
    }
    if (users.length === 1) {
      return users[0];
    }
    let user = users[0];
    users.forEach((u) => {
      if (
        (u.idTokenClaims as idTokenClaims).exp >
        (user.idTokenClaims as idTokenClaims).exp
      ) {
        user = u;
      }
    });
    return user;
  }
}

class MsalHelper {
  get msalInstance(): msal.PublicClientApplication {
    return new msal.PublicClientApplication({
      auth: environment.auth,
    });
  }
}

/**
 * Returns an instance of the msal Public Client Application.
 *
 * This is an export for testing purposes
 */
export const msalHelper = new MsalHelper();
