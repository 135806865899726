<h2 class="page-title">Manage security model</h2>
<form [formGroup]="msmFgService.fg" class="stepper">
    <div class="col-lft">
        <!-- What do you want to do today? -->
        <app-bsc-cstm-fld [abstrctCntrl]="msmFgService.acObjective" [tooltip]="ttLookinFour" inptId="ms-objective"
            label="What do you want to do today?" frmFldClss="obj">
            <mat-select id="ms-objective" formControlName="objective">
                <mat-option value="" disabled>-- Select objective --</mat-option>
                <ng-container *ngFor="let obj of msmObjectivesArr">
                    <mat-option [value]="obj" *ngIf="obj.visible">
                        {{obj.name}}
                    </mat-option>
                </ng-container>
            </mat-select>
        </app-bsc-cstm-fld>

        <!-- What action do you want to perform? -->
        <app-bsc-cstm-fld [abstrctCntrl]="msmFgService.acAction" [tooltip]="ttLookinFour" inptId="ms-action"
            label="What action do you want to perform?" frmFldClss="act">
            <mat-select id="ms-action" formControlName="action">
                <mat-option value="" disabled>-- Select action --</mat-option>
                <ng-container *ngFor="let act of msmActionsArr">
                    <mat-option [value]="act"
                        *ngIf="act.objective === msmFgService.acObjective?.value.name && act.visible">
                        {{act.name}}
                    </mat-option>
                </ng-container>
            </mat-select>
        </app-bsc-cstm-fld>

        <!-- Select an access package -->
        <app-bsc-cstm-fld *ngIf="msmFgService.acAccPkg.enabled" [abstrctCntrl]="msmFgService.acAccPkg"
            inptId="ms-acc-pkg" label="Select an access package" frmFldClss="acc-pkg">
            <div class="filter-container">
                <div class="filter-item">
                    <mat-icon class="filterIcon" (click)="handleCheckboxState('activeAccPkg'); $event.stopPropagation()"
                        [matMenuTriggerFor]="menu" style="cursor: pointer;" aria-label="Example filter icon"
                        fontIcon="filter_alt"></mat-icon>
                    Active
                    <mat-menu #menu="matMenu">
                        <ng-container *ngFor="let option of filterOptions">
                            <button mat-menu-item>
                                <mat-checkbox color="primary" [checked]="filterValues.activeAccPkg[option.val]"
                                    (click)="$event.stopPropagation()"
                                    (change)="onFilterChange('activeAccPkg', option.val, $event.checked)">
                                    {{ option.name }}
                                </mat-checkbox>
                            </button>
                        </ng-container>
                        <div class="btn-wrapper">
                            <button mat-button class="btn btn-two" (click)="applyFilter('activeAccPkg','accPkg')"
                                [disabled]="!anyOptionChecked('activeAccPkg')">Apply</button>
                            <button mat-button class="btn btn-one"
                                (click)="clearFilter('activeAccPkg','accPkg')">Clear</button>
                        </div>
                    </mat-menu>
                </div>
                <div class="filter-item">
                    <mat-icon class="filterIcon"
                        (click)="handleCheckboxState('visibleAccPkg'); $event.stopPropagation()"
                        [matMenuTriggerFor]="menu1" style="cursor: pointer;" aria-label="Example filter icon"
                        fontIcon="filter_alt"></mat-icon>
                    Visible
                    <mat-menu #menu1="matMenu">
                        <ng-container *ngFor="let option of filterOptions">
                            <button mat-menu-item>
                                <mat-checkbox color="primary" [checked]="filterValues.visibleAccPkg[option.val]"
                                    (click)="$event.stopPropagation()"
                                    (change)="onFilterChange('visibleAccPkg', option.val, $event.checked)">
                                    {{ option.name }}
                                </mat-checkbox>
                            </button>
                        </ng-container>
                        <div class="btn-wrapper">
                            <button mat-button class="btn btn-two" (click)="applyFilter('visibleAccPkg','accPkg')"
                                [disabled]="!anyOptionChecked('visibleAccPkg')">Apply</button>
                            <button mat-button class="btn btn-one"
                                (click)="clearFilter('visibleAccPkg','accPkg')">Clear</button>
                        </div>
                    </mat-menu>
                </div>
            </div>
            <div class="select-container">
                <mat-select id="ms-acc-pkg" formControlName="accPkg">
                    <mat-option value="" disabled>-- Select access package --</mat-option>
                    <app-input-keypress class="options-search" label="Search" placeholder="Search"
                        (keyPressed)="filterAccPkgs($event)" [clear]="clearAccPkgFilter$">
                    </app-input-keypress>
                    <ng-container *ngFor="let accPkg of filteredAccPkgs">
                        <mat-option *ngIf="accPkg.desc; else noToolTip" [value]="accPkg" [matTooltip]="accPkg.desc">{{
                            accPkg.name }}</mat-option>
                        <ng-template #noToolTip>
                            <mat-option [value]="accPkg">{{ accPkg.name }}</mat-option>
                        </ng-template>
                    </ng-container>
                </mat-select>
            </div>
        </app-bsc-cstm-fld>

        <!-- Select an application -->
        <app-bsc-cstm-fld *ngIf="msmFgService.acApp.enabled" [abstrctCntrl]="msmFgService.acApp" inptId="ms-app"
            tooltip="This is a unique identifier for your application." label="Select an application" frmFldClss="app">
            <div class="filter-container"
                *ngIf="msmFgService.acAction.value.val !== 'onboard_application_to_myAccess' && msmFgService.acAction.value.val !== 'connect_application_to_myAccess'">
                <div class="filter-item">
                    <mat-icon class="filterIcon" (click)="handleCheckboxState('activeApp'); $event.stopPropagation()"
                        [matMenuTriggerFor]="menu" style="cursor: pointer;" aria-label="Example filter icon"
                        fontIcon="filter_alt"></mat-icon>
                    Active
                    <mat-menu #menu="matMenu">
                        <ng-container *ngFor="let option of filterOptions">
                            <button mat-menu-item>
                                <mat-checkbox color="primary" [checked]="filterValues.activeApp[option.val]"
                                    (click)="$event.stopPropagation()"
                                    (change)="onFilterChange('activeApp', option.val, $event.checked)">
                                    {{ option.name }}
                                </mat-checkbox>
                            </button>
                        </ng-container>
                        <div class="btn-wrapper">
                            <button mat-button class="btn btn-two" (click)="applyFilter('activeApp','app')"
                                [disabled]="!anyOptionChecked('activeApp')">Apply</button>
                            <button mat-button class="btn btn-one"
                                (click)="clearFilter('activeApp','app')">Clear</button>
                        </div>
                    </mat-menu>
                </div>
                <div class="filter-item">
                    <mat-icon class="filterIcon" (click)="handleCheckboxState('visibleApp'); $event.stopPropagation()"
                        [matMenuTriggerFor]="menu1" style="cursor: pointer;" aria-label="Example filter icon"
                        fontIcon="filter_alt"></mat-icon>
                    Visible
                    <mat-menu #menu1="matMenu">
                        <ng-container *ngFor="let option of filterOptions">
                            <button mat-menu-item>
                                <mat-checkbox color="primary" [checked]="filterValues.visibleApp[option.val]"
                                    (click)="$event.stopPropagation()"
                                    (change)="onFilterChange('visibleApp', option.val, $event.checked)">
                                    {{ option.name }}
                                </mat-checkbox>
                            </button>
                        </ng-container>
                        <div class="btn-wrapper">
                            <button mat-button class="btn btn-two" (click)="applyFilter('visibleApp','app')"
                                [disabled]="!anyOptionChecked('visibleApp')">Apply</button>
                            <button mat-button class="btn btn-one"
                                (click)="clearFilter('visibleApp','app')">Clear</button>
                        </div>
                    </mat-menu>
                </div>
            </div>

            <div class="select-container">
                <mat-select id="ms-app" formControlName="app">
                    <mat-option value="" disabled>-- Select application --</mat-option>
                    <app-input-keypress class="options-search" label="Search" placeholder="Search"
                        (keyPressed)="filterApps($event)" [clear]="clearAppFilter$">
                    </app-input-keypress>
                    <ng-container *ngFor="let app of filteredApps">
                        <mat-option *ngIf="app.description; else noToolTip" [value]="app"
                            [matTooltip]="app.description">
                            <span class="chip">{{app.igaAppID}}</span> {{app.displayName}}
                        </mat-option>
                        <ng-template #noToolTip>
                            <mat-option [value]="app">
                                <span class="chip">{{app.igaAppID}}</span> {{app.displayName}}
                            </mat-option>
                        </ng-template>
                    </ng-container>
                </mat-select>
            </div>
        </app-bsc-cstm-fld>

        <!-- Select business role -->
        <app-bsc-cstm-fld *ngIf="msmFgService.acBr.enabled" [abstrctCntrl]="msmFgService.acBr" [tooltip]="ttLookinFour"
            inptId="ms-br" label="Select the business role that you want to update" frmFldClss="br">
            <div class="filter-container">
                <div class="filter-item">
                    <mat-icon class="filterIcon" (click)="handleCheckboxState('activeBr'); $event.stopPropagation()"
                        [matMenuTriggerFor]="menu3" style="cursor: pointer;" aria-label="Example filter icon"
                        fontIcon="filter_alt"></mat-icon>
                    Active
                    <mat-menu #menu3="matMenu">
                        <ng-container *ngFor="let option of filterOptions">
                            <button mat-menu-item>
                                <mat-checkbox color="primary" [checked]="filterValues.activeBr[option.val]"
                                    (click)="$event.stopPropagation()"
                                    (change)="onFilterChange('activeBr', option.val, $event.checked)">
                                    {{ option.name }}
                                </mat-checkbox>
                            </button>
                        </ng-container>
                        <div class="btn-wrapper">
                            <button mat-button class="btn btn-two" (click)="applyFilter('activeBr','br')"
                                [disabled]="!anyOptionChecked('activeBr')">Apply</button>
                            <button mat-button class="btn btn-one" (click)="clearFilter('activeBr','br')">Clear</button>
                        </div>
                    </mat-menu>
                </div>
                <div class="filter-item">
                    <mat-icon class="filterIcon" (click)="handleCheckboxState('visibleBr'); $event.stopPropagation()"
                        [matMenuTriggerFor]="menu4" style="cursor: pointer;" aria-label="Example filter icon"
                        fontIcon="filter_alt"></mat-icon>
                    Visible
                    <mat-menu #menu4="matMenu">
                        <ng-container *ngFor="let option of filterOptions">
                            <button mat-menu-item>
                                <mat-checkbox color="primary" [checked]="filterValues.visibleBr[option.val]"
                                    (click)="$event.stopPropagation()"
                                    (change)="onFilterChange('visibleBr', option.val, $event.checked)">
                                    {{ option.name }}
                                </mat-checkbox>
                            </button>
                        </ng-container>
                        <div class="btn-wrapper">
                            <button mat-button class="btn btn-two" (click)="applyFilter('visibleBr','br')"
                                [disabled]="!anyOptionChecked('visibleBr')">Apply</button>
                            <button mat-button class="btn btn-one"
                                (click)="clearFilter('visibleBr','br')">Clear</button>
                        </div>
                    </mat-menu>
                </div>
            </div>

            <div class="select-container">
                <mat-select id="ms-br" formControlName="businessRole">
                    <mat-option value="" disabled>-- Select business role --</mat-option>
                    <app-input-keypress class="options-search" label="Search" placeholder="Search"
                        (keyPressed)="filterMappedBrs($event)" [clear]="clearMappedBrFilter$">
                    </app-input-keypress>
                    <ng-container *ngFor="let br of msmFgService.filteredBusinessRoles">
                        <mat-option *ngIf="br.description; else noBrDesc" [value]="br" [matTooltip]="br.description | trimWithEllipsis">
                            <span class="chip">{{br.key}}</span> {{br.name}}
                        </mat-option>
                        <ng-template #noBrDesc>
                            <mat-option [value]="br">
                                <span class="chip">{{br.key}}</span> {{br.name}}
                            </mat-option>
                        </ng-template>
                    </ng-container>
                </mat-select>
            </div>
        </app-bsc-cstm-fld>
        <div class="btn-wrapper">
            <a id="next-btn" [ngClass]="{disabled: !msmFgService.fg.valid}" class="btn btn-two"
                [routerLink]="['./', msmFgService.msmActionSubUrl, msmFgService.msmActionLink]">Next</a>
        </div>
    </div>
    <div class="col-rht">
        <ng-container *ngIf="msmFgService.acApp.valid">
            <!-- AIR Id -->
            <div class="form-field onboarding-field" *ngIf="msmFgService.airId">
                <div class="label-wrapper">
                    <label>AIR ID</label>
                </div>
                <div class="input-wrapper">
                    <div class="static">{{ msmFgService.airId }}</div>
                </div>
            </div>
            <!-- onboarding status -->
            <div class="form-field onboarding-field" *ngIf="msmFgService.onboardingLvl">
                <div class="label-wrapper">
                    <label>Onboarding status</label>
                </div>
                <div class="input-wrapper">
                    <div class="static">{{ msmFgService.onboardingStatus }}</div>
                </div>
            </div>
            <!-- app description -->
            <div class="form-field onboarding-field" *ngIf="msmFgService.appDesc">
                <div class="label-wrapper">
                    <label>Description</label>
                </div>
                <div class="input-wrapper">
                    <div class="static">{{ msmFgService.appDesc }}</div>
                </div>
            </div>
        </ng-container>
    </div>
</form>
