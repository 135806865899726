export interface IAppDeetsPayload {
  applications: IAppDeets[];
}

export interface IAppDeets {
  airID: number;
  igaAppID: number;
  appName: string;
  appDescription: string;
  appOwner: string;
  appSponsor: string;
  onboardingStatus: string;
  privilegedAccess: string;
  additionalFilters: boolean;
  active: boolean;
  visible: boolean;
  appStartDttm: string;
  appEndDttm: string;
}

export interface IAppDeetsValidationPayload {
  AIRId: number;
  IGAAppId: number;
  ApplicationName: string;
  ApplicationDescription: string;
  PrivilegedAccessFlag: string;
  AdditionalFilters: boolean;
  IsActive: boolean;
}

export class AppDeets {
  airID: number;
  igaAppID: number;
  appName: string;
  appDescription: string;
  appOwner: string;
  appSponsor: string;
  onboardingStatus: string;
  privilegedAccess: string;
  additionalFilters: boolean;
  active: boolean;
  visible: boolean;
  appStartDttm: string;
  appEndDttm: string;

  /** @description can edit (ce) Iga App id */
  ceIgaAppID = false;
  /** @description can edit (ce) app name */
  ceAppName = true;
  /** @description can edit (ce) additional filters */
  ceAddFilter = false;
  /** @description can edit (ce) description */
  ceAppDesc = true;
  /** @description can edit (ce) app Owner */
  ceAppOwner = true;
  /** @description can edit (ce) app Sponsor */
  ceAppSponsor = true;

  constructor(data?: IAppDeets, isElevate = false) {
    if (data) {
      this.airID = data.airID;
      this.igaAppID = data.igaAppID || this.airID;
      this.appName = data.appName;
      this.appDescription = data.appDescription;
      this.privilegedAccess = data.privilegedAccess ?? 'NA';
      this.additionalFilters = data.additionalFilters ? true : false;
      this.appOwner = data.appOwner;
      this.appSponsor = data.appSponsor;
      this.onboardingStatus = data.onboardingStatus;
      this.active = data.active ? true : false;
      this.visible = data.visible ? true : false;
      this.appStartDttm = data.appStartDttm;
      this.appEndDttm = data.appEndDttm;
      if (isElevate) {
        this.ceIgaAppID = true;
        this.ceAppName = true;
        this.ceAppDesc = true;
        this.ceAppOwner = true;
        this.ceAppSponsor = true;
        this.additionalFilters = false;
      } else {
        this.ceAddFilter = true;
      }
    }
  }

  getPayload(): IAppDeets {
    const obj: IAppDeets = {
      airID: this.airID,
      igaAppID: this.igaAppID,
      appName: this.appName,
      appDescription: this.appDescription,
      privilegedAccess: this.privilegedAccess,
      additionalFilters: this.additionalFilters,
      appOwner: this.appOwner,
      appSponsor: this.appSponsor,
      onboardingStatus: this.onboardingStatus,
      active: true,
      visible: true,
      appEndDttm: this.getFormattedDate(this.appEndDttm),
      appStartDttm: this.getFormattedDate(this.appStartDttm),
    };

    return obj;
  }

  getValidationPayload(): IAppDeetsValidationPayload {
    return {
      AIRId: this.airID,
      IGAAppId: this.igaAppID,
      ApplicationName: this.appName,
      ApplicationDescription: this.appDescription,
      PrivilegedAccessFlag: this.privilegedAccess,
      AdditionalFilters: this.additionalFilters,
      IsActive: true,
    };
  }

  getFormattedDate(appDate: string | null): string | null {
    if (!appDate || (typeof appDate === 'string' && appDate.trim() === '')) {
      return null;
    }
    const date = new Date(appDate);
    if (isNaN(date.getTime())) {
      return null;
    }

    return date.toISOString().split('.')[0];
  }
}
