import { CanActivateFn, Router, Routes } from '@angular/router';
import { AuthGuard } from '../auth/auth.guard';
import { AccPkgGuard } from './acc-pkg/acc-pkg.guard';
import { AppBrEdmGuard } from './app-br-edm/app-br-edm.guard';
import { MsmComponent } from './msm.component';
import { miscConfigCanActivate } from './misc-config/misc-config.guard';
import { MsmService } from './msm.service';
import { inject } from '@angular/core';
import { ddtdsdsgConfigCanActivate } from './ddtdsdsg/ddtdsdsg.guard';

const msmCanActivate: CanActivateFn = () => {
  const msm = inject(MsmService);
  if (msm.fg.valid) {
    return true;
  }

  return inject(Router).parseUrl('/securitymodel');
};

export const msmRoutes: Routes = [
  {
    path: 'securitymodel',
    component: MsmComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'securitymodel/d',
    loadChildren: () =>
      import('./ddtdsdsg/ddtdsdsg.module').then((m) => m.DdtdsdsgModule),
    canActivate: [msmCanActivate, ddtdsdsgConfigCanActivate],
  },
  {
    path: 'securitymodel/m',
    loadChildren: () =>
      import('./app-br-edm/app-br-edm.module').then((m) => m.AppBrEdmModule),
    canActivate: [msmCanActivate, AppBrEdmGuard],
  },
  {
    path: 'securitymodel/accPkg',
    loadChildren: () =>
      import('./acc-pkg/acc-pkg.module').then((m) => m.AccPkgModule),
    canActivate: [msmCanActivate, AccPkgGuard],
    canDeactivate: [AccPkgGuard],
  },
  {
    path: 'securitymodel/miscConfig',
    loadChildren: () =>
      import('./misc-config/misc-config.module').then(
        (m) => m.MiscConfigModule,
      ),
    canActivate: [msmCanActivate, miscConfigCanActivate],
  },
];
